import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import LoginImage from '../../assets/images/login_image.svg';
import forgot_password from '../../assets/images/forgot_password.svg';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Input from '../../../storybook/Input/Input';
import LockIcon from '@material-ui/icons/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { getOtp, resetOTP, resetOTPVerify } from '../../../redux/actions';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppConstants from '../../../values/AppConstants';

const ForgetPassword = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [phone, setPhone] = useState("")
    const [otp, setOtp] = useState('')

    const otpSuccess = useSelector(state => state.authReducer.success)

    const sendCode = (e, type) => {
        e.preventDefault();
        AppConstants.phone = phone;
        dispatch(getOtp({ phone: phone, type: type, enteredOtp: otp }))
    }

    useEffect(() => {
        dispatch(resetOTP());
        dispatch(resetOTPVerify());
        otpSuccess && history.push('/auth/verify-reset-otp');
    }, [otpSuccess])

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <div className="reg_form_img_wrapper" className='form_view'>
                        <div className="top_img_wrapper">
                            <span className="top_heading_wrapper">
                                <h1>2W Digital Platform</h1>
                                <h3 className="xs_heading">A completely paperless, cashless and system driven e-market place for buying and selling of EV</h3>
                            </span>

                            <img className="reg_form_img" src={LoginImage} alt="signup" />
                        </div>

                    </div>
                </Grid>



                <Grid item xs={12} md={4} className="center">
                    <Paper elevation={2} className="p_50 forgot_form_wrapper">
                        <div className="top_heading_wrapper p-b-30">
                            <img src={forgot_password} alt="forgetPassword" height="100" className="emc" />
                            <h1 className="x_heading">Forget Password</h1>
                        </div>
                        <div>
                            <h3>Let’s reset your password</h3>
                            <p style={{ color: '#203594' }}>Enter the phone number registered with statiq</p>
                        </div>
                        <form onSubmit={(e) => sendCode(e, 'generate')}>
                            <div className='m_t_40 m_b_40'>
                                <h5>Phone Number</h5>
                                <Input placeholder="Enter Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="center">
                                <Button
                                    variant="contained"
                                    startIcon={<LockIcon />}
                                    type="submit"
                                    style={{ backgroundColor: '#203594', color: '#fff', borderRadius: 10 }}>
                                    Confirm
                                </Button>
                            </div>

                        </form>

                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default ForgetPassword
