import { HTTPGetRequest } from "../../helper"
import Actions from "../../values/Actions"
import ServerUrls from "../../values/ServerUrls"

export const getFaq = (page) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_FAQ_PROGRESS })
        HTTPGetRequest(`${ServerUrls.FAQ_URL}?page=${page}`, {})
            .then(res => {
                if (res.status === 200) {
                    dispatch({ type: Actions.GET_FAQ_SUCCESS, payload: res.response })
                }
                else {
                    dispatch({ type: Actions.GET_FAQ_ERROR, payload: res.response })
                }
            })
            .catch((error) => {
                dispatch({ type: Actions.GET_FAQ_ERROR, payload: error })
            })
    }
}