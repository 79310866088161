import React, { useEffect, useState } from 'react'
import { getFaq } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ArrowCircleLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowCircleRightIcon from '@material-ui/icons/ArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress'


const Faq = () => {

    const dispatch = useDispatch();
    const faqData = useSelector(state => state.faqReducer.get_faq_data);
    const faqLoading = useSelector(state => state.faqReducer.get_faq_loading);
    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(1)


    const handleAddClick = (i) => {
        if (selected.indexOf(i) === -1) {
            setSelected([...selected, i])
        }
        else {
            let temp = JSON.parse(JSON.stringify(selected));
            let index = temp.indexOf(i)
            temp.splice(index, 1);
            setSelected(temp)

        }

    }


    const handleLeft = () => {
      
        setPage(page - 1)
    }

    const handleRight = () => {
        setPage(page + 1)
    }


    useEffect(() => [
        dispatch(getFaq(page))
    ], [page])

    return (
        <div>
            <div className="sub_heading">
                <h1>FAQs</h1>
            </div>

            <div className="main_container">
                {faqLoading ? <div className="center">
                    <CircularProgress />
                </div> :
                    <div className="faq_wrapper">


                        <div className="faq_container_wrapper">
                            {faqData?.faqs?.map((faq, i) => (
                                <div key={i}>
                                    <div className="faq_ques_wrapper">
                                        <span style={{ fontSize: 16 }} className="dark_heading "> {faq.question}</span>
                                        {
                                            selected.indexOf(i) === -1 ?
                                                <AddCircleIcon style={{ color: '#a9a7a7' }} onClick={() => handleAddClick(i)} fontSize="small" />
                                                :
                                                <RemoveCircleIcon style={{ color: '#a9a7a7' }} onClick={() => handleAddClick(i)} fontSize="small" />
                                        }


                                    </div>
                                    {
                                        selected.map((item) => (
                                            item === i && < div style={{ width: '80vw' }} >
                                                <span style={{ fontSize: 14 }}>{faq.answer}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="center">
                            <span className="center">
                                <ArrowCircleLeftIcon onClick={page > 1 && handleLeft} style={{ color: page === 1 ? '#a9a7a7' : 'none', cursor: page === 1 ? 'default' : 'pointer' }} />
                                {`${page} / ${faqData?.total_pages}`}
                                <ArrowCircleRightIcon onClick={page < faqData?.total_pages && handleRight} style={{ color: page === faqData?.total_pages ? '#a9a7a7' : 'none', cursor: page === faqData?.total_pages ? 'default' : 'pointer' }} />
                            </span>

                        </div>
                    </div>}
            </div>
        </div >
    )
}

export default Faq
