import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const buttonStyle = {
    float: 'right',
    width: 140,
    height: 50,
    background: '#FF914D',
    borderRadius: 10,
    color: '#FFFFFF'
}
const disableButtonStyle = {
    cursor: 'not-allowed',
    opacity: 0.5,
    ...buttonStyle
}
const Declaration = ({ submitTrue, formData }) => {

    const history = useHistory();

    const [checkClick, setCheckClick] = useState(false);
    const patchResponse = useSelector(state => state.confirmationReducer.Patch_loan_response);

    const handleSubmit = () => {
        submitTrue(true)
    }

    useEffect(() => {
        if (patchResponse?.ev_loan_form_url) {
            // window.open(patchResponse?.ev_loan_form_url, '_blank', 'noopener,noreferrer');
            history.push('/loan-tracker')
        }
    }, [patchResponse])

    const { name_of_emp, father_name, emp_id } = formData;

    return (
        <div className='main_container' style={{ marginTop: '1rem' }}>
            <div className='center'>
                <div style={{ width: '80%', fontSize: 20, fontWeight: 500 }}>
                    <div>
                        I,Mr/Ms/Mrs <span className='declaration'>{name_of_emp}</span> S/o,D/o,W/o <span className='declaration'>{father_name}</span> Working  as  Permanent/Deputation/Contract/Out  Sourcing  Employee
                        with Employee id <span className='declaration'>{emp_id}</span> has applied for the Electric 2 Wheeler Vehicle Loan under EMI  scheme  of  TSREDCO  on voluntary
                        basis. I  here  with  give  my acceptance  that I will abide by the scheme guidelines issued by  TSREDCO from time
                        to time and give my  acceptance  to  deduct  the  monthly  EMI  amount  from  my  salary  and  remit against to my
                        Loan account and also I hereby declare that the information furnished  above  is  true,  complete  and  correct  to
                        the  best  of  my  knowledge  and belief. I understand that in the event of my information being found false or
                        incorrect at  any stage,  my  application  shall  be  liable  to  cancellation  /rejection  without  any notice
                        and I understand that the decision of TSREDCO in this regard is final.
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Checkbox checked={checkClick} color="primary" onClick={() => setCheckClick(!checkClick)} style={{ paddingLeft: 0 }} />
                        <span>I have read the above statement and I give my acceptance.</span>
                    </div>
                    <div>
                        <Button
                            disabled={!checkClick}
                            onClick={handleSubmit}
                            style={!checkClick ? disableButtonStyle : buttonStyle}>
                            Submit
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Declaration;