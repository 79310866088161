import React, { useState } from 'react';
import './Catalogue.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import vehicle_not_found from '../assets/images/vehicle_not_found.svg';
import filter_icon from '../assets/images/filter_icon.svg';
import reset_icon from '../assets/images/reset_icon.svg';
import modal_filter_icon from '../assets/images/modal_filter_icon.svg';
import carosel_img1 from '../assets/images/carosel_img1.png';
import carosel_img2 from '../assets/images/carosel_img2.png';
import carosel_img3 from '../assets/images/carosel_img3.png';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux';
import { getStateList, getProductList, getProductInv, addTestDrive, resetNewPassword } from '../../redux/actions'
import { useEffect } from 'react';
import Select from '../../storybook/Select/Select';
import { formatAmount, formatToINR, getItem, saveItem, ucfirst } from '../../helper';
import AppConstants from '../../values/AppConstants';
import StarIcon from './icons/StarIcon';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../redux/actions/AlertActions';
import SearchBar from '../../storybook/Search/SearchBar';
import SwitchButton from '../../storybook/Switch/Switch';
import Slider from "@material-ui/core/Slider";
import { getOemList } from '../../redux/actions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import SelectMui from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import not_found from '../assets/images/not_found.svg';
import CarouselComp from '../catalogue/common-ui/CarouselComp/CarouselComp';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import bajaj_chetak from '../assets/images/bajaj-chetak.svg';
import { customerLogout } from '../../redux/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import admin_logo from '../assets/images/admin_logo.svg'
import Login from '../auth/Login'
import ArrowCircleLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowCircleRightIcon from '@material-ui/icons/ArrowRight';


const Products = (props) => {

    const [selectedState, setSelectedState] = useState({
        id: localStorage.getItem('x_auth_state_id') && localStorage.getItem('x_auth_state_id'),
        name: localStorage.getItem('x_auth_state_name') && localStorage.getItem('x_auth_state_name')
    })
    const history = useHistory();
    const dispatch = useDispatch();
    const locationHistory = useLocation();
    const inv_data = useSelector(state => state.productReducer.inv_response)
    const oem_list = useSelector(state => state.productReducer.oem_response)
    const loginData = useSelector(state => state.authReducer.login_response);
    const [openTest, setOpenTest] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)

    const [selectedInv, setSelectedInv] = useState(0)
    const [selectedInvVal, setSelectedInvVal] = useState()
    const [selectedVehicle, setSelectedVehicle] = useState(null)
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = useState(1)

    const [portalType, setPortalType] = useState(localStorage.getItem('x_v_type') ? localStorage.getItem('x_v_type') : 1)

    const [searchVal, setSearchVal] = useState("");

    const [value, setValue] = React.useState([0, 300000]);

    const [openCompany, setOpenCompany] = useState(false);
    const [openRange, setOpenRange] = useState(false);

    const [oemList, setOemList] = useState([]);

    const isAuth = useSelector(state => state.authReducer.login_success)

    // const inv_succ = useSelector(state => state.productReducer.inv_success)
    const test_succ = useSelector(state => state.orderReducer.test_success)

    const setRoute = (val) => {
        history.push(val)
    }
    const location = () => {

        navigator.geolocation.getCurrentPosition(function (position) {
            saveItem("lat", position.coords.latitude);
            saveItem("long", position.coords.longitude)
        });
    }

    useEffect(() => {
        if (props.success && !localStorage.getItem('x_auth_state_id')) {
            let allStates = props.response?.states
            setSelectedState(allStates[0])
            localStorage.setItem('x_auth_state_id', allStates[0].id)
            localStorage.setItem('x_auth_state_name', allStates[0].name)
            localStorage.setItem('x_v_type', portalType)
            props.getProductList({ stateId: allStates[0].id, oemId: '', value: value, vehicleType: portalType, page })
        }
    }, [props.success])
    useEffect(() => {
        if (selectedState.id) {

            props.getProductList({ stateId: selectedState.id, oemId: '', value: value, vehicleType: portalType, page })
        }
    }, [selectedState.id])

    useEffect(() => {
        if (portalType && selectedState.id !== undefined) {
            props.getProductList({ stateId: selectedState.id, oemId: '', value: value, vehicleType: portalType, page })
            localStorage.setItem('x_v_type', portalType)
        }
    }, [portalType, page])

    useEffect(() => {
        location()
        localStorage.getItem('APP_VERSION') != AppConstants.APP_VERSION && props.getStateList()
        !localStorage.getItem('x_auth_state_id') && props.getStateList()
        dispatch(resetNewPassword())

    }, [])

    useEffect(() => {
        isAuth && handleClose()
    }, [isAuth])



    const onApplyFilters = () => {
        let obj = {
            stateId: selectedState.id,
            oemId: openCompany && oemList,
            value: openRange ? value : [0, 300000],
            vehicleType: portalType,
            page: page

        }
        dispatch(getProductList(obj))
        setOpenFilter(false)
    }


    const handleClickOpen = () => {
        !isAuth && setOpen(true);
    };

    const handleStateChange = (state) => {
        setSelectedState(state)
        props.getProductList({ stateId: state.id, oemId: '', value: value, vehicleType: portalType, page })
        localStorage.setItem('x_auth_state_id', state.id)
        localStorage.setItem('x_auth_state_name', state.name)
        // AppConstants.stateId = state.id;
    }

    const handleOemListChange = (e) => {
        //console.log(e.target.value)
        setOemList([...e.target.value])
    }

    const handleTestOpen = ({ id, stateId, colorId }) => {
        if (isAuth) {
            setSelectedInv(0)
            setSelectedInvVal(null)
            setSelectedVehicle(id)
            let params = `vehicleId=${id}&colorId=${colorId}&stateId=${stateId}&latitude=${getItem('lat') ? getItem('lat') : '28.4595'}&longitude=${getItem('long') ? getItem('long') : '77.0266'}`
            dispatch(getProductInv(params))
            setOpenTest(true)
        }
        else dispatch(setAlert('error', 'Please Login To Book Test Drive!!'))
    }

    const handleTestClose = () => {
        setOpenTest(false)
    }


    const handleFilterOpen = () => {
        setOpenFilter(true)
    }


    const handleFilterClose = () => {
        setOpenFilter(false)
    }


    const bookTestDrive = () => {
        dispatch(addTestDrive({ showroom: selectedInvVal, vehicle: selectedVehicle }))
    }

    const handleInvVal = (val, index) => {
        setSelectedInv(index)
        setSelectedInvVal(val)
    }

    const resetFilters = () => {
        setOemList([])
        setValue([0, 300000])
        setOpenRange(false)
        setOpenCompany(false)
    }

    /**
   * @method search
   * @description for seaching the value 
   * @param {*} value 
   */

    const search = (value) => {
        // setPage(1);
        setSearchVal(value);
    }
    /**
     * @method resetSearch
     * @description for resetting the search
     */

    const resetSearch = () => {
        // setPage(1)
        setSearchVal("");
    }


    const handleLogout = () => {
        dispatch(customerLogout())
        history.push('/')
    };


    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    function numFormatter(num) {
        if (num > 1000 && num < 100000) {
            return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
        } else if (num >= 100000) {
            return (num / 100000).toFixed(1) + "L"; // convert to M for number from > 1 million
        } else if (num < 900) {
            return num; // if value < 1000, nothing to do
        }
    }


    const isSelected = (arr, item) => {
        let isSelect = false;
        arr?.map(t => {
            if (t.id === item.id) {
                isSelect = true;
            }

        }
        )
        return isSelect;
    }


    const isView = () => {
        if (portalType == 1 && (selectedState.id === 7 || 3)) {
            return true
        }
        else if (portalType == 2) {
            return true
        }
        else return true
    }


    const handleLeft = () => {

        setPage(page - 1)
    }

    const handleRight = () => {
        setPage(page + 1)
    }


    useEffect(() => {
        setSelectedInvVal(inv_data?.productInventory?.[0]?.showroomId)
    }, [inv_data])


    useEffect(() => {
        if (test_succ && openTest) {
            handleTestClose()
            history.push('/orders')
        }
    }, [test_succ])


    useEffect(() => {
        openCompany && dispatch(getOemList())
    }, [openCompany])

    return (
        <div>
            {props.loading ? <div className="center"><CircularProgress /></div> : <>
                <div className="sub_heading">
                    <div className="product_filter_block">
                        <div className="product_filter_block_first" style={{ justifyContent: 'center' }}>
                            <span style={{ display: 'flex' }}>
                                <h2 style={{ color: '#1E4473' }}>TSREDCO Digital Marketplace for E-Vehicle</h2>
                                {/* <h1  className="product_menu" onClick={() => history.push('/map-view')}>Charge Your Ev</h1> */}
                            </span>

                            {/* <SearchBar initialSearch={(value) => search(value)} resetSearchVal={resetSearch} /> */}

                        </div>

                        <div className="product_filter_block_first">
                            <ButtonGroup variant="contained">
                                <Button onClick={() => setPortalType(1)} disabled={props?.product_loading} color={portalType == 1 ? 'primary' : 'default'}>
                                    2-Wheelers
                                </Button>
                                <Button onClick={() => setPortalType(2)} disabled={props?.product_loading} color={portalType == 2 ? 'primary' : 'default'}>
                                    3-Wheelers
                                </Button>
                            </ButtonGroup>
                            <div style={{ display: 'flex' }}>
                                <div className="filter_icon_wrapper">
                                    <img src={filter_icon} onClick={handleFilterOpen} className="filter_icon" alt="filter" style={{ cursor: 'pointer', marginRight: 10 }} />

                                </div>

                                <Select data={props.response?.states} onChange={handleStateChange} placeholder={localStorage.getItem('x_auth_state_name') ? localStorage.getItem('x_auth_state_name') : props.response?.states?.name} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main_container">
                    {

                        props.product_loading ? <div className="center">
                            <CircularProgress />
                        </div> :
                            props.product_response.products === undefined ?
                                <span className="center not_found">
                                    <img src={not_found} alt="not found" className="not_found_img" />
                                    <h1>Products Not Found!!</h1>
                                </span>
                                :
                                <>
                                    <Grid container spacing={3}>
                                        {
                                            props.product_response?.products && props.product_response?.products.map((item) => (
                                                <Grid item xs={12} sm={6} md={3} xl={3}>
                                                    <div>
                                                        <Paper variant="outlined" className="p-10" style={{ borderRadius: 20, overflow: 'hidden', position: 'relative' }} >
                                                            {item.vehicleId === 1020 && <div className="test_vehicle">
                                                                Test Vehicle
                                                            </div>}
                                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '90%', cursor: item?.colors?.find(color => color.inStock === true) ? 'pointer' : 'not-allowed' }}
                                                                onClick={() => item?.colors?.find(color => color.inStock === true) && setRoute(`/productdetail/${item.vehicleId}/${selectedState.id}`)}>

                                                                <div className="p-b-10">
                                                                    <img src={item.imageUrl ? item.imageUrl : vehicle_not_found} alt="bajaj" height="120" />
                                                                </div>
                                                                <div className="product_color_round_wrapper ">
                                                                    {item.colors.map(color => (
                                                                        <span style={{ backgroundColor: `${color.hexCode}` }} className="product_color_round" />
                                                                    ))}

                                                                </div>
                                                                <div className="center p-b-10">
                                                                    <span className="dark_heading">{ucfirst(item.vehicleName)}</span>
                                                                </div>
                                                                <div className={portalType === 1 ? "flex_between p-b-10" : "center p-b-10"}>
                                                                    {portalType === 1 && <span>
                                                                        {/* <StarIcon fill="#F38140" />
                                                    <StarIcon fill="#F38140" />
                                                    <StarIcon fill="#F38140" />
                                                    <StarIcon fill="#F38140" />
                                                    <StarIcon /> */}
                                                                    </span>}
                                                                    {
                                                                        <span className="dark_heading">{item?.colors?.find(color => color.inStock === true) && formatToINR(item.price)}</span>

                                                                    }

                                                                </div>
                                                            </div>
                                                            {
                                                                item?.colors?.find(color => color.inStock === true)
                                                                    ?
                                                                    <div className="flex_even">

                                                                        <Button color="primary" variant="contained"
                                                                            style={{ fontSize: 12, backgroundColor: '#9ACA3E' }}
                                                                            onClick={() => setRoute(`/productdetail/${item.vehicleId}/${selectedState.id}`)}
                                                                        >Select</Button>
                                                                        <Button color="secondary" variant="contained"

                                                                            style={{ color: '#fff', fontSize: 12 }}
                                                                            onClick={() => handleTestOpen({ id: item.vehicleId, stateId: selectedState.id, colorId: item.colors[0].colorId })}>Test Drive</Button>


                                                                    </div>
                                                                    :
                                                                    <div className="center" style={{ flexDirection: 'column', cursor: 'not-allowed', padding: 10, height: 54 }}>
                                                                        <h4 style={{ color: 'red' }}>Sold Out</h4>
                                                                        <h5 style={{ color: 'rgba(222, 54, 60, 1)' }}>This item is currently out of stock
                                                                        </h5>
                                                                    </div>
                                                            }

                                                        </Paper>
                                                    </div>

                                                </Grid>
                                            ))


                                        }
                                        {props.product_response.products !== undefined && <Grid item xs={12}>
                                            <div className="center">
                                                <span className="center">
                                                    <ArrowCircleLeftIcon onClick={page > 1 && handleLeft} style={{ color: page === 1 ? '#a9a7a7' : 'none', cursor: page === 1 ? 'default' : 'pointer' }} />
                                                    {`${page} / ${props.product_response?.totalPages}`}
                                                    <ArrowCircleRightIcon onClick={page < props.product_response?.totalPages && handleRight} style={{ color: page === props.product_response?.totalPages ? '#a9a7a7' : 'none', cursor: page === props.product_response?.totalPages ? 'default' : 'pointer' }} />
                                                </span>

                                            </div>
                                        </Grid>}

                                    </Grid>
                                </>

                    }

                </div>
            </>
            }
            <Dialog open={openTest} onClose={handleTestClose} maxWidth="sm">
                <DialogTitle disableTypography>
                    <h1>Select Showroom</h1>
                </DialogTitle>
                <DialogContent>
                    {inv_data?.productInventory?.map((item, index) => (
                        <div className={`dealer_block_data pointer ${selectedInv == index && 'inv_selected'}`} onClick={() =>
                            handleInvVal(item.showroomId, index)
                        }>
                            <div style={{ display: 'flex' }}>
                                <input type="radio" checked={selectedInv == index} onClick={() => handleInvVal(item.showroomId, index)} />
                                <div style={{ marginLeft: 10 }}>
                                    <div className="title">{ucfirst(item.showroomName)}</div>
                                    <div className={` ${selectedInv == index ? 'sub_title_dark' : 'sub_title'}}`}>Address: {ucfirst(item.street_address)} , {item.city}</div>
                                    <div className={` ${selectedInv == index ? 'sub_title_dark' : 'sub_title'}}`}>Distance:  {item.distance}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button variant="contained" style={{ margin: '20px 0px' }}
                        onClick={bookTestDrive}
                        color="primary" fullWidth>
                        Book Test Drive
                    </Button>

                </DialogContent>
            </Dialog>

            {/* Modal Popup */}

            <Dialog open={openFilter} onClose={handleFilterClose} maxWidth="sm">
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={modal_filter_icon} alt='filter' />
                                <h2>Product Filter</h2>
                                <img src={reset_icon} onClick={() => resetFilters()} style={{ cursor: 'pointer' }} alt='reset' />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="modal_button_wrapper">
                                <h3>Company</h3>
                                <SwitchButton checked={openCompany} onChange={() => setOpenCompany(!openCompany)} name="opencompany" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>

                            {
                                openCompany &&
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel id="demo-simple-select-outlined-label">Select Company</InputLabel>
                                    <SelectMui
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Select Company"
                                        multiple
                                        name="company"
                                        value={oemList}
                                        onChange={(e) => handleOemListChange(e)}
                                        renderValue={(selected) => selected.map((item) => {
                                            //console.log(item)
                                            return item.name
                                        }).join()}
                                    >

                                        {oem_list?.oemDetail?.map((item) => (
                                            <MenuItem key={item.id} value={item}>
                                                <Checkbox color="primary" checked={isSelected(oemList, item)} />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                    </SelectMui>
                                </FormControl>
                            }

                        </Grid>

                        <Grid item xs={12}>
                            <div className="modal_button_wrapper">
                                <h3>Price Range</h3>
                                <SwitchButton checked={openRange} onChange={() => setOpenRange(!openRange)} name="openRange" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                openRange &&
                                <Slider
                                    value={value}
                                    min={30000}
                                    max={300000}
                                    onChange={handleChange}
                                    valueLabelFormat={numFormatter}
                                    valueLabelDisplay="on"
                                    aria-labelledby="range-slider"

                                />
                            }

                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" color="primary" onClick={onApplyFilters}>Apply</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
            >
                <DialogContent style={{ padding: 0 }}>
                    <Login modal="true" />
                </DialogContent>

            </Dialog>
        </div >
    )
}
const mapStateToProps = (state) => ({

    loading: state.productReducer.loading,
    error: state.productReducer.error,
    success: state.productReducer.success,
    response: state.productReducer.response,

    product_loading: state.productReducer.product_loading,
    product_error: state.productReducer.product_error,
    product_success: state.productReducer.product_success,
    product_response: state.productReducer.product_response,

})

export default connect(mapStateToProps, { getStateList, getProductList })(Products)
