import { HTTPGetRequest } from "../../helper"
import Actions from "../../values/Actions"
import ServerUrls from "../../values/ServerUrls"

export const getStateList = () => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_STATE_PROGRESS })
        HTTPGetRequest(ServerUrls.GET_STATES, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_STATE_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_STATE_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_STATE_ERROR, payload: error.message })
        })
    }

}

export const getProductList = (data) => {
    let param = '';
    if (data.oemId) {
        let oemParams = '';
        let oemData = data?.oemId?.map((item) => {
            oemParams = `&oemId=${item.id}`
            return oemParams
        })
        param = `?stateId=${data.stateId}${oemData.join('')}&priceRangeFrom=${data.value[0]}&priceRangeTo=${data.value[1]}&vehicleType=${data.vehicleType}&page=${data.page}`
    }
    else {
        param = `?stateId=${data.stateId}&priceRangeFrom=${data.value[0]}&priceRangeTo=${data.value[1]}&vehicleType=${data.vehicleType}&page=${data.page}`
    }


    return (dispatch) => {
        dispatch({ type: Actions.GET_PRODUCTS_PROGRESS })

        HTTPGetRequest(`${ServerUrls.GET_PRODUCTS}${param}`, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_PRODUCTS_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_PRODUCTS_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_PRODUCTS_ERROR, payload: error.message })
        })
    }
}


export const getProductDetail = (id, type) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_PRODUCT_DETAIL_PROGRESS })
        HTTPGetRequest(`${ServerUrls.GET_PRODUCT_DETAIL}?vehicleId=${id}&vehicleType=${type}`, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_PRODUCT_DETAIL_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_PRODUCT_DETAIL_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_PRODUCT_DETAIL_ERROR, payload: error.message })
        })
    }

}


export const getProductImagesByColor = (id) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_PRODUCT_IMAGES_BY_COLOR_PROGRESS })
        HTTPGetRequest(`${ServerUrls.GET_PRODUCT_IMAGES_BY_COLOR_URL}colorId=${id}`, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_PRODUCT_IMAGES_BY_COLOR_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_PRODUCT_IMAGES_BY_COLOR_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_PRODUCT_IMAGES_BY_COLOR_ERROR, payload: error.message })
        })
    }

}

export const getProductInv = (params) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_PRODUCT_INV_PROGRESS })
        HTTPGetRequest(`${ServerUrls.GET_PRODUCT_INV}${params}`, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_PRODUCT_INV_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_PRODUCT_INV_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_PRODUCT_INV_ERROR, payload: error.message })
        })
    }

}
export const getOemList = () => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_OEM_LIST_PROGRESS })
        HTTPGetRequest(`${ServerUrls.GET_OEM_LIST}?searchKey=`, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_OEM_LIST_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_OEM_LIST_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_OEM_LIST_ERROR, payload: error.message })
        })
    }
}

export const getLatLong = () => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_LAT_LONG_PROGRESS })
        HTTPGetRequest(`${ServerUrls.LAT_LONG_URL}`, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_LAT_LONG_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_LAT_LONG_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_LAT_LONG_ERROR, payload: error.message })
        })
    }

}

