import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import './Auth.css'
import ForgetPassword from './forgetPassword/ForgetPassword';
import VerifyResetOtp from './verifyResetOTP/VerifyResetOtp';
import EnterResetPassword from './EnterResetPassword/EnterResetPassword';
import Confirmation from './Confirmation/Confirmation';

const Auth = () => {
    const { component } = useParams();

    const [active, setActive] = useState('login');

    useEffect(() => {
        setActive(component ? component : 'login')
    }, [component]);

    const renderComponent = (component) => {

        switch (component) {
            case 'login':
                return <Login />
            case 'register':
                return <Register />
            case 'forget-password':
                return <ForgetPassword />
            case 'verify-reset-otp':
                return <VerifyResetOtp />

            case 'new-password':
                return <EnterResetPassword />
            case 'change-password-done':
                return <Confirmation />
            default:
                return <Login />
        }
    }
    return (
        <div>
            {renderComponent(component)}
        </div>
    )
}

export default Auth
