import Actions from "../../values/Actions"

const FAQ_INITIAL_STATE = {
    get_faq_loading: false,
    get_faq_success: false,
    get_faq_msg: '',
    get_faq_data: []
}

const INITIAL_STATE = {
    ...FAQ_INITIAL_STATE
}

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action
    switch (type) {
        case Actions.GET_FAQ_PROGRESS:
            return {
                ...state,
                get_faq_loading: true,
                get_faq_success: false,
                get_faq_error: '',
                get_faq_data: []
            }
        case Actions.GET_FAQ_SUCCESS:
            return {
                ...state,
                get_faq_loading: false,
                get_faq_success: true,
                get_faq_data: payload

            }
        case Actions.GET_FAQ_ERROR:
            return {
                ...state,
                get_faq_loading: false,
                get_faq_success: false,
                get_faq_msg: payload.message
            }

        default:
            return state
    }
}