import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch  from '@material-ui/core/Switch';


const PurpleSwitch = withStyles({
    switchBase: {
      color: '#FF914D',
      '&$checked': {
        color: '#FF914D',
      },
      '&$checked + $track': {
        backgroundColor: '#203594',
        opacity:1
      },
    },
    checked: {},
    track: {},
  })(Switch);

// Defination of switch
const Switchs = withStyles((theme) => ({
    root: {
        width: 32,
        height: 16,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#203594',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#203594',
            border: '3px solid #fff',
        },
    },
    thumb: {
        width: 14,
        height: 14,
        color: '#FF914D'
    },
    track: {
        borderRadius: 16 / 2,
        border: `1px solid ${theme.palette.grey[900]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});





export default PurpleSwitch
