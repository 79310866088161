import React from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Confirmation_vehicle from '../assets/images/confirmation_vehicle.svg';
import Bajaj_Chetak from '../assets/images/bajaj-chetak.svg';
import { useSelector } from 'react-redux';
import { formatToINR, getDecryptedItem, getItem } from '../../helper';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { resetPayment, resetRemainingPayment } from '../../redux/actions/PaymentAction';
import vehicle_not_found from '../assets/images/vehicle_not_found.svg';
import { ResetLoan } from '../../redux/actions';

const Checkout = () => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const invData = useSelector(state => state.productReducer.inv_response)
    const productData = useSelector(state => state.productReducer.product_response)
    const detailData = useSelector(state => state.productReducer.detail_response)
    const productImages = useSelector(state => state.productReducer.get_product_images_data)
    const paymentResponse = useSelector(state => state.paymentReducer.response)

    const [amount, setAmount] = useState([]);
    const [delDate, setDelDate] = useState('');
    const [billing, setBilling] = useState(false)

    useEffect(() => {
        dispatch(resetPayment());
        dispatch(resetRemainingPayment())
        setBilling(getDecryptedItem("billing"))
    }, [])

    const setDeliveryDate = (vehicleId) => {
        switch (parseInt(vehicleId)) {
            case 201:
                return setDelDate('30th Nov 2021');
            case 1003:
                return setDelDate('10th Oct 2021');

            case 1004:
                return setDelDate('10th Oct 2021');

            case 1005:
                return setDelDate('10th Oct 2021');

            case 1006:
                return setDelDate('15th Oct 2021');

            case 1007:
                return setDelDate('15th Oct 2021');
            case 1008:
                return setDelDate('15th Oct 2021');
            case 1009:
                return setDelDate('15th Oct 2021');
            case 1010:
                return setDelDate('15th Oct 2021');
            case 1011:
                return setDelDate('15th Oct 2021');
            case 1012:
                return setDelDate('5th Oct 2021');
            case 1013:
                return setDelDate('10th Oct 2021');
            case 1014:
                return setDelDate('15th Oct 2021');
            case 1015:
                return setDelDate('30th Oct 2021');
            case 1016:
                return setDelDate('15th Oct 2021');
            case 1017:
                return setDelDate('15th Oct 2021');
            case 1018:
                return setDelDate('10th Oct 2021');
            case 1019:
                return setDelDate('10th Oct 2021');
            case 1020:
                return setDelDate('30th Sep 2021');
            default:
                return setDelDate('Not Available')

        }
    }

    const getData = () => {
        let v_id = getItem('v_id');
        return productData.products.filter((item) => item.vehicleId == v_id)

    }

    const expectedDate = () => {
        let date = new Date();
        date.setDate(date.getDate() + 45)
        return <span>{date.toDateString()}</span>

    }

    useEffect(() => {
        let inv_val = getDecryptedItem('inv_id')

        let amountVal = invData.productInventory.filter((item) => item.inventoryId == inv_val)
        setAmount(amountVal)
        setDeliveryDate(getItem('v_id'));
        dispatch(ResetLoan())
    }, [])


    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: 1200 }} className="main_container">
                <Paper elevation={3} style={{ width: '70vw', display: 'flex', flexDirection: 'column' }} className="center" >
                    <div>
                        <Grid container spacing={3} className="top_section">
                            <Grid item xs={12} className="center" style={{ flexDirection: 'column', padding: 10 }}>
                                <h2 style={{ color: '#9DCB3A' }} className="p-30">{location.state.loanForm ? 'Congratulations!' : 'Congratulations! Order Confirmed'}</h2>
                                <img className="p-b-10" src={Confirmation_vehicle} alt="confirmation" />
                                <h4 className="p-5">
                                    {
                                        location.state.loanForm ?
                                            'Your application has been successfully submitted. Your will receive an application form shortly on your e-mail.'
                                            :
                                            'Your order is confirmed. Your will receive an order confirmation shortly on your e-mail.'
                                    }

                                </h4>
                            </Grid>
                            {/* <Grid item xs={6}>
                                <h3 style={{color:'#FF914D'}}>Rate Your Experience</h3>
                            </Grid> */}
                        </Grid>
                        <hr className="divider" />
                        <Grid container spacing={3} className="bottom_section">
                            <Grid item xs={12} md={4}>
                                <img src={productImages?.product_images?.length > 0 ? productImages?.product_images[0] : vehicle_not_found} height="200" className="img_height" alt="bajaj" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div style={{ flexDirection: 'column', display: 'flex' }} >
                                    <h3 className="p-b-5">{getData()[0]?.vehicleName}</h3>
                                    {
                                        // console.log(billing)
                                    }
                                    {

                                        billing === 'false' &&
                                        <div style={{ paddingRight: 5, color: '#000' }}>Delivery By :
                                            <span style={{ color: '#9BCA3C' }}>

                                                {paymentResponse.expectedDeliveryDate}
                                            </span> </div>
                                    }

                                </div>

                            </Grid>
                            <Grid item xs={12} md={4} className="confirm_sec_3" style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <div>
                                        {/* <div className="p-b-5">Order Id</div>
                                        <div>
                                            # 406-8192472-6629105
                                        </div> */}
                                    </div>

                                </div>
                                <div className="confirm_sec_3">
                                    <div className="p-b-15">
                                        <span style={{ paddingRight: 20 }}>Total Price</span>
                                        <span><b>{formatToINR(amount[0]?.price - amount[0]?.subsidy)}</b></span>
                                    </div>

                                    <Button color="primary" className="m-b-20" variant="contained"
                                        onClick={() => history.push('/orders')}
                                    >Go To My Orders</Button>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                </Paper>

            </div>
        </div>
    )
}

export default Checkout
