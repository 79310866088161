import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { unregister } from './serviceWorkerRegistration'


const theme = createTheme({
  palette: {
    primary: {
      main: '#064A76',
    },
    secondary: {
      main: '#F38140',
    },
  },

  overrides: {
    MuiStepLabel: {
      label: {
        fontSize: 10
      },
      alternativeLabel: {
        fontSize: 10
      }
    },
    MuiStepIcon: {
      root: {
        height: 18
      },
      completed: {
        height: 18
      }
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: 8
      }
    },
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: '#064A76',
          borderWidth: 1
        },

      },
    },
    MuiInputLabel: {
      root: {
        color: '#064A76'
      }

    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
unregister()
