import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { useSelector, useDispatch } from 'react-redux'
import { getLatLong } from '../../../redux/actions';
import Marker from './Marker';
import { useHistory, useLocation } from 'react-router-dom'
import './Map.css';
import order_card_vehicle_icon from '../../assets/images/order_card_vehicle_icon.png'
import google_play_icon from '../../assets/images/google_play_icon.png'
import apple_store_logo from '../../assets/images/apple-store-logo.png'

const MapView = () => {

    const dispatch = useDispatch();
    const map_data = useSelector(state => state.productReducer.get_map_data)
    const locationHistory = useLocation();
    const history = useHistory();

    const [lat, setLat] = useState(null)
    const [lng, setLong] = useState(null)

    const location = () => {

        navigator.geolocation.getCurrentPosition(function (position) {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude)
        });
    }


    useEffect(() => {
        dispatch(getLatLong())
        location()
    }, [])


    const initialLocation = {
        address: 'My Location',
        lat: lat,
        lng: lng
    }

    return (
        <div className="main_container">
            <div className="sub_heading">
                <span style={{ display: 'flex' }}>
                    <h1 className="product_menu" onClick={() => history.push('/')} >My Ev Shop</h1>
                    <h1 className={locationHistory.pathname = "/map-view" ? 'product_menu_active product_menu' : null} onClick={() => history.push('/map-view')}>Charge Your Ev</h1>
                </span>
            </div>
            {lat && <div style={{ height: '73vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBzrNhVWzsR7xkQkCVZTW0qoPresT3wM-c' }}
                    defaultCenter={initialLocation}
                    defaultZoom={17}
                    yesIWantToUseGoogleMapApiInternals
                >

                    <Marker
                        lat={initialLocation.lat}
                        lng={initialLocation.lng}
                        // text={initialLocation.address}
                        color="#FF914D"
                    />

                    {
                        map_data?.map((item, i) => {
                            return <Marker
                                color="#123abc"
                                key={i}
                                lat={item[1]}
                                lng={item[2]}
                                // text={item[0]}
                            />
                        })
                    }
                </GoogleMapReact>
                <div className="map_card">
                    <h2>Download the MyEV App to charge your electric vehicle </h2>
                    <div className="flex_even">

                        <img src={order_card_vehicle_icon} alt="vehicle" className="vehicle_img" />
                        <span style={{ display: 'flex', flexDirection: 'column' }} >
                            <a href="https://play.google.com/store/apps/details?id=com.statiq.cesl" target="_blank" no-referrer>
                                <img src={google_play_icon} alt="google" className="google_img" />
                            </a>
                            <a href="https://apps.apple.com/in/app/my-ev/id1582799460" target="_blank" no-referrer>
                                <img src={apple_store_logo} alt="apple" className="google_img" />
                            </a>

                        </span>
                    </div>
                </div>

            </div>
            }





        </div>
    )
}

export default MapView
