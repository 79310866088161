import Actions from "../../values/Actions";

const ADD_COMPLAINT_STATE = {
    add_loading: false,
    add_success: false,
    add_msg: null
}

const INITIAL_STATE = {
    ...ADD_COMPLAINT_STATE
}

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case Actions.COMPLAINT_ADD_RESET:
            return {
                ...state,
                add_loading: false,
                add_success: false,
                add_msg: null

            }
        case Actions.COMPLAINT_ADD_PROGRESS:
            return {
                ...state,
                add_loading: true,
                add_success: false,
                add_msg: null

            }
        case Actions.COMPLAINT_ADD_SUCCESS:
            return {
                ...state,
                add_loading: false,
                add_success: true,
                add_msg: payload
            }
        case Actions.COMPLAINT_ADD_ERROR:
            return {
                ...state,
                add_loading: false,
                add_success: false,
                add_msg: payload
            }
        default:
            return state
    }
}