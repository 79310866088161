import Actions from "../../values/Actions";


const INITIAL_STATE = {
    type: '',
    msg: ''
}
export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Actions.SET_ALERT:
            // console.log('set alert',payload.type , payload.msg)
            return { ...state, type : payload.type , msg: payload.msg }
        case Actions.REMOVE_ALERT:
            return { ...state, msg: '' , type : '' }

        default:
            return state
    }
}