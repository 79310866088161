import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import vehicle_not_found from '../../assets/images/vehicle_not_found.svg';
import { formatToINR, getDecryptedItem, ucfirst } from '../../../helper';
import { getApplicationList } from '../../../redux/actions';
import { getEvLoanForm } from '../../../redux/actions/Confirmation';
import { useHistory } from 'react-router-dom';

const LoanTracker = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const applicationLoading = useSelector(state => state.confirmationReducer.get_application_list_loading)
    const applicationSuccess = useSelector(state => state.confirmationReducer.get_application_list_success)
    const applicationData = useSelector(state => state.confirmationReducer.get_application_list_response)
    const getLoanSuccess = useSelector(state => state.confirmationReducer.get_loan_success)

    useEffect(() => {
        dispatch(getApplicationList())
    }, [])

    useEffect(() => {
        if (getLoanSuccess) {
            history.push('/loan')
        }

    }, [getLoanSuccess])

    const renderStatus = (index) => {
        switch (index) {
            case 0:
                return 'Continue your application form'
            case 1:
                return 'Application submitted successfully and is under review. You will receive an email shorty.'
            case 2:
                return 'Your Loan Application is approved'
            case 3:
                return 'Your Loan Application is rejected'
            default:
                return 'Continue your application form'
        }
    }
    const dateConvert = (data) => {
        var event = new Date(data).toDateString()
        return event
    }

    return (
        <>
            {/* {console.log(JSON.stringify(applicationData))} */}
            {
                applicationLoading ? <div className="center"> <CircularProgress /> </div> :
                    <div className='main_container'>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Paper elevation={2} style={{ display: 'flex', justifyContent: 'space-between', width: '90%', flexWrap: 'wrap' }} className="p-10 order_paper">
                                    <Grid item xs={12} md={4} className="center">
                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={applicationData?.data?.vehicleImageUrl ? applicationData?.data?.vehicleImageUrl : vehicle_not_found} alt="vehicle" height={150} />

                                        </div>

                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Grid container spacing={3}>
                                            <Grid xs={12} className="p-10">
                                                <h2 className="p-b-10">{ucfirst(applicationData?.data?.vehicleName)}</h2>
                                            </Grid>
                                            <Grid item xs={6} md={6} >
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div className="dark_heading">
                                                        Application Id
                                                    </div>
                                                    <div className="dark_heading">{applicationData?.data?.applicationNum}</div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div className="dark_heading">Applied On</div>
                                                    <div className="title">{dateConvert(applicationData?.data?.appliedDate)}</div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <div className="p-b-10">
                                                    <div className="dark_heading">
                                                        Billing Address
                                                    </div>
                                                    <div className="title" style={{ display: 'flex', flexDirection: "column" }}>
                                                        <span> {applicationData?.data?.address}</span>

                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '9.1rem' }}>
                                                    <div className="dark_heading">
                                                        Color
                                                    </div>
                                                    <div style={{ backgroundColor: `${applicationData?.data?.hexCode}` }} className="product_color_round"></div>
                                                </div>
                                            </Grid>

                                        </Grid>

                                        <Grid item xs={12}>
                                            <div>

                                                <div onClick={() => applicationData.data.statusId === 0 && dispatch(getEvLoanForm(applicationData?.data?.applicationNum))}>

                                                    <h5 className={applicationData?.data?.statusId === 0 ? 'status_heading' : 'dark_heading'}>
                                                        {renderStatus(applicationData?.data?.statusId)}</h5>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'end' }}>
                                            <span>
                                                <h2 style={{ color: '#000' }} className="p-b-10" >{formatToINR(applicationData?.data?.price)}</h2>
                                            </span>



                                        </div>
                                        <div>

                                        </div>

                                    </Grid>




                                </Paper>
                            </Grid>


                        </Grid>
                    </div>


            }
        </>
    )
}

export default LoanTracker