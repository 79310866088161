import React from 'react'

const AuthLayout = (props) => {
    return (
        <>
            {props.children}
        </>
    )
}

export default AuthLayout
