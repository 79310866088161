import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { deleteAddress, getAddress, resetAddAddress, resetEditAddress } from '../../redux/actions/Confirmation';
import AppConstants from '../../values/AppConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AddressForm from './AddressForm';
import { useHistory, useLocation } from 'react-router';
import { formatToINR, getDecryptedItem, getItem, saveEncryptedItem } from '../../helper';
import { getRazorPay, resetPayment } from '../../redux/actions/PaymentAction';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import Grid from '@material-ui/core/Grid'
import vehicle_not_found from '../assets/images/vehicle_not_found.svg';
import tsredco_logo from '../assets/images/tsredco_logo.svg';



const Address = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const stateId = localStorage.getItem('x_auth_state_id')
    const addressData = useSelector(state => state.confirmationReducer.address_response)
    const deleteSuccess = useSelector(state => state.confirmationReducer.delete_address_success)
    const paymentSuccess = useSelector(state => state.paymentReducer.success)
    const invData = useSelector(state => state.productReducer.inv_response)
    const productDetailResponse = useSelector(state => state.productReducer.detail_response)
    const productResponse = useSelector(state => state.productReducer.product_response)
    const productImages = useSelector(state => state.productReducer.get_product_images_data)
    // const data = new Array(2).fill(0)

    const [selected, setSelected] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState(null)
    const [vehicleId, setVehcileId] = useState(null)
    const [vehiclePriceData, setVehiclePriceData] = useState({})
    const [vehicleInvData, setVehcileInvData] = useState([]);


    const handleCloseModal = () => {
        setOpenModal(false)
        dispatch(getAddress(stateId))
    }


    const handleOpenAddModal = () => {
        setOpenModal(true)
        setIsEdit(false)
    }



    const confirmPayment = () => {
        let inv_val = getDecryptedItem('inv_id')
        let amount = invData.productInventory.filter((item) => item.inventoryId == inv_val)

        let newAmount = (amount[0]?.price + amount[0]?.insurance_charges
            + amount[0]?.registration_charges + amount[0]?.other_charges)
            - (amount[0]?.subsidy + amount[0]?.cesl_discount)


        const options = {
            key: AppConstants.RAZORPAY_KEY,
            amount: (parseInt(location.state.booking ? location.state.bookingAmount : newAmount) * 1) * 100,
            currency: 'INR',
            name: "TSREDCO",
            description: 'Payment Through Razorpay.',
            image: tsredco_logo,
            handler: async function (response) {
                const data = {
                    razorpay_id: response.razorpay_payment_id
                }
                dispatch(getRazorPay({ payment_id: data.razorpay_id, amount: location.state.booking ? location.state.bookingAmount : newAmount, addressId: selected, invId: inv_val, booking: location.state.booking }))

            },
            prefill: {
                // name: userInfo.fullname,
                // email: addressData.data[0].email,
                contact: addressData.data[0].phone,
            },

            theme: {
                color: "#203594",
            },
            modal: {
                ondismiss: function () {
                    document.body.style.overflow = 'auto';
                }
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
            dispatch(getRazorPay({ payment_id: response.error.metadata.payment_id, amount: location.state.booking ? location.state.bookingAmount : newAmount, addressId: selected, invId: inv_val, booking: location.state.booking }))
        });
        paymentObject.open();



    }

    const openEdit = (data) => {
        setEditData(data)
        setIsEdit(true)
        setOpenModal(true)
    }

    const deleteValue = (data) => {
        dispatch(deleteAddress(data.id))
    }


    const expectedDate = () => {
        let date = new Date();
        date.setDate(date.getDate() + 45)
        return <span>{date.toDateString()}</span>

    }

    useEffect(() => {
        const razorPayScript = document.createElement('script');
        razorPayScript.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(razorPayScript)
        dispatch(getAddress(stateId))
    }, [dispatch])

    useEffect(() => {
        // paymentSuccess && window.location.assign(`/checkout/confirmation`);
        paymentSuccess && history.push({
            pathname: '/checkout/confirmation',
            state: {
                loanForm: false
            }
        })
    }, [paymentSuccess])

    useEffect(() => {
        deleteSuccess && dispatch(getAddress(stateId))
    }, [deleteSuccess])

    useEffect(() => {
        let id = getItem('v_id')
        let inv_val = getDecryptedItem('inv_id')
        dispatch(resetAddAddress())
        dispatch(resetEditAddress())
        setVehcileId(id)
        let data = productResponse?.products?.filter(item => item.vehicleId == id)
        let invValue = invData.productInventory.filter((item) => item.inventoryId == inv_val)
        saveEncryptedItem("billing", location.state.booking)
        setVehiclePriceData(data[0])
        setVehcileInvData(invValue[0])

        // console.log("location", location.state)
    }, [])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <div className="main_container">

                        <Paper className="address_paper flex_around">
                            <div >
                                <img src={productImages?.product_images?.length > 0 ? productImages?.product_images[0] : vehicle_not_found} alt="vehicle" height="300" className="summery_img" />
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <div >
                                        <h4 className="dark_heading">Scooter</h4>
                                        <h2 className="dark_heading">{vehiclePriceData?.vehicleName}</h2>
                                    </div>
                                    <div className="p-t-20">
                                        <div>
                                            <h4 className="dark_heading">Price</h4>
                                            <h2 className="dark_heading">{formatToINR(vehiclePriceData?.price)}</h2>
                                        </div>
                                        <div>
                                            <h4 className="dark_heading">Delivery Location - At DealerShip</h4>
                                            {!location.state.booking && <span style={{ display: 'flex' }}>
                                                <h4 className="dark_heading">Delivery By-</h4>

                                                <h4 style={{ color: '#9BCA3C' }}>{`Within ${productDetailResponse?.deliveryDays} days after order confirmation`}</h4>



                                            </span>}

                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* {console.log("color array", vehiclePriceData?.colors?.find((color) => color.colorId === location.state.color).hexCode)} */}
                                    <div style={{ backgroundColor: `${vehiclePriceData?.colors?.find((color) => color.colorId === location.state.color)?.hexCode}` }} className="product_color_round"></div>
                                    <div className="p-t-20">
                                        <div>
                                            <h4 className="dark_heading">Dealership Address</h4>
                                            <h2 className="dark_heading">{vehicleInvData?.showroomName}</h2>
                                        </div>
                                        <div>
                                            <h4 className="dark_heading">Address - {vehicleInvData?.street_address}</h4>
                                        </div>
                                        {/* <div>
                                       <h4>Ph No - {vehicleInvData?.street_address}</h4>
                                   </div> */}
                                        <div>
                                            <h4 className="dark_heading">Distance - {vehicleInvData?.distance}</h4>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>

                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="main_container">
                        <div>

                            <Paper elevation={3} className="center address_main_wrapper address_paper" >
                                <div className="address_wrapper">
                                    <div style={{ width: '80%' }}>
                                        <h1>Select Address</h1>
                                        <span className="center" style={{ justifyContent: 'flex-start' }}>
                                            <InfoIcon style={{ color: '#FF914D', fontSize: '20' }} />
                                            <h4 style={{ color: '#FF914D' }}>Please note - This address will only be used for Billing, the delivery will be at Dealership</h4>
                                        </span>

                                    </div>

                                    {
                                        addressData?.data?.map((item, i) => (
                                            <div key={i}
                                                style={{
                                                    border: '1px solid', display: 'flex', flexDirection: 'column',
                                                    borderRadius: 10, backgroundColor: selected == item.id ? '#203594' : ''
                                                }} className="p-10 m-10">
                                                <div className="p-5" style={{ display: 'flex' }}>
                                                    <input type="radio" className="m-5" value={item.id} name="address" onClick={(e) => setSelected(e.target.value)} />
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ color: selected == item.id ? '#fff' : '#000' }} >{item.street}</span>
                                                        <span style={{ color: selected == item.id ? '#fff' : '#000' }} >{item.city}</span>
                                                        <span style={{ color: selected == item.id ? '#fff' : '#000' }} >{item.stateName} - {item.pincode}</span>
                                                        <span style={{ color: selected == item.id ? '#fff' : '#000' }} >{item.phone}</span>
                                                        {
                                                            selected == item.id &&
                                                            <div className="p-5">
                                                                <Button style={{ color: '#fff', borderColor: '#fff' }} variant="outlined" onClick={() => openEdit(item)} size="small"> Edit</Button>
                                                                {
                                                                    item.is_billing === 0 && <Button onClick={() => deleteValue(item)} style={{ color: '#fff' }} size="small"> Delete</Button>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>


                                            </div>
                                        ))
                                    }

                                </div>

                                <div style={{ width: '80%', border: '1px solid', display: 'flex', flexDirection: 'column', backgroundColor: '#000', color: '#fff', cursor: 'pointer' }}
                                    className="p-10 m-10" onClick={handleOpenAddModal}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <AddIcon />
                                        Add New Address
                                    </span>

                                </div>
                                <div className="address_button">
                                    <Button variant="contained" color="primary" disabled={selected ? false : true}
                                        onClick={confirmPayment} >Confirm And Proceed</Button>
                                    <Button variant="contained"
                                        className="m-10"
                                        onClick={() => history.push('/')} >Cancel</Button>
                                </div>
                            </Paper>
                        </div>

                    </div>
                </Grid>
            </Grid>



            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogContent>
                    <AddressForm data={isEdit && editData} edit={isEdit} onClose={handleCloseModal} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Address
