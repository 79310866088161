import React ,{ useState , useEffect } from 'react';
import { useParams  } from 'react-router-dom';
import Address from './Address';
import Checkout from './Checkout';
import './Confirmation.css';

const Confirmation = () => {

    const { component } = useParams();

    const [active , setActive] = useState('address');

    useEffect(()=>{
        setActive(component ? component : 'address')
    },[component]);

    const renderComponent = (component) => {
      
        switch (component) {
            case 'address':
                return <Address/>
            case 'confirmation':
                return <Checkout/>
        
            default:
                return <Address />
        }
    }
    return (
        <div>
            <div className="view_wrapper">
                <div style={{ flexGrow: '1', height: '83vh', padding: 12 }}>
                    {renderComponent(component)}
                </div>
            </div>
        </div>
    )
}

export default Confirmation
