import React from 'react'
import Grid from '@material-ui/core/Grid';
import Input from '../../../storybook/Input/Input';
import { useState } from 'react';
import { useEffect } from 'react';
import { getItem } from '../../../helper';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { getProductInv } from '../../../redux/actions'
import { useParams } from 'react-router-dom';


const EmployeeDetails = ({ formData, onFormSubmit }) => {
    const location = useLocation();
    const history = useHistory();
    const productResponse = useSelector(state => state.productReducer.product_response);
    const loanResponse = useSelector(state => state.confirmationReducer.loan_response);
    const loanSuccess = useSelector(state => state.confirmationReducer.loan_success)
    const parameters = useParams()
    const [vehicleData, setVehicleData] = useState([]);
    const [vehicleName, setVehicleName] = useState('');

    const onFormChange = (e) => {
        let obj = {
            name: e.target.name,
            value: e.target.value
        }
        onFormSubmit(obj)
    }

    const handleFileChange = (e) => {
        let obj = {
            name: e.target.name,
            value: e.target.files[0]
        }
        onFormSubmit(obj)
    }

    useEffect(() => {
        let id = getItem('v_id')
        let p = productResponse?.products?.filter(item => item.vehicleId == id)
        let params = `vehicleId=${id}&colorId=${p[0]?.colors.find(color => color?.inStock === true).colorId}&stateId=${parameters}&latitude=${getItem('lat') ? getItem('lat') : '28.4595'}&longitude=${getItem('long') ? getItem('long') : '77.0266'}`
        getProductInv(params)
    })

    useEffect(() => {
        let id = getItem('v_id')
        let data = productResponse?.products?.filter(item => item?.vehicleId == id);
        setVehicleData(data[0])
        setVehicleName(data[0]?.vehicleName)
        onFormSubmit({
            vehicle_model: data[0]?.vehicleName, vehicleId: data[0]?.vehicleId,
            vehicle_color_id: data[0]?.colors?.find((color) => color?.colorId === location.state?.color)?.colorId,
            vehicle_color: data[0]?.colors?.find((color) => color?.colorId === location.state?.color)?.colorName
        })
    }, [])

    // useEffect(() => {
    //     if (loanSuccess) {
    //         history.push({
    //             pathname: '/checkout/confirmation',
    //             state: {
    //                 loanForm: true
    //             }
    //         })
    //         window.open(loanResponse?.ev_loan_form_url, '_blank', 'noopener,noreferrer')
    //     }
    // }, [loanSuccess, loanResponse])

    return (
        <div>

            <div className='main_container' style={{ marginTop: '1rem' }}>
                <div className='center'>
                    <div style={{ width: '80%' }}>
                        <h3 className='dark_heading'>
                            APPLICATION FOR SANCTION OF ELECTRIC 2-WHEELER VEHICLE LOAN FOR TELANGANA STATE GOVT.
                            EMPLOYEES ON EMI BASIS.
                        </h3>
                        <form className='m_t_20' >
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={6}>
                                    <h5>Name of the Organisation</h5>
                                    <Input name="name_of_org"
                                        value={formData.name_of_org}
                                        placeholder="Enter Name of the Organisation"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Nature of Employment </h5>
                                    <select className='input' name="empNature"
                                        value={formData.empNature} style={{ width: '100%' }} onChange={(e) => onFormChange(e)} >
                                        <option value="Permanent">Permanent</option>
                                        <option value="Deputation" >Deputation</option>
                                        <option value="Contract">Contract</option>
                                        <option value="Outsourcing">Outsourcing</option>
                                    </select>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Name of Parent Organisation</h5>
                                    <Input name="parent_org"
                                        value={formData.parent_org}
                                        placeholder="Enter Name of Parent Organisation"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Place of Working</h5>
                                    <Input name="place_of_working"
                                        value={formData.place_of_working}
                                        placeholder="Enter Place of Working"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Designation</h5>
                                    <Input name="designation"
                                        value={formData.designation}
                                        placeholder="Enter Designation"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Employee ID</h5>
                                    <Input name="emp_id"
                                        value={formData.emp_id}
                                        placeholder="Enter Employee ID"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Per Month Salary</h5>
                                    <Input name="per_month_salary"
                                        value={formData.per_month_salary}
                                        placeholder="Enter Per Month Salary"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Upload Employee Card Front</h5>
                                    <input type="file"
                                        name='employee_card_front'
                                        className='input_upload_button'
                                        accept='application/pdf, image/jpeg'
                                        onChange={(e) => handleFileChange(e)} />
                                    <label for='files'>{formData.employee_card_front_name}</label>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Upload Employee Card Back</h5>
                                    <input type="file"
                                        name='employee_card_back'
                                        className='input_upload_button'
                                        accept='application/pdf, image/jpeg'
                                        onChange={(e) => handleFileChange(e)} />
                                    <label for='files'>{formData.employee_card_back_name}</label>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Date of Joining in Service</h5>
                                    <Input name="date_of_joining"
                                        value={formData.date_of_joining}
                                        type="date"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Date of Retirement from Service</h5>
                                    <Input name="date_of_retirement"
                                        value={formData.date_of_retirement}
                                        type="date"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Name of the DDO</h5>
                                    <Input name="ddoName"
                                        value={formData.ddoName}
                                        placeholder="Enter Name of the DDO                                        "
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Designation of the DDO</h5>
                                    <Input name="ddoDesignation"
                                        value={formData.ddoDesignation}
                                        placeholder="Enter Designation of the DDO"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Official Email ID of DDO of the Employer</h5>
                                    <Input name="ddoEmail"
                                        value={formData.ddoEmail}
                                        type='email'
                                        placeholder="Enter valid Email"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Mobile Number of the DDO of the Employer</h5>
                                    <Input
                                        placeholder="Enter valid Mobile Number"
                                        value={formData.ddoPhone}
                                        name="ddoPhone"
                                        onChange={(e) => onFormChange(e)}
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Model of 2 Wheeler EV opted for Loan under EMI Scheme</h5>
                                    <Input value={formData.vehicle_model}
                                        disabled
                                        name="vehicle_model"
                                        placeholder="Enter Vehicle Model"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Vehicle Colour</h5>
                                    <Input
                                        value={formData.vehicle_color}
                                        disabled
                                        name="colourId"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>EMI Loan Tenure opted </h5>
                                    <select className='input' name="emi_tenure"
                                        value={formData.emi_tenure} style={{ width: '100%' }} onChange={(e) => onFormChange(e)} >
                                        <option value="12">12 months</option>
                                        <option value="24" >24 months</option>
                                        <option value="36">36 months</option>
                                        <option value="48">48 months</option>
                                        <option value="60">60 months</option>
                                    </select>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Loan Amount Requested</h5>
                                    <Input name="emi_per_month"
                                        value={formData.emi_per_month}
                                        placeholder="Enter EMI Per Month in Rs."
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Upload Signed Declaration Form Scanned Copy</h5>
                                    <input type="file"
                                        name='declaration_form'
                                        className='input_upload_button'
                                        accept='application/pdf, image/jpeg'
                                        onChange={(e) => handleFileChange(e)} />
                                    <label for='files'>{formData.declaration_form_name}</label>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Upload Cancelled cheque</h5>
                                    <input type="file"
                                        name='cancelled_cheque'
                                        className='input_upload_button'
                                        accept='application/pdf, image/jpeg'
                                        onChange={(e) => handleFileChange(e)} />
                                    <label for='files'>{formData.cancelled_cheque_name}</label>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default EmployeeDetails;