import Actions from "../../values/Actions";

const ORDERS_INITAL_STATE = {
    loading: false,
    error: '',
    success: false,
    response: {},
};

const TEST_DRIVE_INITAL_STATE = {
    test_loading: false,
    test_error: '',
    test_success: false,
    test_response: {},
};

const GET_TEST_DRIVE_INITAL_STATE = {
    get_test_loading: false,
    get_test_error: '',
    get_test_success: false,
    get_test_response: {},
};

const GET_DOWNLOAD_INVOICE_INITIAL = {
    get_download_invoice_loading: false,
    get_download_invoice_error: '',
    get_download_invoice_success: false,
    get_download_invoice_response: false,
    get_download_invoice_msg: {},
};

const INITAL_STATE = {
    ...ORDERS_INITAL_STATE,
    ...TEST_DRIVE_INITAL_STATE,
    ...GET_TEST_DRIVE_INITAL_STATE,
    ...GET_DOWNLOAD_INVOICE_INITIAL
};
export default (state = INITAL_STATE, action) => {
    switch (action.type) {

        case Actions.GET_ORDER_SUCCESS:
            return { ...state, ...ORDERS_INITAL_STATE, success: true, response: action.payload }
        case Actions.GET_ORDER_PROGRESS:
            return { ...state, loading: true }
        case Actions.GET_ORDER_ERROR:
            return { ...state, ...ORDERS_INITAL_STATE, error: action.payload }

        case Actions.ADD_TEST_DRIVE_SUCCESS:
            return { ...state, ...TEST_DRIVE_INITAL_STATE, test_success: true, test_response: action.payload }
        case Actions.ADD_TEST_DRIVE_PROGRESS:
            return { ...state, test_loading: true, test_success: false }
        case Actions.ADD_TEST_DRIVE_ERROR:
            return { ...state, ...TEST_DRIVE_INITAL_STATE, test_error: action.payload }


        case Actions.GET_TEST_DRIVE_SUCCESS:
            return { ...state, ...GET_TEST_DRIVE_INITAL_STATE, get_test_success: true, get_test_response: action.payload }
        case Actions.GET_TEST_DRIVE_PROGRESS:
            return { ...state, get_test_loading: true }
        case Actions.GET_TEST_DRIVE_ERROR:
            return { ...state, ...GET_TEST_DRIVE_INITAL_STATE, get_test_error: action.payload }

        case Actions.GET_DOWNLOAD_INVOICE_SUCCESS:
            return { ...state, ...GET_DOWNLOAD_INVOICE_INITIAL, get_download_invoice_loading: false, get_download_invoice_success: true, get_download_invoice_response: action.payload }
        case Actions.GET_DOWNLOAD_INVOICE_LOADING:
            return { ...state, get_download_invoice_loading: true, get_download_invoice_success: false }
        case Actions.GET_DOWNLOAD_INVOICE_ERROR:
            return { ...state, ...GET_DOWNLOAD_INVOICE_INITIAL, get_download_invoice_loading: false, get_download_invoice_success: false, get_download_invoice_msg: action.payload }


        default:
            return state
    }
}
