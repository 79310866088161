import React, { useState, useEffect } from 'react';
import { getApplicationForm } from '../../../redux/actions/Confirmation';
import { useDispatch, useSelector } from 'react-redux';

const Instruction = () => {
    const dispatch = useDispatch();

    const initialFormData = useSelector(state => state.confirmationReducer.get_application_response)

    useEffect(() => {
        dispatch(getApplicationForm())
    }, [])

    const onClickHere = (initialFormData) => {
        window.open(initialFormData?.link, '_blank', 'noopener,noreferrer')
    }

    return (
        <div className='main_container' style={{ marginTop: '1rem', paddingBottom: 8 }}>
            <div className='center'>
                <div style={{ width: '80%' }}>
                    <div style={{ fontSize: 25, fontWeight: 500 }}>
                        {initialFormData.heading}
                    </div>
                    <div style={{ marginTop: 10, fontSize: 20, fontWeight: 500 }} >
                        {initialFormData?.instructions?.map((data, i) => (
                            <div key={i} style={{ marginBottom: '1.2%' }}>{data.instruction}
                                {i == 0 ? <span> <span onClick={() => onClickHere(initialFormData)}
                                    style={{ color: 'red', marginLeft: 3, cursor: 'pointer', textDecoration: 'underline' }}>
                                    click here</span>.</span> : null}
                            </div>
                        ))}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Instruction;