const BASE_URL = 'BASE_URL_DASHBOARD'
const BASE_URL_ECOM = 'BASE_URL_ECOM'
const BACKOFFICE_URL = 'BACKOFFICE_URL'
const VERSION = 'v1'

export default {
    GET_STATES: `${BASE_URL}common/${VERSION}/state`,
    GET_OEM_LIST: `${BASE_URL}oem/${VERSION}/list-oem`,
    GET_PRODUCTS: `${BASE_URL_ECOM}product/${VERSION}/product`,
    GET_PRODUCT_DETAIL: `${BASE_URL_ECOM}product/${VERSION}/product-details`,
    GET_PRODUCT_INV: `${BASE_URL_ECOM}product/${VERSION}/product-inventory?`,
    GET_PRODUCT_IMAGES_BY_COLOR_URL: `${BASE_URL_ECOM}product/${VERSION}/product-images?`,
    GET_OTP: `${BASE_URL_ECOM}customer/${VERSION}/OTP`,
    AUTH_OTP: `${BASE_URL_ECOM}customer/${VERSION}/customer-account`,
    CUSTOMER_PROFILE: `${BASE_URL_ECOM}customer/${VERSION}/customer-profile`,
    ADDRESS_URL: `${BASE_URL_ECOM}customer/${VERSION}/address`,
    PAYMENT_URL: `${BASE_URL_ECOM}order/${VERSION}`,
    GET_ORDERS: `${BASE_URL_ECOM}order/${VERSION}`,
    GET_ORDERS_INVOICE: `${BASE_URL_ECOM}order/${VERSION}/download/advance_receipt?`,
    TEST_DRIVE: `${BASE_URL_ECOM}order/${VERSION}/test-drive`,
    REMAINING_PAYMENT: `${BASE_URL_ECOM}payment/`,
    COMPLAINT_URL: `${BASE_URL_ECOM}complaint/`,
    LAT_LONG_URL: `${BACKOFFICE_URL}${VERSION}/dashboard/stationMap/eesl`,
    FAQ_URL: `${BASE_URL_ECOM}faqs/${VERSION}`,
    LOAN_FORM: `${BASE_URL_ECOM}evloanform`,
    APPLICATION_FORM: `${BASE_URL_ECOM}getapplicationdata`,
    GET_APPLICATION_LIST: `${BASE_URL_ECOM}get_user_loan_application`
}