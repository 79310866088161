import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Products from './Products';
import Orders from './Orders';
import Product_icon from '../assets/images/product_icon.svg';
import device_logo from '../assets/images/device_logo.svg';
import Order_icon from '../assets/images/order_icon.svg';
import './Catalogue.css'
import ProductsDetails from './ProductsDetails';
import { useSelector } from 'react-redux';
import AboutUs from './AboutUs';
import PrivacyPolicy from './PrivacyPolicy';
import MapView from './Map/MapView';
import Faq from './Faq';
import Loan from './LoanForm/Loan';
import LoanTracker from './LoanForm/LoanTracker';



const ProductsCatalogue = (props) => {

    const { component } = useParams();

    const [active, setActive] = useState('catalogue');
    const [isTrue, setIsTrue] = useState(false);

    const isAuth = useSelector(state => state.authReducer.login_success)
    const isLoan = useSelector(state => state.authReducer.login_response?.ev_loan_flag);
    const isLoanCustomer = useSelector(state => state.authReducer.customer_response?.ev_loan_flag);

    const postSuccess = useSelector(state => state.confirmationReducer.loan_success)
    const initialFormData = useSelector(state => state.confirmationReducer.get_loan_response)
    useEffect(() => {
        setActive(component ? component : 'catalogue')
        window.scrollTo(0, 0);
    }, [component]);

    // useEffect(() => {
    //     console.log("isTrue", isAuth, postSuccess, initialFormData?.id)

    //     if (isAuth && isLoan) {
    //         setIsTrue(true)
    //     }
    //     else if (isAuth && postSuccess) {
    //         setIsTrue(true)
    //     }
    //     else if (initialFormData?.id !== undefined) {
    //         setIsTrue(true)
    //     }
    //     else setIsTrue(false)
    // }, [isAuth, isLoan, postSuccess, initialFormData])



    const renderComponent = (component) => {
        switch (component) {
            case 'catalogue':
                return <Products />
            case 'orders':
                return <Orders />
            case 'productdetail':
                return <ProductsDetails />
            case 'about-us':
                return <AboutUs />
            case 'privacy-policy':
                return <PrivacyPolicy />
            case 'faq':
                return <Faq />
            case 'map-view':
                return <MapView />
            case 'loan':
                return <Loan />
            case 'loan-tracker':
                return <LoanTracker />
            default:
                return <Products />
        }
    }

    return (
        <div>
            <div className="view_wrapper">
                <div className="side_container_wrapper">
                    <div className="side_sub_wrapper">
                        <ul>
                            <Link to='/'>
                                <li className="center" style={{ borderBottom: isAuth ? '1px dashed' : 'none', flexDirection: 'column' }}>
                                    <img src={Product_icon} alt="product" width="30" height="30" />
                                    <span className='menu_label'>Products</span>
                                </li>
                            </Link>
                            {
                                isAuth &&
                                <Link to='/orders'>
                                    <li className="center" style={{ flexDirection: 'column' }}>
                                        <img src={Order_icon} alt="product" width="30" height="30" />
                                        <span className='menu_label'>My Orders</span>
                                    </li>
                                </Link>
                            }
                            {(isLoan || isLoanCustomer) &&
                                <Link to='/loan-tracker'>
                                    <li className="center" style={{ borderTop: isAuth ? '1px dashed' : 'none', flexDirection: 'column' }}>
                                        <img src={device_logo} alt="product" width="30" height="30" />
                                        <span className='menu_label'>My Application</span>
                                    </li>
                                </Link>
                            }

                        </ul>
                    </div>
                </div>
                <div style={{ flexGrow: '1', height: '83vh', padding: 12 }}>
                    {renderComponent(component)}
                </div>

            </div>

        </div>
    )
}

export default ProductsCatalogue
