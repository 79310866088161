import React, { useEffect, useState } from 'react'
import './Stepper.css';
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Box  from '@material-ui/core/Box';


const StepperComp = ({ step , stepId }) => {


const steps = [
    'Paid', 'Confirmed by TSREDCO', 'Billed', 'Registered', 'Delivered'
  ];

   return(
    <Box sx={{ width: '100%', fontSize: '10px' }}>
    <Stepper activeStep={stepId} alternativeLabel>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label === 'Confirmed by TSREDCO' ? 'Confirmed' : label}</StepLabel>
      </Step>
    ))}
  </Stepper>
  </Box>
   )

//     const label = ['Paid', 'Confirmed', 'Billed', 'Registered', 'Delivered'];
 

//     const [indx, setIndx] = useState(0)

//     useEffect(() => {
//         setIndx(label.findIndex((item) => item.trim().toLocaleLowerCase() === step.trim().toLocaleLowerCase()))
//     }, [])

//     return (

//         <ul className='progress-wrapper'>
//             {
//                 label.map((item, i) => {

//                     return <li key={i}>
//                         {item}
//                     </li>
//                     // <div key={i} className="stepper-wrapper">
//                     //     <div className="stepper-circle" style={{ backgroundColor: indx >= i && '#51A653' }}></div>
//                     //     <div className="stepper-label" style={{ color: indx >= i && '#51A653' }}>{i === 1 ? 'Confirmed' : item}</div>
//                     //     {
//                     //         i < label.length - 1 && <div style={{ backgroundColor: indx >= i && '#51A653' }} className={`stepper-line`}></div>
//                     //     }

//                     //     {/* <div className="stepper-line-active" style={{ backgroundColor: '#51A653', width: indx <= 2 ? `${indx * 24.5}%` : `${indx * 22.5}%` }}></div> */}
//                     // </div>

//                 })
//             }

//         </ul>
//     )
}

export default StepperComp



// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//     [`&.${stepConnectorClasses.alternativeLabel}`]: {
//       top: 10,
//       left: 'calc(-50% + 16px)',
//       right: 'calc(50% + 16px)',
//     },
//     [`&.${stepConnectorClasses.active}`]: {
//       [`& .${stepConnectorClasses.line}`]: {
//         borderColor: '#784af4',
//       },
//     },
//     [`&.${stepConnectorClasses.completed}`]: {
//       [`& .${stepConnectorClasses.line}`]: {
//         borderColor: '#784af4',
//       },
//     },
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor:
//         theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//       borderTopWidth: 3,
//       borderRadius: 1,
//     },
//   }));
  
//   const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
//     color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
//     display: 'flex',
//     height: 22,
//     alignItems: 'center',
//     ...(ownerState.active && {
//       color: '#784af4',
//     }),
//     '& .QontoStepIcon-completedIcon': {
//       color: '#784af4',
//       zIndex: 1,
//       fontSize: 18,
//     },
//     '& .QontoStepIcon-circle': {
//       width: 8,
//       height: 8,
//       borderRadius: '50%',
//       backgroundColor: 'currentColor',
//     },
//   }));
  
//   function QontoStepIcon(props) {
//     const { active, completed, className } = props;
  
//     return (
//       <QontoStepIconRoot ownerState={{ active }} className={className}>
//         {completed ? (
//           <Check className="QontoStepIcon-completedIcon" />
//         ) : (
//           <div className="QontoStepIcon-circle" />
//         )}
//       </QontoStepIconRoot>
//     );
//   }
  
//   const steps = [
//     'Select campaign settings',
//     'Create an ad group',
//     'Create an ad',
//   ];
  
//   export default function CustomizedSteppers() {
//     return (
     
//         <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
//           {steps.map((label) => (
//             <Step key={label}>
//               <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
//             </Step>
//           ))}
//         </Stepper>
  
//     );
//   }
  

