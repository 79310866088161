import { HTTPGetRequest, HTTPRequest, HTTPPutRequest, HTTPDeleteRequest, HTTPFileUploadRequest, HTTPPatchRequest } from "../../helper";
import Actions from "../../values/Actions";
import ServerUrls from "../../values/ServerUrls";
import store from '../store';
import { setAlert } from "./AlertActions";

export const resetAddAddress = () => ({
    type: Actions.ADD_ADDRESS_PROGRESS
})
export const resetEditAddress = () => ({
    type: Actions.EDIT_ADDRESS_PROGRESS
})

export const ResetLoan = () => ({
    type: Actions.LOAN_LOADING
})

export const getAddress = (id) => {
    return (dispatch) => {
        dispatch({ type: Actions.ADDRESS_PROGRESS })
        HTTPGetRequest(`${ServerUrls.ADDRESS_URL}?stateId=${id}`, {
            authToken: store.getState().authReducer.token
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.ADDRESS_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.ADDRESS_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.ADDRESS_ERROR, payload: error.message })
        })
    }

}

export const addAddress = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.ADD_ADDRESS_PROGRESS })
        HTTPRequest(`${ServerUrls.ADDRESS_URL}`, {
            authToken: store.getState().authReducer.token
        }, {
            "stateId": data.stateId,
            "street": data.street,
            "pincode": data.pincode,
            "city": data.city,
            "phone": data.phone
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.ADD_ADDRESS_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch({ type: Actions.ADD_ADDRESS_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.ADD_ADDRESS_ERROR, payload: error.message })
        })
    }

}

export const editAddress = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.EDIT_ADDRESS_PROGRESS })
        HTTPPutRequest(`${ServerUrls.ADDRESS_URL}`, {
            authToken: store.getState().authReducer.token
        }, {
            "stateId": data.stateId,
            "street": data.street,
            "pincode": data.pincode,
            "city": data.city,
            "phone": data.phone,
            "deliveryId": data.id
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.EDIT_ADDRESS_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {

                dispatch({ type: Actions.EDIT_ADDRESS_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.EDIT_ADDRESS_ERROR, payload: error.message })
        })
    }

}
export const deleteAddress = (id) => {
    return (dispatch) => {
        dispatch({ type: Actions.DELETE_ADDRESS_PROGRESS })
        HTTPDeleteRequest(`${ServerUrls.ADDRESS_URL}?deliveryId=${id}`, {
            authToken: store.getState().authReducer.token,
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.DELETE_ADDRESS_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {

                dispatch({ type: Actions.DELETE_ADDRESS_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.DELETE_ADDRESS_ERROR, payload: error.message })
        })
    }

}


export const getEvLoanForm = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_LOAN_LOADING })
        HTTPGetRequest(`${ServerUrls.LOAN_FORM}?application_number=${data}`, {
            authToken: store.getState().authReducer.token
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_LOAN_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_LOAN_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_LOAN_ERROR, payload: error.message })
        })
    }

}

export const evLoanForm = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.LOAN_LOADING })
        HTTPFileUploadRequest(`${ServerUrls.LOAN_FORM}`, {
            authToken: store.getState().authReducer.token
        }, {
            "name": data.name_of_emp,
            "fatherName": data.father_name,
            "presentAdd": data.present_address,
            "permanentAdd": data.permanent_address,
            "orgName": data.name_of_org,
            "place": data.place_of_working,
            'phone': data.phone,
            "empNature": data.empNature,
            "parentOrg": data.parent_org,
            "designation": data.designation,
            "empId": data.emp_id,
            "dob": data.dob,
            "joiningDate": data.date_of_joining,
            "retirementDate": data.date_of_retirement,
            "monthlySalary": data.per_month_salary,
            "aadharNum": data.aadhar_number,
            "panNum": data.pan_number,
            "vehicleId": data.vehicleId,
            "vehicle_name": data.vehicle_model,
            "colourId": data.vehicle_color_id,
            "colur_name": data.vehicle_color,
            "emiTenure": data.emi_tenure,
            "monthEmi": data.emi_per_month,
            "aadhar_card": data.aadhar_card,
            "pan_card": data.pan_card,
            "ddoName": data.ddoName,
            "ddoDesignation": data.ddoDesignation,
            "ddoEmail": data.ddoEmail,
            "ddoPhone": data.ddoPhone,
            "email": data.email,
            "aadhar_card_front": data.aadhar_card_front,
            "aadhar_card_back": data.aadhar_card_back,
            "passport_photo": data.passport_photo,
            "employee_card_front": data.employee_card_front,
            "employee_card_back": data.employee_card_back,
            "declaration_form": data.declaration_form,
            "cancelled_cheque": data.cancelled_cheque,
            "status": data.status
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.LOAN_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch({ type: Actions.LOAN_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.LOAN_ERROR, payload: error.message })
        })
    }

}

export const UpdateEvLoanForm = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.PATCH_LOAN_LOADING })
        HTTPPatchRequest(`${ServerUrls.LOAN_FORM}`, {
            authToken: store.getState().authReducer.token
        }, {
            "name": data.name_of_emp,
            "fatherName": data.father_name,
            "presentAdd": data.present_address,
            "permanentAdd": data.permanent_address,
            "orgName": data.name_of_org,
            "place": data.place_of_working,
            'phone': data.phone,
            "empNature": data.empNature,
            "parentOrg": data.parent_org,
            "designation": data.designation,
            "empId": data.emp_id,
            "dob": data.dob,
            "joiningDate": data.date_of_joining,
            "retirementDate": data.date_of_retirement,
            "monthlySalary": data.per_month_salary,
            "aadharNum": data.aadhar_number,
            "panNum": data.pan_number,
            "vehicleId": data.vehicleId,
            "vehicle_name": data.vehicle_model,
            "colourId": data.vehicle_color_id,
            "colur_name": data.vehicle_color,
            "emiTenure": data.emi_tenure,
            "monthEmi": data.emi_per_month,
            "aadhar_card": data.aadhar_card,
            "pan_card": data.pan_card_name ? data.pan_card_name : data.pan_card,
            "ddoName": data.ddoName,
            "ddoDesignation": data.ddoDesignation,
            "ddoEmail": data.ddoEmail,
            "ddoPhone": data.ddoPhone,
            "email": data.email,
            "aadhar_card_front": data.aadhar_card_front_name ? data.aadhar_card_front_name : data.aadhar_card_front,
            "aadhar_card_back": data.aadhar_card_back_name ? data.aadhar_card_back_name : data.aadhar_card_back,
            "passport_photo": data.passport_photo_name ? data.passport_photo_name : data.passport_photo,
            "employee_card_front": data.employee_card_front_name ? data?.employee_card_front_name : data.employee_card_front,
            "employee_card_back": data.employee_card_back_name ? data.employee_card_back_name : data.employee_card_back,
            "declaration_form": data.declaration_form_name ? data.declaration_form_name : data.declaration_form,
            "cancelled_cheque": data.cancelled_cheque_name ? data.cancelled_cheque_name : data.cancelled_cheque,
            'application_number': data.application_num,
            'status': data.status
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.PATCH_LOAN_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch({ type: Actions.PATCH_LOAN_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.PATCH_LOAN_ERROR, payload: error.message })
        })
    }

}
export const getApplicationForm = () => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_APPLICATION_LOADING })
        HTTPGetRequest(`${ServerUrls.APPLICATION_FORM}`, {
            authToken: store.getState().authReducer.token
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_APPLICATION_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_APPLICATION_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_APPLICATION_ERROR, payload: error.message })
        })
    }

}



export const getApplicationList = () => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_APPLICATION_LIST_LOADING })
        HTTPGetRequest(`${ServerUrls.GET_APPLICATION_LIST}`, {
            authToken: store.getState().authReducer.token
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_APPLICATION_LIST_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_APPLICATION_LIST_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_APPLICATION_LIST_ERROR, payload: error.message })
        })
    }

}