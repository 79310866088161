import React from 'react'
import { Route } from 'react-router-dom'
import AuthLayout from '../auth/AuthLayout'

const AuthRoutes = (props) => {

    const { component : Component , ...rest } = props
    return (
        <Route {...rest} render={props =>(
         <>
         <AuthLayout>
          <Component {...props} />
         </AuthLayout>
         </>   
        )} />
    )
}

export default AuthRoutes
