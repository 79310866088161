import React from 'react'
import banner from '../assets/images/banner.svg'
const AboutUs = () => {
    return (
        <div>
            <div className="sub_heading" style={{ justifyContent: 'center', marginLeft: '-10%', paddingTop: '1%', height: 'auto' }}>
                <h1 style={{ color: '#074B77' }}>About Us</h1>
            </div>
            <div style={{ marginTop: '8%' }}>
                <img src={banner} style={{ width: '100%' }} />
            </div>

            <div className="main_container" style={{ marginTop: '2rem' }}>

                <div>
                    <p className='about_us_data'>
                        In pursuance of the section 53 of the AP Reorganization Act 2014, a separate Telangana State Renewable Energy Development Corporation Ltd., (TSREDCO) (formerly NREDCAP/TNREDCL) has been created vide G.O. Ms no.27 and G.E no 25 dated 30.05.2014 of Energy (CC) Department, to act as Nodal Agency for implementing all New and Renewable Energy Programmes ,
                        vide G.O. Ms no.19 dated 03.07.2015 of Energy (CC) Department, to act as Designated Agency for implementing all Energy Conservation Programmes and vide G.O. Rt no.7 dated 07.02.2019 of Energy (power I) Department, to act as State Nodal Agency (SNA) Cum Implementing agency for implementation of Public EV Charging Station for the State of the Telangana as per the guideline of Ministry of Power, Government of India.
                    </p>
                    <p className='about_us_data'>
                        Telangana State Renewable Energy Development Corporation Ltd., (TSREDCO) is nominated by Government of Telangana, for implementing the following Programmes:
                    </p>
                    <p className='about_us_data'>
                        <span>
                            · State Nodal Agency (SNA): As SNA, TSREDCO is implementing all New and Renewable Energy Programmes G.O MS No 25
                            <a href='https://tsredco.telangana.gov.in/Updates_2021/2015ENY_MS25.pdf' target="_blank" style={{ color: 'blue', textDecoration: 'underline', paddingLeft: 5 }}>Click Here</a>

                        </span>                         <br />
                        <span>
                            · State Designated Agency (SDA): As SDA, TSREDCO is promoting Energy Conservation activities in the state of Telangana under the provisions of Energy Conservation Act 2001 G.O MS No 19
                            <a href='https://tsredco.telangana.gov.in/Updates_2021/2015ENY_MS19.pdf' style={{ color: 'blue', textDecoration: 'underline', paddingLeft: 5 }}>Click Here</a>
                        </span>
                        <br />
                        <span>
                            · State Nodal & Implementation Agency(SNA): For setting up of EV charging infrastructure for Electric vehicles G.O RT No 7
                            <a href='https://tsredco.telangana.gov.in/Updates_2021/2019ENY_RT7.pdf' style={{ color: 'blue', textDecoration: 'underline', paddingLeft: 5 }}>Click Here</a>
                        </span>

                        <br />
                        <span>
                            · Telangana State Electric Vehicle & Energy Storage System Policy 2020-2030. G.O MS No 12
                            <a href='https://tsredco.telangana.gov.in/Updates_2020/Telangana_EVES_policy_2020_30.pdf' style={{ color: 'blue', textDecoration: 'underline', paddingLeft: 5 }}>Click Here</a>
                        </span>
                    </p>
                    <p className='about_us_data'>
                        Accordingly, TSREDCO is promoting the above programmes and activities in the state as a State Nodal Agency to Ministry of New & Renewable Energy (MNRE) for Renewable Energy Programs & Electric Vehicle Charging Stations and other EV Eco System, and State Designated Agency to Bureau of Energy Efficiency (BEE), Ministry of Power, Government of India respectively under the administrative control of Energy Department, Government of Telangana.
                    </p>
                </div>

            </div>
        </div>



    )
}

export default AboutUs
