import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import LoginImage from '../../assets/images/login_image.svg';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Input from '../../../storybook/Input/Input';
import LockIcon from '@material-ui/icons/Lock';
import { useDispatch , useSelector} from 'react-redux';
import { newPassword } from '../../../redux/actions';
import AppConstants from '../../../values/AppConstants';
import { useEffect } from 'react';
import { useHistory } from 'react-router';


const EnterResetPassword = () => {

    const dispatch = useDispatch();
    const new_pass_success = useSelector(state => state.authReducer.new_pass_success)
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [verified, setVerified] = useState(true);
    const [sixChar, setSixChar] = useState(true);
    const [first , setFirst] = useState(false)


    const confirmPassword = (e) => {
        e.preventDefault()
        setSixChar(password.length >= 6 && confirmPass.length >= 6 ? true : false)
        setVerified(password === confirmPass ? true : false)
        setFirst(true)
    }

    useEffect(()=>{
      first &&  verified && sixChar && dispatch(newPassword({phone : AppConstants.phone , password : password}))
    },[verified , sixChar, first])

    useEffect(()=>{
     new_pass_success && history.push('/auth/change-password-done')
    },[new_pass_success])

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <div className="reg_form_img_wrapper" className='form_view'>
                        <div className="top_img_wrapper">
                            <span className="top_heading_wrapper">
                                <h1>2W Digital Platform</h1>
                                <h3 className="xs_heading">A completely paperless, cashless and system driven e-market place for buying and selling of EV</h3>
                            </span>

                            <img className="reg_form_img" src={LoginImage} alt="signup" />
                        </div>

                    </div>
                </Grid>



                <Grid item xs={12} md={4} className="center">
                    <Paper elevation={2} className="p_50 forgot_form_wrapper">
                        <div className="top_heading_wrapper p-b-30">

                            <h1>Reset your Password</h1>
                        </div>

                        <form onSubmit={(e) => confirmPassword(e)}>
                            <div className='m_t_40 m_b_40'>
                                <h5>New Password</h5>
                                <Input type="password" placeholder="Enter New Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className='m_t_40 m_b_40'>
                                <h5>Confirm New Password</h5>
                                <Input type="password" placeholder="Confirm New Password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
                            </div>

                            <div className="center" style={{ flexDirection: 'column' }}>
                                {
                                    !sixChar && <h5 style={{ color: 'red' }}>Password must have six characters</h5>
                                }
                                {
                                    !verified && <h5 style={{ color: 'red' }}>Password not match</h5>
                                }
                                <Button
                                    variant="contained"
                                    startIcon={<LockIcon />}
                                    type="submit"
                                    style={{ backgroundColor: '#203594', color: '#fff', borderRadius: 10 }}>
                                    Confirm
                                </Button>
                            </div>

                        </form>

                    </Paper>
                </Grid>
            </Grid>
        </div>

    )
}

export default EnterResetPassword
