import React from 'react'
import Grid from '@material-ui/core/Grid';
import LoginImage from '../../assets/images/login_image.svg';
import Shield from '../../assets/images/shield.svg';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import LockIcon from '@material-ui/icons/Lock';
import { useEffect } from 'react';
import { useDispatch  } from 'react-redux';
import { resetNewPassword } from '../../../redux/actions';
import { useHistory } from 'react-router';

const Confirmation = () => {

    const history = useHistory();


    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <div className="reg_form_img_wrapper" className='form_view'>
                        <div className="top_img_wrapper">
                            <span className="top_heading_wrapper">
                                <h1>2W Digital Platform</h1>
                                <h3 className="xs_heading">A completely paperless, cashless and system driven e-market place for buying and selling of EV</h3>
                            </span>

                            <img className="reg_form_img" src={LoginImage} alt="signup" />
                        </div>

                    </div>
                </Grid>



                <Grid item xs={12} md={4} className="center">
                    <Paper elevation={2} className="p_50 forgot_form_wrapper">
                        <div className="top_heading_wrapper p-b-30">
                            <img src={Shield} alt="done" height="100" className="p-b-30" />
                            <h1>Password Reset</h1>
                            <h3>Completed Successfully</h3>
                        </div>

                    

                            <div className="center" style={{ flexDirection: 'column' }}>
                             <h4 className="p-b-10">Please Login  to Continue</h4>
                                <Button
                                    onClick={() => history.push('/auth/login')}
                                    variant="contained"
                                    startIcon={<LockIcon />}
                                    type="submit"
                                    style={{ backgroundColor: '#203594', color: '#fff', borderRadius: 10 }}>
                                    Login
                                </Button>
                            </div>

                     

                    </Paper>
                </Grid>
            </Grid>
        </div>

    )
}

export default Confirmation
