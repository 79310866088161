
import './App.css';
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import CatalogueRoutes from './components/routes/CatalogueRoutes';
import ProductsCatalogue from './components/catalogue/ProductsCatalogue';
import ConfirmationRoutes from './components/routes/ConfirmationRoutes';
import Confirmation from './components/confirmation/Confirmation';
import AuthRoutes from './components/routes/AuthRoutes';
import Auth from './components/auth/Auth';
import { Provider } from 'react-redux';
import Store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import Alert from './storybook/Alert/Alert';
import { useEffect } from 'react';
import VALUES from '../src/values/AppConstants';
import { useHistory } from 'react-router-dom';


function App() {

  const history = useHistory();

  useEffect(() => {
    if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
      localStorage.clear();
      window.location.href = '/'
      localStorage.setItem('APP_VERSION', VALUES.VERSION);
    }

    if (localStorage.APP_VERSION != VALUES.VERSION) {
      localStorage.clear();
      window.location.href = '/'

    }
  }, [])

  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Alert />
          <Switch>
            <CatalogueRoutes path={['/', '/:component', '/:component/:id/:stateId']} exact component={ProductsCatalogue} />
            <CatalogueRoutes path='/:orders' exact component={ProductsCatalogue} />
            <ConfirmationRoutes path='/checkout/:component' exact component={Confirmation} />
            <AuthRoutes path='/auth/:component' exact component={Auth} />
          </Switch>

        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
