export default {

    SET_ALERT: 'SET_ALERT',
    REMOVE_ALERT: 'REMOVE_ALERT',

    GET_STATE_SUCCESS: 'GET_STATE_SUCCESS',
    GET_STATE_ERROR: 'GET_STATE_ERROR',
    GET_STATE_PROGRESS: 'GET_STATE_PROGRESS',

    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',
    GET_PRODUCTS_PROGRESS: 'GET_PRODUCTS_PROGRESS',

    GET_PRODUCT_DETAIL_SUCCESS: 'GET_PRODUCT_DETAIL_SUCCESS',
    GET_PRODUCT_DETAIL_ERROR: 'GET_PRODUCT_DETAIL_ERROR',
    GET_PRODUCT_DETAIL_PROGRESS: 'GET_PRODUCT_DETAIL_PROGRESS',

    GET_PRODUCT_INV_SUCCESS: 'GET_PRODUCT_INV_SUCCESS',
    GET_PRODUCT_INV_ERROR: 'GET_PRODUCT_INV_ERROR',
    GET_PRODUCT_INV_PROGRESS: 'GET_PRODUCT_INV_PROGRESS',

    GET_OTP_SUCCESS: 'GET_OTP_SUCCESS',
    GET_OTP_ERROR: 'GET_OTP_ERROR',
    GET_OTP_PROGRESS: 'GET_OTP_PROGRESS',
    OTP_RESET: 'OTP_RESET',

    GET_OTP_VERIFY_SUCCESS: 'GET_OTP_VERIFY_SUCCESS',
    GET_OTP_VERIFY_ERROR: 'GET_OTP_VERIFY_ERROR',
    GET_OTP_VERIFY_PROGRESS: 'GET_OTP_VERIFY_PROGRESS',
    GET_OTP_VERIFY_RESET: 'GET_OTP_VERIFY_RESET',

    NEW_PASS_VERIFY_SUCCESS: 'NEW_PASS_VERIFY_SUCCESS',
    NEW_PASS_VERIFY_ERROR: 'NEW_PASS_VERIFY_ERROR',
    NEW_PASS_VERIFY_PROGRESS: 'NEW_PASS_VERIFY_PROGRESS',
    NEW_PASS_VERIFY_RESET: 'NEW_PASS_VERIFY_RESET',

    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGIN_PROGRESS: 'LOGIN_PROGRESS',

    LOGOUT: 'LOGOUT',

    GET_REGISTER_SUCCESS: 'GET_REGISTER_SUCCESS',
    GET_REGISTER_ERROR: 'GET_REGISTER_ERROR',
    GET_REGISTER_PROGRESS: 'GET_REGISTER_PROGRESS',

    ADDRESS_SUCCESS: 'ADDRESS_SUCCESS',
    ADDRESS_ERROR: 'ADDRESS_ERROR',
    ADDRESS_PROGRESS: 'ADDRESS_PROGRESS',

    ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
    ADD_ADDRESS_ERROR: 'ADD_ADDRESS_ERROR',
    ADD_ADDRESS_PROGRESS: 'ADD_ADDRESS_PROGRESS',

    EDIT_ADDRESS_SUCCESS: 'EDIT_ADDRESS_SUCCESS',
    EDIT_ADDRESS_ERROR: 'EDIT_ADDRESS_ERROR',
    EDIT_ADDRESS_PROGRESS: 'EDIT_ADDRESS_PROGRESS',

    RAZORPAY_SUCCESS: 'RAZORPAY_SUCCESS',
    RAZORPAY_ERROR: 'RAZORPAY_ERROR',
    RAZORPAY_PROGRESS: 'RAZORPAY_PROGRESS',
    RAZORPAY_RESET: 'RAZORPAY_RESET',

    RAZORPAY_REMAINING_SUCCESS: 'RAZORPAY_REMAINING_SUCCESS',
    RAZORPAY_REMAINING_ERROR: 'RAZORPAY_REMAINING_ERROR',
    RAZORPAY_REMAINING_PROGRESS: 'RAZORPAY_REMAINING_PROGRESS',
    RAZORPAY_REMAINING_RESET: 'RAZORPAY_REMAINING_RESET',

    GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
    GET_ORDER_ERROR: 'GET_ORDER_ERROR',
    GET_ORDER_PROGRESS: 'GET_ORDER_PROGRESS',

    ADD_TEST_DRIVE_SUCCESS: 'ADD_TEST_DRIVE_SUCCESS',
    ADD_TEST_DRIVE_ERROR: 'ADD_TEST_DRIVE_ERROR',
    ADD_TEST_DRIVE_PROGRESS: 'ADD_TEST_DRIVE_PROGRESS',


    GET_TEST_DRIVE_SUCCESS: 'GET_TEST_DRIVE_SUCCESS',
    GET_TEST_DRIVE_ERROR: 'GET_TEST_DRIVE_ERROR',
    GET_TEST_DRIVE_PROGRESS: 'GET_TEST_DRIVE_PROGRESS',

    GET_OEM_LIST_SUCCESS: 'GET_OEM_LIST_SUCCESS',
    GET_OEM_LIST_ERROR: 'GET_OEM_LIST_ERROR',
    GET_OEM_LIST_PROGRESS: 'GET_OEM_LIST_PROGRESS',

    DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
    DELETE_ADDRESS_ERROR: 'DELETE_ADDRESS_ERROR',
    DELETE_ADDRESS_PROGRESS: 'DELETE_ADDRESS_PROGRESS',

    COMPLAINT_ADD_RESET: 'COMPLAINT_ADD_RESET',
    COMPLAINT_ADD_SUCCESS: 'COMPLAINT_ADD_SUCCESS',
    COMPLAINT_ADD_ERROR: 'COMPLAINT_ADD_ERROR',
    COMPLAINT_ADD_PROGRESS: 'COMPLAINT_ADD_PROGRESS',


    GET_PRODUCT_IMAGES_BY_COLOR_SUCCESS: 'GET_PRODUCT_IMAGES_BY_COLOR_SUCCESS',
    GET_PRODUCT_IMAGES_BY_COLOR_ERROR: 'GET_PRODUCT_IMAGES_BY_COLOR_ERROR',
    GET_PRODUCT_IMAGES_BY_COLOR_PROGRESS: 'GET_PRODUCT_IMAGES_BY_COLOR_PROGRESS',

    GET_LAT_LONG_SUCCESS: 'GET_LAT_LONG_SUCCESS',
    GET_LAT_LONG_ERROR: 'GET_LAT_LONG_ERROR',
    GET_LAT_LONG_PROGRESS: 'GET_LAT_LONG_PROGRESS',

    GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
    GET_FAQ_ERROR: 'GET_FAQ_ERROR',
    GET_FAQ_PROGRESS: 'GET_FAQ_PROGRESS',

    GET_DOWNLOAD_INVOICE_SUCCESS: 'GET_DOWNLOAD_INVOICE_SUCCESS',
    GET_DOWNLOAD_INVOICE_ERROR: 'GET_DOWNLOAD_INVOICE_ERROR',
    GET_DOWNLOAD_INVOICE_LOADING: 'GET_DOWNLOAD_INVOICE_LOADING',

    LOAN_SUCCESS: 'LOAN_SUCCESS',
    LOAN_ERROR: 'LOAN_ERROR',
    LOAN_LOADING: 'LOAN_LOADING',

    GET_LOAN_SUCCESS: 'GET_LOAN_SUCCESS',
    GET_LOAN_ERROR: 'GET_LOAN_ERROR',
    GET_LOAN_LOADING: 'GET_LOAN_LOADING',

    PATCH_LOAN_SUCCESS: 'PATCH_LOAN_SUCCESS',
    PATCH_LOAN_ERROR: 'PATCH_LOAN_ERROR',
    PATCH_LOAN_LOADING: 'PATCH_LOAN_LOADING',

    GET_APPLICATION_SUCCESS: 'GET_APPLICATION_SUCCESS',
    GET_APPLICATION_ERROR: 'GET_APPLICATION_ERROR',
    GET_APPLICATION_LOADING: 'GET_APPLICATION_LOADING',

    GET_APPLICATION_LIST_SUCCESS: 'GET_APPLICATION_LIST_SUCCESS',
    GET_APPLICATION_LIST_ERROR: 'GET_APPLICATION_LIST_ERROR',
    GET_APPLICATION_LIST_LOADING: 'GET_APPLICATION_LIST_LOADING',

    GET_CUSTOMER_DETAIL_SUCCESS: 'GET_CUSTOMER_DETAIL_SUCCESS',
    GET_CUSTOMER_DETAIL_ERROR: 'GET_CUSTOMER_DETAIL_ERROR',
    GET_CUSTOMER_DETAIL_LOADING: 'GET_CUSTOMER_DETAIL_LOADING',

}