import Actions from "../../values/Actions";

const STATES_INITAL_STATE = {
    loading: false,
    error: '',
    success: false,
    response: {},
};
const PRODUCTS_INITAL_STATE = {
    product_loading: false,
    product_error: '',
    product_success: false,
    product_response: {},
};

const DETAIL_INITAL_STATE = {
    detail_loading: false,
    detail_error: '',
    detail_success: false,
    detail_response: {},
};
const INV_INITAL_STATE = {
    inv_loading: false,
    inv_error: '',
    inv_success: false,
    inv_response: {},
};
const OEM_INITAL_STATE = {
    oem_loading: false,
    oem_error: '',
    oem_success: false,
    oem_response: {},
};

const PRODUCT_IMAGE_BY_COLOR_INITIAL = {
    get_product_image_loading: false,
    get_product_image_success: false,
    get_product_image_msg: {},
    get_product_images_data: {}

}

const LAT_LONG_INITIAL_STATE = {
    get_map_data_loading: false,
    get_map_data_success: false,
    get_map_data_msg: {},
    get_map_data: []
}
const INITAL_STATE = {
    ...STATES_INITAL_STATE,
    ...PRODUCTS_INITAL_STATE,
    ...DETAIL_INITAL_STATE,
    ...INV_INITAL_STATE,
    ...OEM_INITAL_STATE,
    ...PRODUCT_IMAGE_BY_COLOR_INITIAL,
    ...LAT_LONG_INITIAL_STATE
};
export default (state = INITAL_STATE, action) => {
    switch (action.type) {

        case Actions.GET_STATE_SUCCESS:
            return { ...state, ...STATES_INITAL_STATE, success: true, response: action.payload }
        case Actions.GET_STATE_PROGRESS:
            return { ...state, loading: true }
        case Actions.GET_STATE_ERROR:
            return { ...state, ...STATES_INITAL_STATE, error: action.payload }

        case Actions.GET_PRODUCTS_SUCCESS:
            return { ...state, ...PRODUCTS_INITAL_STATE, product_success: true, product_response: action.payload }
        case Actions.GET_PRODUCTS_PROGRESS:
            return { ...state, product_loading: true }
        case Actions.GET_PRODUCTS_ERROR:
            return { ...state, ...PRODUCTS_INITAL_STATE, product_error: action.payload }

        case Actions.GET_PRODUCT_DETAIL_SUCCESS:
            return { ...state, ...DETAIL_INITAL_STATE, detail_success: true, detail_response: action.payload }
        case Actions.GET_PRODUCT_DETAIL_PROGRESS:
            return { ...state, detail_loading: true }
        case Actions.GET_PRODUCT_DETAIL_ERROR:
            return { ...state, ...DETAIL_INITAL_STATE, detail_error: action.payload }

        case Actions.GET_PRODUCT_INV_SUCCESS:
            return { ...state, ...INV_INITAL_STATE, inv_success: true, inv_response: action.payload }
        case Actions.GET_PRODUCT_INV_PROGRESS:
            return { ...state, inv_loading: true }
        case Actions.GET_PRODUCT_INV_ERROR:
            return { ...state, ...INV_INITAL_STATE, inv_error: action.payload }

        case Actions.GET_OEM_LIST_SUCCESS:
            return { ...state, ...OEM_INITAL_STATE, oem_success: true, oem_response: action.payload }
        case Actions.GET_OEM_LIST_PROGRESS:
            return { ...state, oem_loading: true, oem_success: false, oem_response: {} }
        case Actions.GET_OEM_LIST_ERROR:
            return { ...state, ...OEM_INITAL_STATE, oem_error: action.payload }

        case Actions.GET_PRODUCT_IMAGES_BY_COLOR_PROGRESS:
            return { ...state, ...PRODUCT_IMAGE_BY_COLOR_INITIAL, get_product_image_loading: true, get_product_images_data: {}, get_product_image_success: false }
        case Actions.GET_PRODUCT_IMAGES_BY_COLOR_SUCCESS:
            return { ...state, ...PRODUCT_IMAGE_BY_COLOR_INITIAL, get_product_image_loading: false, get_product_image_success: true, get_product_images_data: action.payload }
        case Actions.GET_PRODUCT_IMAGES_BY_COLOR_ERROR:
            return { ...state, ...PRODUCT_IMAGE_BY_COLOR_INITIAL, get_product_image_loading: false, get_product_image_success: true, get_product_image_msg: action.payload }

        case Actions.GET_LAT_LONG_PROGRESS:
            return { ...state, ...LAT_LONG_INITIAL_STATE, get_map_data_loading: true }
        case Actions.GET_LAT_LONG_SUCCESS:
            return { ...state, ...LAT_LONG_INITIAL_STATE, get_map_data_loading: false, get_map_data_success: true, get_map_data: action.payload }
        case Actions.GET_LAT_LONG_ERROR:
            return { ...state, ...LAT_LONG_INITIAL_STATE, get_map_data_loading: false, get_map_data_success: false, get_map_data_msg: action.payload }

        default:
            return state
    }
}