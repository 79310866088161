import Actions from "../../values/Actions";
import AppConstants from "../../values/AppConstants";

const OTP_INITIAL_STATE = {
    loading: false,
    error: '',
    success: false,
    response: {},
}

const OTP_VERIFY_INITIAL_STATE = {
    verify_loading: false,
    verify_error: '',
    verify_success: false,
    verify_response: {},
}

const REGISTER_INITIAL_STATE = {
    register_loading: false,
    register_error: '',
    register_success: false,
    register_response: {},
    token: null
}

const NEW_PASS_STATE = {
    new_pass_loading: false,
    new_pass_error: '',
    new_pass_success: false,
}

const LOGIN_INITIAL_STATE = {
    login_loading: false,
    login_error: '',
    login_success: false,
    login_response: {},
    token: null
}

const CUSTOMER_DETAIL = {
    customer_loading: false,
    customer_error: false,
    customer_success: false,
    customer_response: {}
}



const INITIAL_STATE = {
    ...OTP_INITIAL_STATE,
    ...OTP_VERIFY_INITIAL_STATE,
    ...REGISTER_INITIAL_STATE,
    ...LOGIN_INITIAL_STATE,
    ...NEW_PASS_STATE,
    ...CUSTOMER_DETAIL
}


export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case Actions.GET_OTP_PROGRESS:
            return {
                ...state,
                loading: true,

            }

        case Actions.GET_OTP_SUCCESS:
            return {
                ...state,
                ...OTP_INITIAL_STATE,
                success: true,
                response: payload
            }
        case Actions.GET_OTP_ERROR:
            return {
                ...state,
                ...OTP_INITIAL_STATE,
                success: false,
                error: payload
            }
        case Actions.OTP_RESET:
            return {
                ...state,
                ...OTP_INITIAL_STATE,
                success: false,
                error: '',
                loading: false
            }
        case Actions.GET_OTP_VERIFY_PROGRESS:
            return {
                ...state,
                verify_loading: true,

            }

        case Actions.GET_OTP_VERIFY_SUCCESS:
            return {
                ...state,
                ...OTP_VERIFY_INITIAL_STATE,
                verify_success: true,
                verify_response: payload
            }
        case Actions.GET_OTP_VERIFY_ERROR:
            return {
                ...state,
                ...OTP_VERIFY_INITIAL_STATE,
                verify_success: false,
                verify_error: payload
            }
        case Actions.GET_OTP_VERIFY_RESET:
            return {
                ...state,
                ...OTP_VERIFY_INITIAL_STATE,
                verify_success: false,
                verify_error: '',
                verify_loading: false
            }


        case Actions.NEW_PASS_VERIFY_RESET:
            return {
                ...state,
                new_pass_loading: false,
                new_pass_success: false,

            }
        case Actions.NEW_PASS_VERIFY_PROGRESS:
            return {
                ...state,
                new_pass_loading: true,

            }

        case Actions.NEW_PASS_VERIFY_SUCCESS:
            return {
                ...state,
                ...NEW_PASS_STATE,
                new_pass_success: true,
            }
        case Actions.NEW_PASS_VERIFY_ERROR:
            return {
                ...state,
                ...NEW_PASS_STATE,
                new_pass_success: false

            }
        case Actions.LOGIN_PROGRESS:
        case Actions.GET_REGISTER_PROGRESS:
            return {
                ...state,
                login_loading: true,

            }

        case Actions.LOGIN_SUCCESS:
        case Actions.GET_REGISTER_SUCCESS:
            AppConstants.token = payload.token
            return {
                ...state,
                ...LOGIN_INITIAL_STATE,
                login_success: true,
                login_response: payload,
                token: payload.token
            }
        case Actions.LOGIN_ERROR:
        case Actions.GET_REGISTER_ERROR:
            return {
                ...state,
                ...LOGIN_INITIAL_STATE,
                login_success: false,
                login_error: payload,
                token: null

            }
        case Actions.LOGOUT:
            localStorage.clear();
            return {
                ...state,
                ...LOGIN_INITIAL_STATE,
                login_success: false,
                login_error: payload,
                token: null,
                ...CUSTOMER_DETAIL,
                customer_loading: false,
                customer_response: {},
                customer_success: false

            }

        case Actions.GET_CUSTOMER_DETAIL_LOADING:
            return {
                ...state,
                ...CUSTOMER_DETAIL,
                customer_loading: true,
                customer_response: {},
                customer_success: false
            }

        case Actions.GET_CUSTOMER_DETAIL_SUCCESS:
            return {
                ...state,
                ...CUSTOMER_DETAIL,
                customer_loading: false,
                customer_response: payload,
                customer_success: true
            }

        case Actions.GET_CUSTOMER_DETAIL_ERROR:
            return {
                ...state,
                ...CUSTOMER_DETAIL,
                customer_loading: false,
                customer_error: true,
                customer_response: {},
                customer_success: false,
            }



        default:
            return state

    }


}