import { HTTPGetRequest, HTTPRequest } from "../../helper"
import Actions from "../../values/Actions"
import ServerUrls from "../../values/ServerUrls"
import store from '../store';
import { setAlert } from "./AlertActions";

export const getOrdersList = () => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_ORDER_PROGRESS })
        HTTPGetRequest(ServerUrls.GET_ORDERS, {
            authToken: `${store.getState().authReducer.token}`
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_ORDER_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_ORDER_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_ORDER_ERROR, payload: error.message })
        })
    }

}

export const getOrdersDownloadInvoice = (id) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_DOWNLOAD_INVOICE_LOADING })
        HTTPGetRequest(`${ServerUrls.GET_ORDERS_INVOICE}orderid=${id}`, {
            authToken: `${store.getState().authReducer.token}`
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_DOWNLOAD_INVOICE_SUCCESS, payload: res.response })
                window.open(res.response.receipt_url)
            } else {
                dispatch({ type: Actions.GET_DOWNLOAD_INVOICE_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_DOWNLOAD_INVOICE_ERROR, payload: error.message })
        })
    }

}

export const addTestDrive = ({ vehicle, showroom }) => {
    return (dispatch) => {
        dispatch({ type: Actions.ADD_TEST_DRIVE_PROGRESS })
        HTTPRequest(ServerUrls.TEST_DRIVE, {
            authToken: store.getState().authReducer.token
        }, {
            "vehicleId": vehicle,
            "showroomId": showroom
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.ADD_TEST_DRIVE_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch({ type: Actions.ADD_TEST_DRIVE_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.ADD_TEST_DRIVE_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }

}

export const getTestDrive = () => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_TEST_DRIVE_PROGRESS })
        HTTPGetRequest(ServerUrls.TEST_DRIVE, {
            authToken: store.getState().authReducer.token
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_TEST_DRIVE_SUCCESS, payload: res.response })
            } else {
                dispatch({ type: Actions.GET_TEST_DRIVE_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_TEST_DRIVE_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }

}