import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import ConfirmationLayout from '../confirmation/ConfirmationLayout';
import store from '../../redux/store';

const ConfirmationRoutes = (props) => {

    const { component: Component, ...rest } = props;

    return (
        <Route {...rest} render={props => (
            store.getState().authReducer.token ?
            <>
                <ConfirmationLayout>
                    <Component {...props} />
                </ConfirmationLayout>
            </>
            :
            <Redirect to ='/'/>
        )} />
    )
}

export default ConfirmationRoutes
