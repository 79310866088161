import React, { useEffect } from 'react'
import LoginImage from '../assets/images/login_image.svg';
import Button from '@material-ui/core/Button';
import Input from '../../storybook/Input/Input';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStateList } from '../../redux/actions';
import Select from '../../storybook/Select/Select';
import { getOtp, otpVerify, customerRegister } from '../../redux/actions/AuthActions';
import { useHistory } from 'react-router';
import AppContants from '../../values/AppConstants';
import Grid from '@material-ui/core/Grid';

const Register = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const stateList = useSelector(state => state.productReducer.response)
    const otpSuccess = useSelector(state => state.authReducer.success)
    const otpVerifySuccess = useSelector(state => state.authReducer.verify_success)
    const token = useSelector(state => state.authReducer.token)
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        otp: '',
        email: '',
        password: '',
        aadhar: '213456544',
        street: '',
        pincode: '',
        city: '',
        state: '',
        stateId: ''
    })

    const { name, phone, otp, email, password, aadhar, street, pincode, city } = formData;




    const onFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleStateChange = (state) => {
        setFormData({ ...formData, state: state, stateId: state.id })
    }

    const handleRegiser = e => {
        e.preventDefault()
        dispatch(customerRegister(formData))
    }

    const sendCode = (type) => {
        dispatch(getOtp({ phone: phone, type: type, enteredOtp: otp }))
    }

    const verifyCode = () => {
        dispatch(otpVerify({ phone: phone, type: 'verify', enteredOtp: otp }))
    }

    useEffect(() => {
        dispatch(getStateList())
        setFormData({ ...formData, state: stateList?.states[0], stateId: stateList?.states[0].id })
    }, [dispatch])

    useEffect(() => {

        if (token) {
            AppContants.route ? history.push(AppContants.route) : history.push('/')
        }
    }, [token])


    return (
        <Grid container>
            <Grid item xs={12} md={8}>
                <div className="reg_form_img_wrapper" className='form_view'>
                    <div className="top_img_wrapper">
                        <span className="top_heading_wrapper">
                            <h1>Electric Vehicle Digital Platform</h1>
                            <h3 className="xs_heading">A completely paperless, cashless and system driven e-market place for buying and selling of EV</h3>
                        </span>

                        <img className="reg_form_img" src={LoginImage} alt="signup" />
                    </div>

                </div>
            </Grid>



            <Grid item xs={12} md={4}>
                <div className="sign_form_wrapper">
                    <h1 className='m_t_40'>Sign Up</h1>
                    <h4 style={{ color: 'rgba(40, 44, 64, 0.6)' }}>Welcome, we missed you!</h4>
                    <form onSubmit={handleRegiser}>

                        <div className='m_t_40'>
                            <h5>Full Name</h5>
                            <Input placeholder="Enter Name"
                                value={name}
                                name="name"
                                onChange={(e) => onFormChange(e)} />
                        </div>
                        <div className="flex_between m_t_10">
                            <div style={{ flexGrow: 1 }}>
                                <h5>Phone Number</h5>
                                <Input placeholder="Enter Phone Number" value={phone}
                                    name="phone"
                                    type="number"

                                    onChange={(e) => onFormChange(e)}
                                />
                            </div>
                            {
                                otpSuccess ?
                                    <Button color="secondary" className="input_button_send"
                                        onClick={() => sendCode('resend')}
                                    >Resend Code</Button>
                                    :
                                    <Button color="secondary" className="input_button_send"
                                        onClick={() => sendCode('generate')}
                                    >Send Code</Button>
                            }

                        </div>
                        {
                            otpSuccess && <div className="flex_between m_t_10">
                                <div style={{ flexGrow: 1 }}>
                                    <h5>Enter OTP</h5>
                                    <Input placeholder="Enter OTP" value={otp}
                                        name="otp"
                                        onChange={(e) => onFormChange(e)} />
                                </div>
                                <Button variant="contained"
                                    className="input_button_verify "
                                    onClick={verifyCode}
                                    color="primary">Verify</Button>
                            </div>
                        }

                        <div className='m_t_10'>
                            <h5>Email</h5>
                            <Input placeholder="Enter Email" value={email}
                                name="email"
                                type="email"
                                onChange={(e) => onFormChange(e)} />
                        </div>
                        <div className='m_t_10'>
                            <h5>Password</h5>
                            <Input placeholder="Enter Password" value={password}
                                name="password"
                                type="password"
                                onChange={(e) => onFormChange(e)} />
                        </div>
                        {/* <div>
                    <h5>Aadhar Number</h5>
                    <Input placeholder="Enter Aadhar Number" value={aadhar}
                        name="aadhar"
                        onChange={(e) => onFormChange(e)} />
                </div> */}
                        <div className='m_t_10'>
                            <h5>Street</h5>
                            <Input placeholder="Enter Street" value={street}
                                name="street"
                                onChange={(e) => onFormChange(e)} />
                        </div>
                        <div className='m_t_10'>
                            <h5>Pin Code</h5>
                            <Input placeholder="Enter Pin Code" value={pincode}
                                name="pincode"
                                type="number"

                                onChange={(e) => onFormChange(e)} />
                        </div>
                        <div className=" m_t_10">

                            <h5>City</h5>
                            <Input placeholder="Enter City" value={city}
                                name="city"
                                onChange={(e) => onFormChange(e)} />

                        </div>
                        <div className=" m_t_10">

                            <h5>State</h5>
                            <Select data={stateList?.states} onChange={handleStateChange} placeholder={formData.state.name} optionStyle={{ position: 'relative' }} style={{ width: 'inherit', marginTop: 5 }} />

                        </div>

                        <div className='m_t_20 m_b_40'>
                            <Button color="secondary" style={{ color: '#fff' }} className="p-10"
                                disabled={otpVerifySuccess ? false : true}
                                type="submit"
                                fullWidth variant="contained">Register</Button>
                        </div>
                    </form>


                </div>

            </Grid>
        </Grid>
    )
}

export default Register
