import React from 'react'
import Header from '../catalogue/common/Header'
import Footer from '../catalogue/common/Footer'


const ConfirmationLayout = (props) => {
    return (
        <>
          <Header/>
          {props.children} 
          <Footer/> 
        </>
    )
}

export default ConfirmationLayout
