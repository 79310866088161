import React from 'react';
import { Route } from 'react-router-dom'
import CatalogueLayout from '../catalogue/CatalogueLayout';

const CatalogueRoutes = (props) => {

    const { component: Component, ...rest } = props;
    return (
        <Route {...rest} render={props => (
           
            <>
                <CatalogueLayout>
                    <Component {...props} />
                </CatalogueLayout>
            </>
        )} />
    )
}

export default CatalogueRoutes
