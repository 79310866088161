import Actions from "../../values/Actions";

const ADDRESS_INITIAL_STATE = {
    address_loading: true,
    address_success: false,
    address_error: '',
    address_response: {}
}
const ADD_ADDRESS_INITIAL_STATE = {
    add_address_loading: true,
    add_address_success: false,
    add_address_error: '',
    add_address_response: {}
}
const EDIT_ADDRESS_INITIAL_STATE = {
    edit_address_loading: true,
    edit_address_success: false,
    edit_address_error: '',
    edit_address_response: {}
}

const DELETE_ADDRESS_INITIAL_STATE = {
    delete_address_loading: true,
    delete_address_success: false,
    delete_address_error: '',
    delete_address_response: {}
}

const LOAN_INITIAL_STATE = {
    loan_loading: true,
    loan_success: false,
    loan_error: false,
    loan_response: {}
}
const PATCH_LOAN_INITIAL_STATE = {
    Patch_loan_loading: true,
    Patch_loan_success: false,
    Patch_loan_error: false,
    Patch_loan_response: {}
}
const GET_LOAN_INITIAL_STATE = {
    get_loan_loading: true,
    get_loan_success: false,
    get_loan_error: false,
    get_loan_response: {}
}
const GET_APPLICATION_INITIAL_STATE = {
    get_application_loading: true,
    get_application_success: false,
    get_application_error: false,
    get_application_response: {}
}

const GET_APPLICATION_LIST_INITIAL_STATE = {
    get_application_list_loading: true,
    get_application_list_success: false,
    get_application_list_error: false,
    get_application_list_response: {}
}

const INITIAL_STATE = {
    ...ADDRESS_INITIAL_STATE,
    ...ADD_ADDRESS_INITIAL_STATE,
    ...EDIT_ADDRESS_INITIAL_STATE,
    ...DELETE_ADDRESS_INITIAL_STATE,
    ...LOAN_INITIAL_STATE,
    ...GET_LOAN_INITIAL_STATE,
    ...PATCH_LOAN_INITIAL_STATE,
    ...GET_APPLICATION_INITIAL_STATE,
    ...GET_APPLICATION_LIST_INITIAL_STATE
}

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Actions.ADDRESS_PROGRESS:
            return { ...state, address_loading: true, address_response: {} }
        case Actions.ADDRESS_SUCCESS:
            return { ...state, address_loading: false, address_success: true, address_error: "", address_response: payload }
        case Actions.ADDRESS_ERROR:
            return { ...state, address_loading: false, address_success: false, address_error: payload }
        case Actions.ADD_ADDRESS_PROGRESS:
            return { ...state, add_address_loading: true, add_address_success: false, add_address_response: {} }
        case Actions.ADD_ADDRESS_SUCCESS:
            return { ...state, add_address_loading: false, add_address_success: true, add_address_error: "", add_address_response: payload }
        case Actions.ADD_ADDRESS_ERROR:
            return { ...state, add_address_loading: false, add_address_success: false, add_address_error: payload }
        case Actions.EDIT_ADDRESS_PROGRESS:
            return { ...state, edit_address_loading: true, edit_address_success: false, edit_address_response: {} }
        case Actions.EDIT_ADDRESS_SUCCESS:
            return { ...state, edit_address_loading: false, edit_address_success: true, edit_address_error: "", edit_address_response: payload }
        case Actions.EDIT_ADDRESS_ERROR:
            return { ...state, edit_address_loading: false, edit_address_success: false, edit_address_error: payload }
        case Actions.DELETE_ADDRESS_PROGRESS:
            return { ...state, delete_address_loading: true, delete_address_success: false, delete_address_response: {} }
        case Actions.DELETE_ADDRESS_SUCCESS:
            return { ...state, delete_address_loading: false, delete_address_success: true, delete_address_error: "", delete_address_response: payload }
        case Actions.DELETE_ADDRESS_ERROR:
            return { ...state, delete_address_loading: false, delete_address_success: false, delete_address_error: payload }

        case Actions.LOAN_LOADING:
            return { ...state, loan_loading: true, get_loan_success: false, loan_success: false, loan_response: {} }
        case Actions.LOAN_SUCCESS:
            return { ...state, loan_loading: false, loan_success: true, loan_error: "", loan_response: payload }
        case Actions.LOAN_ERROR:
            return { ...state, loan_loading: false, loan_success: false, loan_error: payload }
        case Actions.PATCH_LOAN_LOADING:
            return { ...state, Patch_loan_loading: true, get_loan_success: false, Patch_loan_success: false, Patch_loan_response: {} }
        case Actions.PATCH_LOAN_SUCCESS:
            return { ...state, Patch_loan_loading: false, Patch_loan_success: true, Patch_loan_error: "", Patch_loan_response: payload }
        case Actions.PATCH_LOAN_ERROR:
            return { ...state, Patch_loan_loading: false, Patch_loan_success: false, Patch_loan_error: payload }
        case Actions.GET_LOAN_LOADING:
            return { ...state, get_loan_loading: true, get_loan_success: false, get_loan_response: {} }
        case Actions.GET_LOAN_SUCCESS:
            return { ...state, get_loan_loading: false, loan_loading: false, Patch_loan_loading: false, get_loan_success: true, get_loan_error: "", get_loan_response: payload.data }
        case Actions.GET_LOAN_ERROR:
            return { ...state, get_loan_loading: false, get_loan_success: false, get_loan_error: payload, loan_success: false }
        case Actions.GET_APPLICATION_LOADING:
            return { ...state, get_application_loading: true, get_application_success: false, get_application_response: [] }
        case Actions.GET_APPLICATION_SUCCESS:
            return { ...state, get_application_loading: false, get_application_success: true, get_application_error: "", get_application_response: payload }
        case Actions.GET_APPLICATION_ERROR:
            return { ...state, get_application_loading: false, get_application_success: false, get_application_error: payload }
        case Actions.GET_APPLICATION_LIST_LOADING:
            return { ...state, get_application_list_loading: true, loan_loading: false, Patch_loan_loading: false, get_loan_success: false, get_application_list_success: false, get_application_list_response: [] }
        case Actions.GET_APPLICATION_LIST_SUCCESS:
            return { ...state, get_application_list_loading: false, get_application_list_success: true, get_application_list_error: "", get_application_list_response: payload }
        case Actions.GET_APPLICATION_LIST_ERROR:
            return { ...state, get_application_list_loading: false, get_application_list_success: false, get_application_list_error: payload }
        default:
            return state
    }
}