import { HTTPRequest } from "../../helper"
import Actions from "../../values/Actions"
import ServerUrls from "../../values/ServerUrls"
import store from '../store';

export const resetPayment = () => ({
    type: Actions.RAZORPAY_RESET
})
export const resetRemainingPayment = () => ({
    type: Actions.RAZORPAY_REMAINING_RESET
})

export const getRazorPay = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.RAZORPAY_PROGRESS })
        HTTPRequest(ServerUrls.PAYMENT_URL, {
            authToken: store.getState().authReducer.token
        },
            {
                "amount": parseInt(data.amount),
                "addressId": parseInt(data.addressId),
                "paymentId": data.payment_id,
                "invId": parseInt(data.invId),
                "placeOrderFlag": !data.booking
            }).then(res => {
                if (res.status === 200) {
                    dispatch({ type: Actions.RAZORPAY_SUCCESS, payload: res.response })
                } else {
                    dispatch({ type: Actions.RAZORPAY_ERROR, payload: res.response.message })
                }
            }).catch((error) => {
                dispatch({ type: Actions.RAZORPAY_ERROR, payload: error.message })
            })
    }

}

export const remainingPayment = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.RAZORPAY_REMAINING_PROGRESS })
        HTTPRequest(ServerUrls.REMAINING_PAYMENT, {
            authToken: store.getState().authReducer.token
        },
            {
                "amount": parseInt(data.amount),
                "orderId": parseInt(data.order_id),
                "paymentId": data.payment_id
            }).then(res => {
                if (res.status === 200) {
                    dispatch({ type: Actions.RAZORPAY_REMAINING_SUCCESS, payload: res.response })
                } else {
                    dispatch({ type: Actions.RAZORPAY_REMAINING_ERROR, payload: res.response.message })
                }
            }).catch((error) => {
                dispatch({ type: Actions.RAZORPAY_REMAINING_ERROR, payload: error.message })
            })
    }

}