import { combineReducers } from "redux";
import AuthReducers from "./AuthReducers";
import ConfirmationReducer from "./ConfirmationReducer";
import OrdersReducer from "./OrdersReducer";
import PaymentReducer from './PaymentReducer';
import ProductReducer from "./ProductReducer";
import AlertReducers from './AlertReducers';
import ComplaintReducers from './ComplaintReducers';
import FaqReducers from "./FaqReducers";

export default combineReducers({
    productReducer: ProductReducer,
    authReducer: AuthReducers,
    confirmationReducer: ConfirmationReducer,
    paymentReducer: PaymentReducer,
    orderReducer: OrdersReducer,
    alertReducers: AlertReducers,
    complaintReducers: ComplaintReducers,
    faqReducer: FaqReducers
})