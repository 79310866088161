import { HTTPGetRequest, HTTPPutRequest, HTTPRequest } from "../../helper"
import Actions from "../../values/Actions"
import ServerUrls from "../../values/ServerUrls"
import { setAlert } from "./AlertActions"
import store from '../store';



export const resetOTP = () => ({
    type: Actions.OTP_RESET
})

export const resetOTPVerify = () => ({
    type: Actions.GET_OTP_VERIFY_RESET
})

export const resetNewPassword = () => ({
    type: Actions.NEW_PASS_VERIFY_RESET
})


export const getOtp = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_OTP_PROGRESS })
        HTTPGetRequest(`${ServerUrls.GET_OTP}?phoneNumber=${data.phone}&countryCode=${91}&OtpType=${data.type}&enteredOTP=${data.enteredOtp}`, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_OTP_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch({ type: Actions.GET_OTP_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_OTP_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }

}

export const otpVerify = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_OTP_VERIFY_PROGRESS })
        HTTPGetRequest(`${ServerUrls.GET_OTP}?phoneNumber=${data.phone}&countryCode=${91}&OtpType=${data.type}&enteredOTP=${data.enteredOtp}`, {}).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_OTP_VERIFY_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch({ type: Actions.GET_OTP_VERIFY_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_OTP_VERIFY_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }

}

export const customerRegister = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_REGISTER_PROGRESS })
        HTTPRequest(ServerUrls.AUTH_OTP, {}, {
            "name": data.name,
            "countryCode": 91,
            "phone": data.phone,
            "email": data.email,
            "street": data.street,
            "pincode": data.pincode,
            "city": data.city,
            "stateId": data.stateId,
            "password": data.password
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_REGISTER_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch({ type: Actions.GET_REGISTER_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_REGISTER_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }

}

export const customerLogin = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.LOGIN_PROGRESS })
        HTTPGetRequest(ServerUrls.AUTH_OTP, {
            Authorization: "Basic " + window.btoa(unescape(encodeURIComponent(data.email + ":" + data.password)))
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.LOGIN_SUCCESS, payload: res.response })
                dispatch(setAlert('success', 'Login Successfully!!'))
            } else {
                dispatch({ type: Actions.LOGIN_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.LOGIN_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }

}


export const customerDetail = () => {
    return (dispatch) => {
        dispatch({ type: Actions.GET_CUSTOMER_DETAIL_LOADING })
        HTTPGetRequest(`${ServerUrls.CUSTOMER_PROFILE}?userId=${store.getState().authReducer.login_response.id}`, {
            authToken: store.getState().authReducer.token
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.GET_CUSTOMER_DETAIL_SUCCESS, payload: res.response })

            } else {
                dispatch({ type: Actions.GET_CUSTOMER_DETAIL_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.GET_CUSTOMER_DETAIL_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }

}



export const newPassword = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.NEW_PASS_VERIFY_PROGRESS })
        HTTPPutRequest(ServerUrls.AUTH_OTP, {}, {
            "phoneNumber": data.phone,
            "newPassword": data.password
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.NEW_PASS_VERIFY_SUCCESS, payload: res.response })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch({ type: Actions.NEW_PASS_VERIFY_ERROR, payload: res.response.message })
                dispatch(setAlert('error', res.response.message))
            }
        }).catch((error) => {
            dispatch({ type: Actions.NEW_PASS_VERIFY_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }

}

export const customerLogout = () => {
    return (dispatch) => {
        dispatch({ type: Actions.LOGOUT })
        dispatch(setAlert('success', 'Logout Successfully!!'))
    }

}