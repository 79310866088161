export function ucfirst(string) {

	return string? string.split(',').map(con => con.charAt(0).toUpperCase() + con.slice(1)).join():string;
}

export function log(TAG, ...VAL) {
	//   return console.log(TAG, VAL.join())
	 return null
}

export function saveItem(key,value){
	localStorage.setItem(key,value)
}

export function getItem(key){
	return localStorage.getItem(key)!=null ?localStorage.getItem(key):''
}

export function saveEncryptedItem(key,value){
	let encodedString = btoa(value)
	localStorage.setItem(key,encodedString)
}

export function getDecryptedItem(key){
	
	let decodedString = atob(localStorage.getItem(key))
	return localStorage.getItem(key)?decodedString:null
}

export function removeItem(key){
	localStorage.removeItem(key)
}

export function formValidation(values){
	console.log(values)
// if(values.toString().includes('required')){
// 	console.log('required')
// }
}

export const formatToINR = (amount = 0, locale = 'en-IN', currency = 'INR') => {
	return new Intl.NumberFormat(locale, {
	  minimumFractionDigits: 0,
	  style: 'currency',
	  currency: currency,
	}).format(amount);
  };
  
  export const INRtoNumber = (str) => {
	const numberString = str.replace(/[₹,]+/g, '')?.trim();
	if (numberString && numberString.length > 0) {
	  return parseFloat(numberString);
	} else {
	  return 0;
	}
  };
  
  export const formatAmount = (amount = 0) => {
	return new Intl.NumberFormat('en-IN', {
	  maximumSignificantDigits: 3,
	  style: 'currency',
	  currency: 'INR',
	}).format(amount);
  };
  export const formatCash = (amount = 0) => {
	if (amount < 1e3) {
	  return amount;
	}
	if (amount >= 1e3 && amount < 1e5) {
	  return +(amount / 1e3).toFixed(2) + ' K';
	}
	if (amount >= 1e5 && amount <= 1e7) {
	  return +(amount / 1e5).toFixed(2) + ' L';
	}
	if (amount > 1e7) {
	  return +(amount / 1e7).toFixed(2) + ' C';
	}
  };