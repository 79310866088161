import React, { useState, useEffect } from 'react';
import Declaration from './Declaration';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import { useDispatch, useSelector } from 'react-redux';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Box from '@material-ui/core/Box';
import LoanForm from './LoanForm';
import { evLoanForm, getEvLoanForm, UpdateEvLoanForm } from '../../../redux/actions/Confirmation';
import EmployeeDetails from './EmployeeDetails';
import Instruction from './Instruction';
import Grid from '@material-ui/core/Grid';
import { useLocation, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { customerDetail } from '../../../redux/actions';

const Loan = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const postSuccess = useSelector(state => state.confirmationReducer.loan_success)
    const postLoading = useSelector(state => state.confirmationReducer.loan_loading)
    const patchLoanLoading = useSelector(state => state.confirmationReducer.Patch_loan_loading)
    const patchLoanSuccess = useSelector(state => state.confirmationReducer.Patch_loan_success)
    const postApplicationNum = useSelector(state => state.confirmationReducer.loan_response?.application_num)
    const initialFormData = useSelector(state => state.confirmationReducer.get_loan_response)
    const initialFormDataSuccess = useSelector(state => state.confirmationReducer.get_loan_success)
    const isLoanCustomer = useSelector(state => state.authReducer.customer_response.ev_loan_flag);

    const [activeStep, setActiveStep] = React.useState(0);

    const steps = [
        'Instructions', 'Personal Details', 'Employer Details', 'Declaration'
    ]
    const [formData, setFormData] = useState({
        name_of_emp: '',
        father_name: '',
        present_address: '',
        permanent_address: '',
        name_of_org: '',
        place_of_working: '',
        phone: '',
        empNature: 'Permanent',
        parent_org: '',
        designation: '',
        emp_id: '',
        dob: '',
        date_of_joining: '',
        date_of_retirement: '',
        per_month_salary: '',
        aadhar_number: '',
        pan_number: '',
        vehicle_model: location?.state?.vehicleName,
        vehicleId: location?.state?.vehicleId,
        vehicle_color_id: location?.state?.selectedColorId,
        vehicle_color: location?.state?.selectedColorName,
        emi_tenure: '12',
        emi_per_month: '',
        pan_card: '',
        aadhar_card: '',
        ddoName: '',
        ddoDesignation: '',
        ddoEmail: '',
        ddoPhone: '',
        email: '',
        aadhar_card_front: '',
        aadhar_card_front_name: '',
        aadhar_card_back: '',
        aadhar_card_back_name: '',
        pan_card: '',
        pan_card_name: '',
        passport_photo: '',
        passport_photo_name: '',
        employee_card_front: '',
        employee_card_front_name: '',
        employee_card_back: '',
        employee_card_back_name: '',
        declaration_form: '',
        declaration_form_name: '',
        cancelled_cheque: '',
        cancelled_cheque_name: '',
        status: 0
    });

    const finalSubmit = () => {
        let obj = { ...formData, status: 1 }
        obj.application_num = postApplicationNum?.keys?.lenght > 0 ? postApplicationNum : initialFormData?.id;

        dispatch(UpdateEvLoanForm(obj))
    }

    const isFormValid = () => {
        const { name_of_emp, father_name, present_address, permanent_address, phone, dob, pan_number, aadhar_number,
            aadhar_card_front, aadhar_card_back, pan_card, passport_photo, aadhar_card_front_name, aadhar_card_back_name,
            pan_card_name, passport_photo_name } = formData
        return (name_of_emp === '' || father_name === '' || present_address === '' || permanent_address === '' ||
            phone === '' || dob === '' || pan_number === '' || aadhar_number === '' || (aadhar_card_front === (undefined || '') && aadhar_card_front_name === (undefined || '')) ||
            (aadhar_card_back === (undefined || '') && aadhar_card_back_name === (undefined || '')) || (pan_card === (undefined || '') && pan_card_name === (undefined || '')) || (passport_photo === (undefined || '') && passport_photo_name === (undefined || '')))
    }

    const isEmployeeFormValid = () => {
        const { name_of_org, place_of_working, designation, emp_id, per_month_salary, date_of_joining, date_of_retirement,
            ddoDesignation, ddoPhone, ddoEmail, ddoName, emi_per_month, employee_card_front, employee_card_back,
            declaration_form, cancelled_cheque, employee_card_front_name, employee_card_back_name, declaration_form_name,
            cancelled_cheque_name, parent_org, empNature } = formData
        return (name_of_org === '' || place_of_working === '' || designation === '' || emp_id === '' || parent_org === '' || empNature === '' ||
            per_month_salary === '' || date_of_joining === '' || date_of_retirement === '' || ddoDesignation === '' ||
            ddoPhone === '' || ddoEmail === '' || ddoName === '' || emi_per_month === '' || (employee_card_front === (undefined || '') && employee_card_front_name === (undefined || '')) ||
            (employee_card_back === (undefined || '') && employee_card_back_name === (undefined || '')) || (declaration_form === (undefined || '') && declaration_form_name === (undefined || '')) ||
            (cancelled_cheque === (undefined || '') && cancelled_cheque_name === (undefined || '')))
    }
    const renderComponent = (index) => {
        switch (index) {
            case 0:
                return <Instruction />
            case 1:
                return <LoanForm
                    formData={formData}
                    onFormSubmit={(data) => setFormData({ ...formData, [data.name]: data.value })} />
            case 2:
                return <EmployeeDetails
                    formData={formData}
                    onFormSubmit={(data) => setFormData({ ...formData, [data.name]: data.value })} />
            case 3:
                return <Declaration submitTrue={(data) => finalSubmit(data)}
                    formData={formData} />
            default:
                return <Instruction />
        }


    }

    useEffect(() => {
        // dispatch(getEvLoanForm())
        dispatch(customerDetail())
        console.log("vehicle name", location?.state?.vehicleName)
    }, [])

    const dateConvert = (data) => {
        var event = new Date(data)
        let date = JSON.stringify(event)
        date = date.slice(1, 11)
        return date
    }

    useEffect(() => {
        if (initialFormDataSuccess) {
            setFormData({
                ...formData,
                name_of_emp: initialFormData?.name,
                father_name: initialFormData?.fatherName,
                present_address: initialFormData?.presentAdd,
                permanent_address: initialFormData?.permanentAdd,
                name_of_org: initialFormData?.orgName,
                place_of_working: initialFormData?.place,
                phone: initialFormData?.phone,
                empNature: initialFormData?.empNature,
                parent_org: initialFormData?.parentOrg,
                designation: initialFormData?.designation,
                emp_id: initialFormData?.empId,
                dob: initialFormData?.dob && dateConvert(initialFormData?.dob),
                date_of_joining: initialFormData?.joiningDate && dateConvert(initialFormData?.joiningDate),
                date_of_retirement: initialFormData?.retirementDate && dateConvert(initialFormData?.retirementDate),
                per_month_salary: initialFormData?.monthlySalary,
                aadhar_number: initialFormData?.aadharNum,
                pan_number: initialFormData?.panNum,
                vehicleId: initialFormData?.vehicleId,
                vehicle_model: initialFormData?.vehicle_name,
                vehicle_color_id: initialFormData?.colourId,
                vehicle_color: initialFormData?.colur_name,
                emi_tenure: initialFormData?.emiTenure,
                emi_per_month: initialFormData?.monthEmi,
                pan_card: initialFormData?.panNum,
                aadhar_card: initialFormData?.aadharNum,
                ddoName: initialFormData?.ddoName,
                ddoDesignation: initialFormData?.ddoDesignation,
                ddoEmail: initialFormData?.ddoEmail,
                ddoPhone: initialFormData?.ddoPhone,
                email: initialFormData?.email,
                aadhar_card_front_name: initialFormData?.aadhar_filename_front,
                aadhar_card_back_name: initialFormData?.aadhar_filename_back,
                passport_photo_name: initialFormData?.passport_photo_filename,
                employee_card_front_name: initialFormData?.employee_card_front_filename,
                employee_card_back_name: initialFormData?.employee_card_back_filename,
                declaration_form_name: initialFormData?.declaration_form_filename,
                cancelled_cheque_name: initialFormData?.cancelled_cheque_filename,
                pan_card_name: initialFormData?.pan_filename
            })
        }

    }, [initialFormDataSuccess])

    const submitForm = () => {
        if (isLoanCustomer) {
            let obj = { ...formData }
            obj.application_num = postApplicationNum !== undefined ? postApplicationNum : initialFormData?.id;
            dispatch(UpdateEvLoanForm(obj))

        }
        else {

            dispatch(evLoanForm(formData))
        }

        (!isLoanCustomer || isLoanCustomer === undefined) && dispatch(customerDetail())
    }

    const nextButton = (activeStep) => {
        if (activeStep > 0) {
            window.scrollTo(0, 0);
            submitForm()

        }

        setActiveStep(activeStep + 1)
    }

    return (
        <div style={{ marginTop: '5%', paddingBottom: '1%' }}>

            <Box sx={{ width: '100%', fontSize: '10px' }}>
                <Stepper activeStep={activeStep} alternativeLabel style={{ width: '60%', marginLeft: '3%' }}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={() => {
                                    return (
                                        index < activeStep ?
                                            setActiveStep(index) : null)
                                }}>{label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            {renderComponent(activeStep)}

            {(activeStep === 1 || activeStep === 2) ?
                <Grid item sm={12} md={12} style={buttonsRow}>
                    <Button onClick={() => submitForm()} style={submitButtonStyle} >Save</Button>
                    <Button
                        disabled={activeStep === 1 ? isFormValid() : isEmployeeFormValid()}
                        style={(activeStep === 1 ? isFormValid() : isEmployeeFormValid()) ? disableNextButton : nextButtonStyle} onClick={() => {
                            nextButton(activeStep)
                        }}

                    >Next</Button>
                </Grid>

                :
                activeStep !== 3 ?
                    <Grid item sm={12} md={12} style={buttonsRow}>
                        <Button
                            style={nextButtonStyle} onClick={(e) => {
                                nextButton(activeStep)
                            }}
                        >Next</Button>
                    </Grid> : null}
        </div >
    )

}
const disableNextButton = {
    opacity: 0.5,
    width: 140,
    height: 50,
    marginRight: '3%',
    background: '#1E4473',
    borderRadius: 10,
    color: '#FFFFFF'
}
const nextButtonStyle = {
    ...disableNextButton,
    opacity: 1
}
const submitButtonStyle = {
    ...disableNextButton,
    background: '#FF914D',
    opacity: 1
}
const buttonsRow = {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '5%',
    marginLeft: '-7%'
}

export default Loan;