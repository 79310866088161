import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import bajaj_chetak from '../assets/images/bajaj-chetak.svg';
import vehicle_not_found from '../assets/images/vehicle_not_found.svg';
import { getProductDetail, getProductImagesByColor, getProductInv, customerDetail } from '../../redux/actions'
import { useEffect } from 'react';
import { useState } from 'react';
import { formatToINR, getItem, saveEncryptedItem, saveItem, ucfirst } from '../../helper';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router';
import AppConstants from '../../values/AppConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const ProductsDetails = (props) => {
    const isAuth = useSelector(state => state.authReducer.token);
    const detailLoading = useSelector(state => state.productReducer.detail_loading);
    const postSuccess = useSelector(state => state.confirmationReducer.loan_success)
    const isLoan = useSelector(state => state.authReducer.login_response.ev_loan_flag);
    const isLoanCustomer = useSelector(state => state.authReducer.customer_response.ev_loan_flag);
    const [isTrue, setIsTrue] = useState(false);
    // const postApplicationNum = useSelector(state => state.confirmationReducer.loan_response?.application_num)
    const initialFormData = useSelector(state => state.confirmationReducer.get_loan_response)
    const { id, stateId } = useParams()
    const [detail, setDetail] = useState({})
    const [product, setProduct] = useState({})
    const [selectedImage, setSelectedImage] = useState(0)
    const [selectedSpec, setSelectedSpec] = useState(0)
    const [selectedInv, setSelectedInv] = useState(0)
    const [selectedColor, setSelectedColor] = useState(0)
    const [selectedColorName, setSelectedColorName] = useState('')
    const [selectedState, setSelectedState] = useState(null)
    const [selectedDealer, setSelectedDealer] = useState(null)
    const [checkClick, setCheckClick] = useState(false)
    const history = useHistory();
    const location = useLocation();
    const [inStock, setinStock] = useState(true);
    const [portalType, setPortalType] = useState(null)
    const [checkDisclaimer, setCheckDisclaimer] = useState(false)
    const [openDisclaimer, setOpenDisclaimer] = useState(false)


    const handlePlaceOrder = (bookingAmount, book = false) => {
        if (isAuth) {
            history.push({
                pathname: '/checkout/address',
                state: {
                    booking: book,
                    bookingAmount,
                    color: selectedColor && selectedColor
                }
            })
            // history.push(`/checkout/address`)
        } else {
            history.push('/auth/login')
            AppConstants.route = location.pathname;
        }

    }





    const handleSelectedDealer = (value) => {
        setSelectedDealer(value)
        setSelectedInv(value)
    }

    useEffect(() => {

        setSelectedState(localStorage.getItem('x_auth_state_id'))
        let p = props.product_response?.products?.filter(item => item.vehicleId == id)
        // setSelectedColor(p[0].colors[0].colorId)
        let type = localStorage.getItem('x_v_type')
        setSelectedColor(p[0].colors.find(color => color.inStock === true).colorId)
        setSelectedColorName(p[0].colors.find(color => color.inStock === true).colorName)
        saveItem("v_id", id)
        setPortalType(type)
        // setDeliveryDate(id)
        setProduct(p[0])
        props.getProductDetail(id, type)
        let params = `vehicleId=${id}&colorId=${p[0].colors.find(color => color.inStock === true).colorId}&stateId=${stateId}&latitude=${getItem('lat') ? getItem('lat') : '28.4595'}&longitude=${getItem('long') ? getItem('long') : '77.0266'}`
        props.getProductInv(params)
        isAuth && props.customerDetail()
    }, [])

    useEffect(() => {
        setDetail(props.detail_response)
    }, [props.detail_response])
    useEffect(() => {
        if (props.inv_success && !props.inv_loading) {
            saveEncryptedItem("inv_id", props.inv_response?.productInventory[selectedInv]?.inventoryId)
        }

    }, [props.inv_success, selectedInv, props.inv_loading])

    useEffect(() => {
        selectedColor && props.getProductImagesByColor(selectedColor)
        // console.log("selected", selectedColor)
    }, [selectedColor])

    const onColorChange = (colorId, colorName, value) => {
        setinStock(value)
        setSelectedColor(colorId)
        setSelectedColorName(colorName)
        let params = `vehicleId=${id}&colorId=${colorId}&stateId=${stateId}&latitude=${getItem('lat') ? getItem('lat') : '28.4595'}&longitude=${getItem('long') ? getItem('long') : '77.0266'}`
        value && props.getProductInv(params)
    }

    useEffect(() => {

        if (isAuth && isLoan) {
            setIsTrue(false)
        }
        else if (postSuccess) {
            setIsTrue(false)
        }
        else if (initialFormData?.id !== undefined) {
            setIsTrue(false)
        }
        else setIsTrue(true)
    }, [isAuth, isLoan, postSuccess, initialFormData])

    const handleLoan = () => {
        if (isAuth) {
            history.push({
                pathname: '/loan',
                state: {
                    selectedColorName: selectedColorName,
                    selectedColorId: selectedColor,
                    vehicleId: id,
                    vehicleName: product?.vehicleName && ucfirst(product?.vehicleName)
                }
            })
            // history.push(`/checkout/address`)
        } else {
            history.push('/auth/login')
            AppConstants.route = location.pathname;
        }
    }


    return (
        <div>
            <Dialog open={openDisclaimer} onClose={() => setOpenDisclaimer(false)} PaperProps={{
                style: { borderRadius: '20px' }
            }}>
                <DialogContent style={{ marginBottom: '20px' }}>
                    <h1 style={{ display: 'flex', justifyContent: 'center', color: '#000000' }}>Disclaimer</h1>
                    <div style={{ margin: '3% 1%', fontSize: 16, lineHeight: '20px' }}>I certify that, I am a State/Central government/Govt Corporation employee working in the state of Telangana.
                        If my above statement is found to be false, I authorise TSREDCO to take legal action against me.
                        I have read the above statement and I give my acceptance.</div>
                    <span>
                        <Checkbox style={{ padding: '0px 5px 0px 0px', marginTop: '-3px' }} color="primary" checked={checkDisclaimer} onClick={() => {
                            setOpenDisclaimer(false)
                            setCheckDisclaimer(!checkDisclaimer)
                        }}
                        />
                        <span style={{ fontSize: 14 }}>I have read the above statement and I give my acceptance</span>
                    </span>
                </DialogContent>
            </Dialog>

            {/* <div className="catalogue_top_wrapper">
                <h2>Government Marketplace For Electric Vehicles</h2>
            </div> */}
            <div>
                <h2 className="sub_heading">Product Details</h2>
            </div>

            <div className="main_container">
                {
                    detailLoading ?
                        <div className="center">
                            <CircularProgress />
                        </div>
                        :
                        <>
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={8}>
                                    <div>

                                        <Paper variant="outlined" className="center" style={{ width: '90%', borderRadius: 20, marginBottom: 20 }}>

                                            {

                                                props.product_images_loading ? <CircularProgress /> :
                                                    <img src={props.product_images_res?.product_images?.length > 0 ? props.product_images_res?.product_images[selectedImage] : vehicle_not_found} alt="bajaj" className='imgFit product_details_img' />
                                            }

                                        </Paper>
                                    </div>

                                    <div className="product_color_round_wrapper" style={{ width: '90%' }} >
                                        {
                                            props.product_images_res?.product_images?.map((image, index) => (
                                                <div className={`product_image_round ${index == selectedImage && 'product_image__selected'}`} onClick={() => setSelectedImage(index)}>
                                                    <img src={image} alt={`${image}`} height="50" width="50" className='imgFit' />
                                                </div>
                                            ))
                                        }


                                    </div>
                                </Grid>
                                {props.inv_success && props.detail_success && <Grid item sm={12} md={4} style={{ width: '100%' }}>
                                    <div>
                                        <div className="p-b-30">
                                            <h2 style={{ color: '#000' }}>{product?.vehicleName && ucfirst(product?.vehicleName)}</h2>
                                            <span style={{ display: 'flex' }}>
                                                <h4 style={{ paddingRight: 5, color: '#000' }}>Delivery By -</h4>
                                                <h4 style={{ color: '#9BCA3C' }}>{`Within ${detail.deliveryDays} days after order confirmation`}</h4>

                                            </span>
                                            <span style={{ display: 'flex' }}>
                                                <h4 style={{ paddingRight: 5, color: '#000' }}>Delivery Location -</h4>
                                                <h4 style={{ color: '#000' }}>At DealerShip</h4>
                                            </span>

                                        </div>
                                        <div className="p-b-20">

                                            <h4 style={{ color: '#000' }}>TSREDCO Price</h4>
                                            <h2 style={{ color: '#000' }}>   {formatToINR((props.inv_response?.productInventory[selectedInv]?.price + props.inv_response?.productInventory[selectedInv]?.insurance_charges
                                                + props.inv_response?.productInventory[selectedInv]?.registration_charges + props.inv_response?.productInventory[selectedInv]?.other_charges) - (props.inv_response?.productInventory[selectedInv]?.subsidy + props.inv_response?.productInventory[selectedInv]?.cesl_discount))}{portalType === '2' && '*'}</h2>
                                            {portalType === '1' ? <>
                                                {
                                                    <h6 style={{ color: '#929394', paddingBottom: 5 }}>*The prices shown are indicative in nature and are subject to change at the time of final billing</h6>
                                                }
                                                <h5 style={{ color: '#000', paddingBottom: 7 }}>(Including Registration Charges, Insurance, Road Tax And Subsidy)</h5>

                                            </>

                                                :
                                                <>
                                                    {
                                                        <h6 style={{ color: '#929394', paddingBottom: 5 }}>*The prices shown are indicative in nature and are subject to change at the time of final billing</h6>
                                                    }
                                                    <h5 style={{ color: '#000', paddingBottom: 5 }}>(Includes GST + 3 Years AMC + 1 Year Comprehensive Insurance Only)</h5>
                                                    <h5 style={{ color: '#000' }}>Note- The registration charges and road taxes are applicable and to be paid as per actuals at the time of delivery</h5>
                                                </>
                                            }
                                        </div>
                                        {<div className="p-b-30">
                                            <h4 className="p-b-5 dark_heading">Color</h4>
                                            <div className="product_color_round_wrapper">
                                                {product?.colors?.map(color => (
                                                    <span onClick={() => onColorChange(color.colorId, color.colorName, color.inStock)} style={{ backgroundColor: `${color.hexCode}` }} className={`product_color_round pointer ${selectedColor == color.colorId && 'product_color_round__selected'}`} />
                                                ))}
                                            </div>
                                        </div>}

                                        <div className="price_block">
                                            {<Paper variant="outlined">
                                                <>
                                                    <div className="p-10">
                                                        <div className="p-b-5 dark_heading">Price Details</div>
                                                        <div className="flex_between p-b-5">
                                                            <div className=" dark_heading">Base Price</div>
                                                            <div className=" dark_heading">{formatToINR(props.inv_response?.productInventory[selectedInv]?.price)}</div>

                                                        </div>
                                                        {props.inv_response?.productInventory[selectedInv]?.subsidy !== 0 && <div className="flex_between p-b-5">
                                                            <div className=" dark_heading">
                                                                {'FAME Subsidy'}
                                                            </div>
                                                            <div className=" dark_heading">- {formatToINR(props.inv_response?.productInventory[selectedInv]?.subsidy)}</div>
                                                        </div>}

                                                        <div className="flex_between p-b-5">
                                                            <div className=" dark_heading">Total Price</div>
                                                            <div className=" dark_heading">{formatToINR(props.inv_response?.productInventory[selectedInv]?.price - props.inv_response?.productInventory[selectedInv]?.subsidy)}</div>

                                                        </div>
                                                        {
                                                            props.inv_response?.productInventory[selectedInv]?.insurance_charges !== 0 && <div className="flex_between p-b-5">
                                                                <div className=" dark_heading">
                                                                    Comprehensive Insurance
                                                                </div>
                                                                <div className=" dark_heading">{formatToINR(props.inv_response?.productInventory[selectedInv]?.insurance_charges)}</div>
                                                            </div>}
                                                        {props.inv_response?.productInventory[selectedInv]?.registration_charges !== 0 && <div className="flex_between p-b-5">
                                                            <div className=" dark_heading">
                                                                Registration & Insurance Charges
                                                            </div>
                                                            <div className=" dark_heading">{formatToINR(props.inv_response?.productInventory[selectedInv]?.registration_charges)}</div>
                                                        </div>}
                                                        {props.inv_response?.productInventory[selectedInv]?.other_charges !== 0 && <div className="flex_between p-b-5">
                                                            <div className=" dark_heading">
                                                                Others
                                                            </div>
                                                            <div className=" dark_heading">{formatToINR(props.inv_response?.productInventory[selectedInv]?.other_charges)}</div>
                                                        </div>}
                                                        {/* <hr style={{ border: '1px dashed' }} className="m-b-10" /> */}

                                                        {
                                                            props.inv_response?.productInventory[selectedInv]?.cesl_discount !== 0
                                                            &&
                                                            <div className="flex_between p-b-5">
                                                                <div className=" dark_heading">
                                                                    TSREDCO Discount
                                                                </div>
                                                                <div className=" dark_heading">{formatToINR(props.inv_response?.productInventory[selectedInv]?.cesl_discount)}</div>
                                                            </div>

                                                        }

                                                        <hr className="m-b-10" />
                                                        <div className="flex_between p-b-10">
                                                            {
                                                                // portalType === '1' ? <div className=" dark_heading">Total Price</div> :
                                                                //     <div className=" dark_heading">TSREDCO Price</div>

                                                                <div className=" dark_heading">On Road Price</div>

                                                            }

                                                            <div className=" dark_heading">
                                                                {formatToINR((props.inv_response?.productInventory[selectedInv]?.price + props.inv_response?.productInventory[selectedInv]?.insurance_charges
                                                                    + props.inv_response?.productInventory[selectedInv]?.registration_charges + props.inv_response?.productInventory[selectedInv]?.other_charges) - (props.inv_response?.productInventory[selectedInv]?.subsidy + props.inv_response?.productInventory[selectedInv]?.cesl_discount))}
                                                            </div>
                                                        </div>
                                                        {
                                                            inStock &&
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Checkbox checked={checkClick} color="primary" onClick={() => setCheckClick(!checkClick)} />
                                                                <h5 className=" dark_heading">I accept the Terms and Conditions</h5>
                                                            </div>
                                                        }
                                                        {
                                                            inStock ?
                                                                <>
                                                                    <div className="flex_between">

                                                                        {!isLoanCustomer && props.inv_response?.productInventory[selectedInv]?.booking_amount !== 0 && <Button color="secondary" className="m-5" disabled={!(checkClick && checkDisclaimer && selectedDealer !== null) ? true : false} style={{ color: '#fff' }} variant="contained" onClick={() => handleLoan()}>{`Opt for EMI*`}</Button>}
                                                                        {props.inv_response?.productInventory[selectedInv]?.disablePayButtonFlag === false && <Button color="primary" className="m-5" disabled={!(checkClick && selectedDealer !== null) ? true : false} variant="contained" onClick={() => handlePlaceOrder()}>Pay Full Amount</Button>}
                                                                    </div>
                                                                    {
                                                                        selectedDealer === null &&
                                                                        <h5 className="red_heading" style={{ margin: '5px 0px' }}>Please select the dealership from below</h5>
                                                                    }
                                                                    {!isLoanCustomer && <div>
                                                                        *Opt for EMI option is available to only govt employees working in the state of Telangana. If you are a govt employee, please read and accept the <a style={{ textDecoration: 'underline', color: '#000000' }} onClick={() => setOpenDisclaimer(true)}>disclaimer</a> for opting the EMI scheme.
                                                                    </div>}

                                                                </>
                                                                :
                                                                <div className="center" style={{ flexDirection: 'column', cursor: 'not-allowed' }}>
                                                                    <h4 className="red_heading">Sold Out</h4>
                                                                    <h5 style={{ color: 'rgba(222, 54, 60, 1)' }}>This item is currently out of stock
                                                                    </h5>
                                                                </div>


                                                        }
                                                        {/* {
                                                            inStock ?
                                                                <>
                                                                    <div className="flex_between">

                                                                        {props.inv_response?.productInventory[selectedInv]?.booking_amount !== 0 && <Button color="secondary" className="m-5" disabled={!(checkClick && selectedDealer !== null) ? true : false} style={{ color: '#fff' }} variant="contained" onClick={() => handlePlaceOrder(props.inv_response?.productInventory[selectedInv]?.booking_amount, true)}>{`Book For ${formatToINR(props.inv_response?.productInventory[selectedInv]?.booking_amount)}`}</Button>}
                                                                        {props.inv_response?.productInventory[selectedInv]?.disablePayButtonFlag === false && <Button color="primary" className="m-5" disabled={!(checkClick && selectedDealer !== null) ? true : false} variant="contained" onClick={() => handlePlaceOrder()}>Pay Full Amount</Button>}

                                                                        {
                                                                            selectedDealer === null &&
                                                                            <h5 className="red_heading">Please select the dealership</h5>
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className="center" style={{ flexDirection: 'column', cursor: 'not-allowed' }}>
                                                                    <h4 className="red_heading">Sold Out</h4>
                                                                    <h5 style={{ color: 'rgba(222, 54, 60, 1)' }}>This item is currently out of stock
                                                                    </h5>
                                                                </div>


                                                        } */}

                                                    </div>
                                                </>



                                            </Paper>
                                            }

                                        </div>


                                    </div>
                                </Grid>


                                }
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h3 className="p-b-10">Description</h3>
                                    <p className="p-b-10">
                                        {detail?.description}
                                        {/* {setDeliveryDate(detail?.deliveryDays)} */}
                                    </p>


                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={8}>
                                    <Paper variant="outlined" elevation={3} className='p-20' >
                                        <h3>Specification</h3>
                                        {
                                            portalType === '1' ?
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} md={3}>
                                                                <div className="specfication_block_wrapper">
                                                                    <ul className="product_spec_left">
                                                                        <li className={`pointer  ${selectedSpec == 0 && 'specfication_block_wrapper__Selected'} dark_heading`} onClick={() => setSelectedSpec(0)}>Performance</li>
                                                                        <li className={`pointer  ${selectedSpec == 1 && 'specfication_block_wrapper__Selected'} dark_heading `} onClick={() => setSelectedSpec(1)}>Battery</li>
                                                                        <li className={`pointer  ${selectedSpec == 2 && 'specfication_block_wrapper__Selected'} dark_heading`} onClick={() => setSelectedSpec(2)}>Breaks {`&`} Suspension</li>
                                                                        <li className={`pointer  ${selectedSpec == 4 && 'specfication_block_wrapper__Selected'} dark_heading`} onClick={() => setSelectedSpec(4)}>Warranty</li>
                                                                        <li className={`pointer  ${selectedSpec == 3 && 'specfication_block_wrapper__Selected'} dark_heading`} onClick={() => setSelectedSpec(3)}>Others</li>

                                                                    </ul>
                                                                </div>
                                                            </Grid>
                                                            {selectedSpec == 0 && <Grid item xs={12} md={9} className="spec_table_wrapper">

                                                                <table className="table_wrapper">
                                                                    <tr>
                                                                        <td>Top Speed</td>
                                                                        <td>{detail.top_speed}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Motor Power</td>
                                                                        <td>{detail.motor_power}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Motor Type</td>
                                                                        <td>{detail.motor_type}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Max Torque</td>
                                                                        <td>{detail.motor_torque}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Motor IP Rating</td>
                                                                        <td>{detail.motor_rating}</td>
                                                                    </tr>
                                                                </table>
                                                            </Grid>}

                                                            {selectedSpec == 1 && <Grid item xs={12} md={9} className="spec_table_wrapper">

                                                                <table className="table_wrapper">
                                                                    <tr>
                                                                        <td>Battery Power</td>
                                                                        <td>{detail.battery_power}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Battery Range</td>
                                                                        <td>{detail.battery_range}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Battery Type</td>
                                                                        <td>{detail.battery_type}</td>
                                                                    </tr>
                                                                </table>
                                                            </Grid>}


                                                            {selectedSpec == 2 && <Grid item xs={12} md={9} className="spec_table_wrapper">

                                                                <table className="table_wrapper">
                                                                    <tr>
                                                                        <td>Breaking System</td>
                                                                        <td>{detail.breaking_system}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Front Break</td>
                                                                        <td>{detail.front_break}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Front Suspension</td>
                                                                        <td>{detail.front_suspension}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Rear Break</td>
                                                                        <td>{detail.rear_break}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Rear Suspension</td>
                                                                        <td>{detail.rear_suspension}</td>
                                                                    </tr>
                                                                </table>
                                                            </Grid>}


                                                            {selectedSpec == 3 && <Grid item xs={12} md={9} className="spec_table_wrapper">

                                                                <table className="table_wrapper">
                                                                    <tr>
                                                                        <td>Registration Requirements</td>
                                                                        <td>{detail.registration_required}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Chasis</td>
                                                                        <td>{detail.chasis}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Dimensions</td>
                                                                        <td>{detail.dimensions}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Ground Clearance</td>
                                                                        <td>{detail.ground_clearance}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Head Light</td>
                                                                        <td>{detail.head_light}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Launch Year</td>
                                                                        <td>{detail.launch_year}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Special Features</td>
                                                                        <td>{detail.special_features}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tail Light</td>
                                                                        <td>{detail.tail_light}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tyre Size</td>
                                                                        <td>{detail.tyre_size}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Weight</td>
                                                                        <td>{detail.weight}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Wheel Size</td>
                                                                        <td>{detail.wheel_size}</td>
                                                                    </tr>

                                                                </table>
                                                            </Grid>}

                                                            {selectedSpec == 4 && <Grid item xs={12} md={9} className="spec_table_wrapper">

                                                                <table className="table_wrapper">
                                                                    <tr>
                                                                        Standard warranty conditions as mandated under FAME II policy i.e., for three years (battery + vehicle)
                                                                    </tr>

                                                                </table>
                                                            </Grid>}

                                                        </Grid>

                                                    </Grid>

                                                </Grid> :

                                                <Grid item xs={12} md={12} className="spec_table_wrapper">

                                                    <table className="table_wrapper">
                                                        <tr>
                                                            <td>Battery</td>
                                                            <td>{detail.battery}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Front Brake</td>
                                                            <td>{detail.brake_front}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rear Break</td>
                                                            <td>{detail.brake_rear}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Charging Time</td>
                                                            <td>{detail.charging_time}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dimensions</td>
                                                            <td>{detail.dimensions}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Motor Rating</td>
                                                            <td>{detail.motor_rating}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Payload</td>
                                                            <td>{detail.payload}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Seating Capacity</td>
                                                            <td>{detail.seating_capacity}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Speed</td>
                                                            <td>{detail.speed}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Front Suspension</td>
                                                            <td>{detail.suspension_front}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rear Suspension</td>
                                                            <td>{detail.suspension_rear}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Torque</td>
                                                            <td>{detail.torque}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Vehicle Category</td>
                                                            <td>{detail.vehicle_category}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Vehicle Range</td>
                                                            <td>{detail.vehicle_range}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Vehicle Roof Type</td>
                                                            <td>{detail.vehicle_roof_type}</td>
                                                        </tr>

                                                    </table>
                                                </Grid>

                                        }

                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4}>

                                    <Paper variant="outlined" elevation={3} className="price_block">
                                        <div className="p-5">
                                            <div className="dealer_block">Dealerships</div>
                                            <div className="dealership_block">
                                                {props?.inv_response?.productInventory?.map((item, index) => (
                                                    <div className={`dealer_block_data pointer ${selectedDealer == index && 'inv_selected'}`} onClick={() => handleSelectedDealer(index)}>
                                                        <div style={{ display: 'flex' }}>
                                                            <input type="radio" checked={selectedDealer == index} onClick={() => handleSelectedDealer(index)} />
                                                            <div style={{ marginLeft: 10 }}>
                                                                <div className="title">{ucfirst(item.showroomName)}</div>
                                                                <div className={` ${selectedDealer == index ? 'sub_title_dark' : 'sub_title'}}`}>Address: {ucfirst(item.street_address)} , {item.city}</div>
                                                                <div className={` ${selectedDealer == index ? 'sub_title_dark' : 'sub_title'}}`}>Distance:  {item.distance}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>



                                        </div>
                                    </Paper>
                                </Grid>

                            </Grid>

                        </>
                }


            </div>
        </div >

    )
}
const mapStateToProps = (state) => ({
    detail_loading: state.productReducer.detail_loading,
    detail_error: state.productReducer.detail_error,
    detail_success: state.productReducer.detail_success,
    detail_response: state.productReducer.detail_response,

    inv_loading: state.productReducer.inv_loading,
    inv_error: state.productReducer.inv_error,
    inv_success: state.productReducer.inv_success,
    inv_response: state.productReducer.inv_response,

    product_response: state.productReducer.product_response,

    product_images_res: state.productReducer.get_product_images_data,
    product_images_loading: state.productReducer.get_product_image_loading,
})
export default connect(mapStateToProps, { getProductDetail, getProductInv, getProductImagesByColor, customerDetail })(ProductsDetails)
