
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStateList } from '../../redux/actions';
import Select from '../../storybook/Select/Select';
import Button from '@material-ui/core/Button';
import Input from '../../storybook/Input/Input';
import { addAddress, editAddress, resetAddAddress, resetEditAddress } from '../../redux/actions/Confirmation';

const AddressForm = (props) => {

    const dispatch = useDispatch();
    const addSuccess = useSelector(state => state.confirmationReducer.add_address_success)
    const editSuccess = useSelector(state => state.confirmationReducer.edit_address_success)

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        street: '',
        pincode: '',
        city: '',
        state: '',
        stateId: '',
        id: ''
    })


    const { name, phone, street, pincode, city, state, stateId } = formData;

    const onFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleStateChange = (state) => {
        // setFormData({ ...formData, state: state, stateId: state.id })
    }

    const addAddressSubmit = (e) => {
        e.preventDefault()
        props.edit ? dispatch(editAddress(formData)) : dispatch(addAddress(formData))
    }


    const data = [
        { id: localStorage.getItem('x_auth_state_id'), name: localStorage.getItem('x_auth_state_name') }
    ]

    useEffect(() => {
        setFormData({ ...formData, stateId: localStorage.getItem('x_auth_state_id') })
        if (props.edit) {
            setFormData({
                street: props.data.street,
                pincode: props.data.pincode,
                city: props.data.city,
                stateId: localStorage.getItem('x_auth_state_id'),
                id: props.data.id,
                phone: props.data.phone

            })
        }
    }, [])


    useEffect(() => {
        if (addSuccess) {
            dispatch(resetAddAddress())
            props.onClose()
        }
        if (editSuccess) {
            dispatch(resetEditAddress())
            props.onClose()
        }
    }, [addSuccess, editSuccess])


    return (
        <div>
            <form onSubmit={addAddressSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>

                        {
                            props.edit ? <h1>Edit Address</h1> : <h1>Add New Address</h1>
                        }

                    </Grid>
                    <Grid item xs={12}>
                        <h3>Fill The Details</h3>
                    </Grid>
                    {/* <Grid item xs={12}>
                    <h5>Name</h5>
                    <Input placeholder="Enter Name" value={name}
                        name="name"
                        onChange={(e) => onFormChange(e)} />
                </Grid> */}
                    <Grid item xs={12}>
                        <h5>Phone Number</h5>
                        <Input placeholder="Enter Phone" value={phone}
                            name="phone"
                            type="number"
                            onChange={(e) => onFormChange(e)} />
                    </Grid>

                    <Grid item xs={12}>
                        <h5>Street</h5>
                        <Input placeholder="Enter Street" value={street}
                            name="street"

                            onChange={(e) => onFormChange(e)} />
                    </Grid>
                    <Grid item xs={12}>
                        <h5>Pincode</h5>
                        <Input placeholder="Enter Pincode" value={pincode}
                            name="pincode"
                            type="number"

                            onChange={(e) => onFormChange(e)} />
                    </Grid>
                    <Grid item xs={6}>
                        <h5>City</h5>
                        <Input placeholder="Enter City" value={city}
                            name="city"

                            onChange={(e) => onFormChange(e)} />
                    </Grid>
                    <Grid item xs={6}>
                        <h5>State</h5>

                        <Select data={data} onChange={handleStateChange} placeholder={data.name} />
                    </Grid>
                    <Grid item xs={12}>
                        {props.edit ? <Button variant="contained" color="primary"
                            fullWidth type="submit">Edit Address</Button>
                            :
                            <Button variant="contained" color="primary"
                                fullWidth type="submit">Add Address</Button>}
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default AddressForm
