import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import bajaj_chetak from '../assets/images/bajaj-chetak.svg';
import Button from '@material-ui/core/Button';
import { connect, useSelector } from 'react-redux';
import { addComplaint, getOrdersDownloadInvoice, getOrdersList, getProductDetail, getTestDrive, resetComplaint } from '../../redux/actions'
import { formatToINR, getDecryptedItem, ucfirst } from '../../helper';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import order_not_found from '../assets/images/order_not_found.svg';
import not_found from '../assets/images/not_found.svg';
// import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import { remainingPayment, resetRemainingPayment } from '../../redux/actions/PaymentAction';
import AppConstants from '../../values/AppConstants';
import StepperComp from './common-ui/Stepper/Stepper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import vehicle_not_found from '../assets/images/vehicle_not_found.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from 'react-router-dom';



const Orders = (props) => {


    const [active, setActive] = useState('order');

    const [openComplaint, setOpenComplaint] = useState(false);

    const [complaintForm, setComplaintForm] = useState({
        id: '',
        complaint: ''
    })

    const dispatch = useDispatch();
    const history = useHistory();

    const testData = useSelector(state => state.orderReducer.get_test_response)
    const testDataLoading = useSelector(state => state.orderReducer.get_test_loading)
    const remainingSuccess = useSelector(state => state.paymentReducer.remaining_success)
    const complaintSuccess = useSelector(state => state.complaintReducers.add_success)
    const productImages = useSelector(state => state.productReducer.get_product_images_data)
    const downloadLoading = useSelector(state => state.orderReducer.get_download_invoice_loading)
    const setMenu = (value) => {
        setActive(value)
    }

    const payRemaining = (id, amount, remaining) => {
        let newAmount = amount - remaining

        const options = {
            key: AppConstants.RAZORPAY_KEY,
            amount: (parseInt(newAmount) * 1) * 100,
            currency: 'INR',
            name: "EESL",
            description: 'Payment Through Razorpay.',
            // image: logo__statiq,
            handler: async function (response) {
                const data = {
                    razorpay_id: response.razorpay_payment_id
                }
                dispatch(remainingPayment({ payment_id: data.razorpay_id, amount: newAmount, order_id: id }))

            },
            prefill: {
                // name: userInfo.fullname,
                // email: userInfo.email,
                // contact: addressData.data.phone,
            },

            theme: {
                color: "#203594",
            },
            modal: {
                ondismiss: function () {
                    document.body.style.overflow = 'auto';
                }
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
            dispatch(remainingPayment({ payment_id: response.error.metadata.payment_id, amount: newAmount, order_id: id }))
        });
        paymentObject.open();
    }


    const buttonAmount = (amount, remaining) => {
        let newAmount = amount - remaining
        return formatToINR(newAmount)
    }

    const handleCloseComplaint = () => {

        setComplaintForm({
            id: '',
            complaint: ''
        })
        setOpenComplaint(false)
    }

    const handleOpenComplaint = (id) => {
        setComplaintForm({ ...complaintForm, id })
        setOpenComplaint(true)
    }

    const onChangeComplaint = (e) => {
        setComplaintForm({ ...complaintForm, complaint: e.target.value })
    }

    const submitComplaint = () => {
        dispatch(addComplaint(complaintForm))
    }


    const downloadInvoice = (id) => {
        dispatch(getOrdersDownloadInvoice(id))
    }

    const getProductDetail = (vehicle, state) => {
        history.push(`/productdetail/${vehicle}/${state}`)
    }

    // useEffect(() => {
    //     if (downloadUrl) {
    //         console.log(downloadUrl.receipt_url)

    //     }

    // }, [downloadUrl])

    useEffect(() => {
        const razorPayScript = document.createElement('script');
        razorPayScript.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(razorPayScript)
        props.getOrdersList()
    }, [])

    useEffect(() => {
        active === 'testdrive' && dispatch(getTestDrive())
    }, [active])

    useEffect(() => {
        remainingSuccess && dispatch(resetRemainingPayment())
        remainingSuccess && props.getOrdersList()
    }, [remainingSuccess])

    useEffect(() => {
        if (complaintSuccess) {
            dispatch(resetComplaint())
            handleCloseComplaint()
        }
    }, [complaintSuccess])



    const data = new Array(5).fill('0')
    return (
        <div>
            {/* <div className="catalogue_top_wrapper">
                <h2>My Orders</h2>
            </div> */}
            <div className="sub_heading order_sub_heading" style={{ display: 'flex', textDecoration: 'none', justifyContent: 'flex-start' }}>
                <span style={{ marginRight: 30 }} className={active === 'order' ? 'active_order pointer' : 'pointer'} onClick={() => setMenu('order')}>Orders</span>
                <span className={active === 'testdrive' ? 'active_order pointer' : 'pointer'} onClick={() => setMenu('testdrive')}>Test Drive</span>
            </div>

            <div className="main_container">
                {props.loading ? <div className="center"> <CircularProgress /> </div> :
                    (active === 'order' && props.response?.order?.length > 0) ?
                        <Grid container spacing={3}>
                            {
                                props.response?.order?.map((item, i) => (
                                    <Grid key={i} item xs={12}>
                                        <Paper elevation={2} style={{ display: 'flex', justifyContent: 'space-between', width: '90%', flexWrap: 'wrap' }} className="p-10 order_paper">
                                            <Grid item xs={12} md={4} className="center">
                                                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={item.imageUrl ? item.imageUrl : vehicle_not_found} alt="vehicle" height={150} />
                                                    {/* <img src={productImages?.product_images?.length > 0 ? productImages?.product_images[0] : vehicle_not_found} height="150" alt="bajaj" /> */}
                                                </div>

                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <Grid container spacing={3}>
                                                    <Grid xs={12} className="p-10">
                                                        <h2 className="p-b-10">{ucfirst(item.vehicleName)}</h2>
                                                    </Grid>
                                                    <Grid item xs={6} md={6} >
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <div className="dark_heading">
                                                                Order Id
                                                            </div>
                                                            <div className="dark_heading">{item.id}</div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <div className="dark_heading">Order Date</div>
                                                            <div className="title">{item.orderDate}</div>
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <div className="p-b-10">
                                                            <div className="dark_heading">
                                                                Billing Address
                                                            </div>
                                                            <div className="title" style={{ display: 'flex', flexDirection: "column" }}>
                                                                <span> {item.street}</span>
                                                                <span> {item.state}</span>
                                                                <span> {item.pincode}</span>

                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '9.1rem' }}>
                                                            <div className="dark_heading">
                                                                Color
                                                            </div>
                                                            <div style={{ backgroundColor: `${item.hexCode}` }} className="product_color_round"></div>
                                                        </div>
                                                    </Grid>

                                                </Grid>

                                                <Grid item xs={12}>
                                                    <div>

                                                        <div>
                                                            {
                                                                item.orderStatus === 'Cancelled' ?
                                                                    <h5 className="red_dark_heading center">Your Order is Cancelled</h5>
                                                                    :
                                                                    item.orderStatus === 'Payment Failed' ?
                                                                        <h5 className="red_dark_heading center">Payment Failed !</h5>
                                                                        :
                                                                        <StepperComp step={item.orderStatus} stepId={item.orderStatusId} />
                                                            }


                                                        </div>
                                                        {
                                                            item.orderStatus === 'Delivered' &&
                                                            <h5 className="dark_heading">Your Order is Delivered</h5>
                                                        }


                                                        {/* <div className="flex_between remaining_btn">

                                                            <Button color="primary"
                                                                onClick={() => handleOpenComplaint(item.id)}
                                                                startIcon={
                                                                    <AssignmentLateIcon />
                                                                }
                                                            >Complaint</Button>

                                                            <Button color="primary" variant="contained" onClick={() => downloadInvoice(item.id)} >Download Invoice</Button>
                                                        </div> */}

                                                    </div>
                                                </Grid>


                                            </Grid>
                                            <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'end' }}>
                                                    <span>
                                                        <h2 style={{ color: '#000' }} className="p-b-10" >{formatToINR(item.amount)}</h2>
                                                    </span>
                                                    {
                                                        item.totalPaidFlag === 0 && item.orderStatusId !== 7 && item.orderStatusId !== 5 &&
                                                        <Button variant="contained" style={{ backgroundColor: '#51A653', color: '#fff' }}
                                                            onClick={() => payRemaining(item.id, item.amount, item.amountPaid ? item.amountPaid : 0)}>
                                                            {

                                                                `Pay Balance  ${buttonAmount(item.amount, item.amountPaid)}`
                                                            }
                                                        </Button>

                                                    }

                                                    <div className="center p-10">
                                                        <div className="dark_heading " style={{ marginRight: '0.3rem' }}>
                                                            Paid Amount
                                                        </div>
                                                        <h4>{formatToINR(item.amountPaid ? item.amountPaid : item.amount)}</h4>
                                                        {/* <div style={{ color: '#51A653', fontWeight: 600 }}>{formatToINR(item.amountPaid ? item.amountPaid : item.amount)}</div> */}
                                                    </div>
                                                </div>
                                                <div>
                                                    {item.totalPaidFlag === 1 && item.orderStatusId !== 7 && item.orderStatusId !== 5 && <div className='dark_heading flex_end' style={{ padding: '5px 0px' }}>
                                                        Expected  Date: {item.expectedDeliveryDate}
                                                    </div>}
                                                    <div className="remaining_btn flex_end">

                                                        <Button color="primary"
                                                            size="small"
                                                            onClick={() => handleOpenComplaint(item.id)}
                                                            startIcon={
                                                                <AssignmentLateIcon />
                                                            }
                                                        >Complaint</Button>
                                                        {
                                                            item.orderStatusId >= 1 && item.orderStatusId <= 5 && <Button color="primary" variant="contained" startIcon={<GetAppIcon />} onClick={() => downloadInvoice(item.id)} >Advance Note</Button>

                                                        }
                                                    </div>
                                                </div>

                                            </Grid>




                                        </Paper>
                                    </Grid>
                                ))
                            }

                        </Grid>
                        :
                        active === 'order' && <div className="center not_found">
                            <img src={order_not_found} alt="ordernotfound" className="not_found_img" />
                            <h1>No Orders Found!!</h1>
                        </div>
                }

                {/* Test Drive */}

                {testDataLoading ? <div className="center"> <CircularProgress /> </div> :
                    (active === 'testdrive' && testData?.testDriveDetail?.length > 0) ?
                        <Grid container spacing={3}>
                            {
                                testData?.testDriveDetail?.map((item, i) => (
                                    <Grid key={i} item xs={12}>
                                        <Paper variant="outlined" style={{ display: 'flex', justifyContent: 'space-between', width: '90%', flexWrap: 'wrap' }} className="p-10">
                                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                                <img src={item.imageUrl ? item.imageUrl : vehicle_not_found} alt="vehicle" height={150} />
                                            </div>

                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                <h2 className="p-b-20">{ucfirst(item.vehicleName)}</h2>
                                                <div style={{ display: 'flex' }} className="p-b-10">
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '8rem' }}>
                                                        <div className="dark_heading">
                                                            Test Drive Id
                                                        </div>
                                                        <div className="dark_heading">{item.testDriveId}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {
                                                            item.testDriveDate &&
                                                            <>
                                                                <div className="dark_heading">Test Drive Date</div>
                                                                <div className="title">{item.testDriveDate}</div>
                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="dark_heading">
                                                        Address
                                                    </div>
                                                    <div className="title ">
                                                        {item.showroomStreet}
                                                        <br />
                                                        {item.showroomCity}
                                                        <br />
                                                        {item.showroomState}
                                                        <br />
                                                        {item.showroomPincode}
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span>
                                                    {/* <h2 style={{ color: '#000' }} >{formatToINR(item.amount)}</h2> */}
                                                </span>
                                                {item.orderNowFlag && <Button color="secondary" size='small' style={{ color: '#fff' }} onClick={() => getProductDetail(item.vehicleId, item.stateId)} variant="contained" >Order Now</Button>}
                                            </div>
                                        </Paper>
                                    </Grid>
                                ))
                            }

                        </Grid>
                        :
                        active === 'testdrive' && <div className="center not_found">
                            <img src={not_found} alt="not found" className="not_found_img" />
                            <h1>No Test Drive Found!!</h1>
                        </div>
                }

            </div>
            {/* Modal of complaint */}


            <Dialog open={openComplaint} onClose={handleCloseComplaint} maxWidth="sm" fullWidth>
                <DialogTitle disableTypography><h2>Feedback</h2></DialogTitle>
                <DialogContent>
                    <h3>Fill The Detail</h3>
                    <TextareaAutosize
                        value={complaintForm.complaint}
                        onChange={(e) => onChangeComplaint(e)}
                        minRows={15}
                        placeholder="Enter Text"
                        style={{ width: '100%' }}
                    />
                    <div className="center">
                        <Button variant="contained" color="primary" onClick={submitComplaint} >Submit</Button>
                    </div>

                </DialogContent>

            </Dialog>

        </div>

    )
}
const mapStateToProps = (state) => ({
    loading: state.orderReducer.loading,
    error: state.orderReducer.error,
    success: state.orderReducer.success,
    response: state.orderReducer.response,
})

export default connect(mapStateToProps, { getOrdersList })(Orders)
