import React from 'react'
import Grid from '@material-ui/core/Grid';
import Input from '../../../storybook/Input/Input';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';

const LoanForm = ({ formData, onFormSubmit }) => {

    const [sameAddress, setSameAddress] = useState(false)

    const onFormChange = (e) => {
        let obj = {
            name: e.target.name,
            value: e.target.value
        }
        onFormSubmit(obj)
    }

    const handleFileChange = (e) => {
        let obj = {
            name: e.target.name,
            value: e.target.files[0]
        }
        onFormSubmit(obj)
    }

    const handleAddress = (e) => {
        if (e.target.checked) {
            setSameAddress(true)
            let obj = {
                name: 'permanent_address',
                value: formData.present_address
            }
            onFormSubmit(obj)
        }
        else {
            setSameAddress(false);
        }
    }

    return (
        <div>

            <div className='main_container' style={{ marginTop: '1rem' }}>
                <div className='center'>
                    <div style={{ width: '80%' }}>
                        <h3 className='dark_heading'>
                            APPLICATION FOR SANCTION OF ELECTRIC 2-WHEELER VEHICLE LOAN FOR TELANGANA STATE GOVT.
                            EMPLOYEES ON EMI BASIS.
                        </h3>
                        <form className='m_t_20'>
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={6}>
                                    <h5>Name of Employee</h5>
                                    <Input name="name_of_emp"
                                        value={formData.name_of_emp}
                                        placeholder="Enter Name of Employee"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Father Name</h5>
                                    <Input name='father_name'
                                        value={formData.father_name}
                                        placeholder="Enter Father Name"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5 className="input_margin_bottom">Present Address</h5>
                                    <Input
                                        name='present_address'
                                        value={formData.present_address}
                                        placeholder="Enter Present Address"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <div className='flex_between'>
                                        <h5>Permanent Address</h5>
                                        <span>
                                            <Checkbox size='small' style={{ padding: 0 }} onClick={(e) => handleAddress(e)} color="primary" />
                                            <span style={{ fontSize: '0.65rem' }}>Same as Present Address</span>
                                        </span>
                                    </div>
                                    <Input
                                        name='permanent_address'
                                        value={formData.permanent_address}
                                        disabled={sameAddress}
                                        placeholder="Enter Permanent Address"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Email ID of Employee</h5>
                                    <Input name='email'
                                        value={formData.email}
                                        placeholder="Enter Email ID"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Phone Number of Employee</h5>
                                    <Input name='phone'
                                        value={formData.phone}
                                        placeholder="Enter Employee ID"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Date of Birth</h5>
                                    <Input name='dob'
                                        value={formData.dob}
                                        type="date"
                                        placeholder="Enter Date of Birth"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Aadhaar Number</h5>
                                    <Input name='aadhar_number'
                                        value={formData.aadhar_number}
                                        placeholder="Enter Aadhar Number"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Upload Aadhaar Card Front</h5>
                                    <input type="file"
                                        name='aadhar_card_front'
                                        accept='application/pdf, image/jpeg'
                                        onChange={(e) => handleFileChange(e)} />
                                    <label for='files'>{formData.aadhar_card_front_name}</label>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Upload Aadhaar Card Back</h5>
                                    <input type="file"
                                        name='aadhar_card_back'
                                        className='input_upload_button'
                                        accept='application/pdf, image/jpeg'
                                        onChange={(e) => handleFileChange(e)} />
                                    <label for='files'>{formData.aadhar_card_back_name}</label>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <h5>Upload passport size photo</h5>
                                    <input type="file"
                                        name='passport_photo'
                                        className='input_upload_button'
                                        accept='application/pdf, image/jpeg'
                                        onChange={(e) => handleFileChange(e)} />
                                    <label for='files'>{formData.passport_photo_name}</label>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>PAN Number</h5>
                                    <Input name='pan_number'
                                        value={formData.pan_number}
                                        placeholder="Enter PAN Number"
                                        onChange={(e) => onFormChange(e)} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <h5>Upload PAN Card</h5>
                                    <input type="file"
                                        name='pan_card'
                                        className='input_upload_button'
                                        accept='application/pdf, image/jpeg'
                                        onChange={(e) => handleFileChange(e)} />
                                    <label for='files'>{formData.pan_card_name}</label>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default LoanForm;