import React from 'react';
import LoginImage from '../assets/images/login_image.svg';
import Button from '@material-ui/core/Button';
import Input from '../../storybook/Input/Input';
import { useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { customerLogin, resetNewPassword, resetOTP, resetOTPVerify } from '../../redux/actions/AuthActions';
import AppConstants from '../../values/AppConstants';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
const Login = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const authData = useSelector(state => state.authReducer.login_success)
    const new_pass_success = useSelector(state => state.authReducer.new_pass_success)


    const signUp = e => {
        e.preventDefault()
        AppConstants.route = location.pathname == '/auth/login' && AppConstants.route;
        history.push('/auth/register')
    }

    const loginUser = e => {
        e.preventDefault()
        dispatch(customerLogin({ email: username, password: password }))

    }

    const routeForget = () => {
        history.push('/auth/forget-password')
    }

    useEffect(() => {
        dispatch(resetOTP())
        dispatch(resetOTPVerify())
    }, [])

    useEffect(() => {
        // authData &&  AppConstants.route && history.push(AppConstants.route)
        if (authData) {
            new_pass_success ? history.push('/') : AppConstants.route && history.push(AppConstants.route)
        }

    }, [authData, new_pass_success])

    return (
        <Grid container>
            <Grid item xs={12} md={8}>
                <div className={props.modal ? 'auth_img_res_wrapper form_view' : 'auth_img_wrapper form_view'}>
                    <div className="top_img_wrapper">
                        <span className="top_heading_wrapper">
                            <h1>Electric Vehicle Digital Platform</h1>
                            <h3 className="xs_heading">A completely paperless, cashless and system driven e-market place for buying and selling of EV</h3>
                        </span>

                        <img
                            className={props.modal ? 'auth_res_img' : 'auth_img'}
                            src={LoginImage} alt="login" />
                    </div>

                </div>
            </Grid>

            <Grid item xs={12} md={4}>
                <div style={{ height: props.modal && 'auto' }} className="sign_form_wrapper" >
                    <h1>Sign In</h1>
                    <h4 style={{ color: 'rgba(40, 44, 64, 0.6)' }}>Welcome, we missed you!</h4>
                    <form onSubmit={loginUser}>
                        <div className='m_t_40'>
                            <h5>Phone Number</h5>
                            <Input placeholder="Enter Phone Number" type="number" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className='m_t_20'>
                            <h5>Password</h5>
                            <Input placeholder="Enter password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div style={{ color: '#F0AE4B', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }} onClick={routeForget}>
                            Forgot Password?
                        </div>
                        <div className='m_t_20'>
                            <Button color="primary" className="p-10" fullWidth
                                type="submit"
                                variant="contained">Login</Button>
                        </div>
                        <div className='m_t_10'>
                            <Button color="secondary" style={{ color: '#fff' }} className="p-10"
                                onClick={signUp}
                                fullWidth variant="contained">Sign Up</Button>
                        </div>


                    </form>

                </div>
            </Grid>



        </Grid>


    )
}
export default Login
