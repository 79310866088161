import Actions from "../../values/Actions";

const PAYMENT_INITIAL_STATE = {
    loading: true,
    success: false,
    error: '',
    response: {}
}

const REMAINING_PAYMENT_INITIAL_STATE = {
    remaining_loading: true,
    remaining_success: false,
    remaining_error: '',
    remaining_response: {}
}

const INITIAL_STATE = {
    ...PAYMENT_INITIAL_STATE,
    ...REMAINING_PAYMENT_INITIAL_STATE
}
export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Actions.RAZORPAY_PROGRESS:
            return { ...state, loading: true, success: false }
        case Actions.RAZORPAY_SUCCESS:
            return { ...state, loading: false, success: true, response: payload }
        case Actions.RAZORPAY_ERROR:
            return { ...state, loading: false, success: false, error: payload }

        case Actions.RAZORPAY_RESET:
            return { ...state, loading: false, success: false, error: '' }

        case Actions.RAZORPAY_REMAINING_PROGRESS:
            return { ...state, remaining_loading: true, remaining_success: false }
        case Actions.RAZORPAY_REMAINING_SUCCESS:
            return { ...state, remaining_loading: false, remaining_success: true, remaining_response: payload }
        case Actions.RAZORPAY_REMAINING_ERROR:
            return { ...state, remaining_loading: false, remaining_success: false, remaining_error: payload }
        case Actions.RAZORPAY_REMAINING_RESET:
            return { ...state, remaining_loading: false, remaining_success: false, remaining_error: '' }
        default:
            return state
    }
}