import React, { useState } from 'react';
import './Input.css'

export default function Input(props) {
    return (
        <div className='viewV'>
            <input
                {...props}
                type={props.type ? props.type : 'text'}
                className={`input ${props.error && 'input-error'}`}
                placeholder={props.placeholder}
                required
            />
            {props.error && <span className='input__errormessage'>{props.error}</span>}
        </div>
    );
}

