import React, { useEffect, useState } from 'react';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import Dialog from '@material-ui/core/Dialog';
import state1 from '../../components/assets/images/state1.svg';
import state2 from '../../components/assets/images/state2.svg';
import state3 from '../../components/assets/images/state3.svg';
import state4 from '../../components/assets/images/state4.svg';
import state5 from '../../components/assets/images/state5.svg';
import state6 from '../../components/assets/images/state6.svg';
import state7 from '../../components/assets/images/state7.svg';

import './Select.css'
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';

const Select = (props) => {
    const [isOpened, setOpened] = useState(false)
    const [selectedItem, setSelectItem] = useState('--Select--')
    const [openModal, setOpenModal] = useState(false);
    const [searchVal, setSearchVal] = useState(null)
    const [stateData, setStateData] = useState([])

    const stateImages = {
        state1,
        state2,
        state3,
        state4,
        state5,
        state6,
        state7
    }

    useEffect(() => {
        if (props.placeholder) {
            setSelectItem(props.placeholder)
        } else {
            props.data?.length && setSelectItem(props.data[0].name)
        }
    }, [])

    const getArrowTint = () => {
        return isOpened ? (props.activeColor) ? props.activeColor : '#203594' : (props.tintColor) ? props.tintColor : '#20359440'
    }

    const handleClick = () => {
        props.modalOn ? setOpenModal(!openModal) : setOpened(false)
    }


    const handleChange = (e) => {
        let value = e.target.value
        setSearchVal(value.toLowerCase())
        let data = props?.data?.filter(state => state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        setStateData(data)
    }



    useEffect(() => {
        props.data && setStateData(props.data)
    }, [])
    return (
        <div style={{ position: 'relative' }}>
            <div className={`select ${isOpened && 'select-clicked'} ${props.className}`} style={props.style} onClick={handleClick} >
                <span>{selectedItem}</span>
                {!isOpened ? <ExpandMoreOutlinedIcon style={{ color: getArrowTint() }} /> :
                    <ExpandLessOutlinedIcon style={{ color: getArrowTint() }} />}
            </div>
            {isOpened && <div className='select__options' style={props.optionStyle}>
                <ul>
                    {props.data.map((item, index) => {
                        return (<li
                            key={index.toString()}
                            onClick={() => {
                                setSelectItem(item.name)
                                setOpened(false)
                                props.onChange(item)
                            }}>{item.name}</li>)
                    })}
                </ul>
            </div>}
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="xl">
                <DialogContent>


                    <TextField variant="filled" fullWidth placeholder="Search States" value={searchVal} onChange={e => handleChange(e)} />
                    <ul className="modal_list">
                        {stateData.map((item, index) => {
                            return (
                                index <= 3 && <div>
                                    <li
                                        key={index.toString()}
                                        onClick={() => {
                                            setSelectItem(item.name)
                                            setOpened(false)
                                            setOpenModal(false)
                                            props.onChange(item)
                                        }}>
                                        <div className={selectedItem === item.name ? 'state_selected' : 'state_list'}>
                                            {<img src={stateImages[`state${index + 1}`]} className="p-5" />}
                                            {item.name}
                                        </div>

                                    </li>
                                </div>
                            )
                        })}
                    </ul>
                    <div className="flex_even">
                        <ul>
                            {stateData.map((item, index) => {
                                return (
                                    index > 3 && index <= 16 && <div className="state_list_cont">
                                        <li style={{ cursor: 'pointer' }}
                                            key={index.toString()}
                                            onClick={() => {
                                                setSelectItem(item.name)
                                                setOpened(false)
                                                setOpenModal(false)
                                                props.onChange(item)
                                            }}>
                                            {item.name}
                                            {/* <div className={selectedItem === item.name ? 'state_selected' : 'state_list'}>
                                            {<img src={stateImages[`state${index + 1}`]} className="p-5" />}
                                          
                                        </div> */}

                                        </li>
                                    </div>
                                )
                            })}
                        </ul>
                        <ul>
                            {stateData.map((item, index) => {
                                return (
                                    index > 16 && <div className="state_list_cont">
                                        <li
                                            style={{ cursor: 'pointer' }}
                                            key={index.toString()}
                                            onClick={() => {
                                                setSelectItem(item.name)
                                                setOpened(false)
                                                setOpenModal(false)
                                                props.onChange(item)
                                            }}>
                                            {item.name}
                                            {/* <div className={selectedItem === item.name ? 'state_selected' : 'state_list'}>
                                            {<img src={stateImages[`state${index + 1}`]} className="p-5" />}
                                          
                                        </div> */}

                                        </li>
                                    </div>
                                )
                            })}
                        </ul>
                    </div>



                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Select