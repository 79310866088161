import React, { useState, useEffect } from 'react';
import '../Catalogue.css';
import footer_logo from '../../assets/images/footer_logo.svg';
import supported_by from '../../assets/images/supported_by.svg';
import copyright_icon from '../../assets/images/copyright_icon.svg';
import contact_us_img from '../../assets/images/contact_us_img.svg';
import ica_logo from '../../assets/images/ica_logo.png';
import whatsapp_icon from '../../assets/images/whatsapp_icon.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined'
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

const Footer = () => {

    const [openContact, setOpenContact] = useState(false)
    const [stateId, setStateId] = useState(localStorage.getItem('x_auth_state_id'))
    const loading = useSelector(state => state.productReducer.product_loading)
    const stateResponse = useSelector(state => state.productReducer.response)
    const history = useHistory();

    const handleAboutUs = () => {
        history.push('/about-us')
    }

    const handleFaq = () => {
        history.push('/faq')
    }

    const handlePrivacyPolicy = () => {
        history.push('/privacy-policy')
    }

    useEffect(() => {
        let id = localStorage.getItem('x_auth_state_id');
        setStateId(id)
    }, [loading])

    return (
        <>
            <div className="footer">
                <div className="catalogue_footer_wrapper">
                    {/* <h5> &copy; Convergence Energy Services limited All rights reserved</h5> */}
                    <div className="center">
                        <ul style={{ color: '#000000' }}>
                            <li style={{ display: 'flex'}}>
                                <img src={copyright_icon} style={{ marginRight: 2 }} />
                                Sharify Services Private Limited. All Rights Reserved.
                            </li>
                        </ul>
                        {/* 
                        <img src={supported_by} alt="supported" height="15" />
                        <img src={ica_logo} alt="ica" height="55" width="156" className="footerImage" /> */}
                    </div>
                    <div className="center">
                        <span >
                            <ul className="footer_right_block">
                                <li onClick={() => setOpenContact(true)}>Contact Us</li>
                                <li onClick={() => handleAboutUs()}> &#x25CF; About Us</li>
                                {/* <li onClick={() => handleFaq()}> &#x25CF; FAQs</li> */}
                                <li onClick={() => handlePrivacyPolicy()}> &#x25CF; Privacy Policy</li>
                            </ul>
                        </span>
                        <span>
                            <a href="https://www.statiq.in/" rel="noopener noreferrer" target="_blank">
                                <img src={footer_logo} alt="statiq" height="20" />
                            </a>
                        </span>

                    </div>

                </div>
            </div>

            {/* Modal of contact */}
            <Dialog open={openContact} onClose={() => setOpenContact(false)} fullWidth maxWidth="sm">
                <DialogContent>
                    <h1>Contact Us</h1>
                    <div className="contact_us_wrapper">
                        <div>
                            <div className="p-b-10 contact_us_info">
                                {
                                    stateResponse?.states?.map((item) => (
                                        item.email !== null && item.id == stateId &&
                                        <>
                                            <span className="p-r-5 footer_icon" style={{ fontWeight: 700 }}>
                                                @
                                            </span>
                                            <span className="dark_heading">
                                                {item.email}
                                            </span>
                                        </>
                                    ))
                                }
                            </div>
                            <div className="p-b-10 contact_us_info">
                                {
                                    stateResponse?.states?.map((item) => (
                                        item.contactNumber !== null && item.id == stateId &&
                                        <>
                                            <span className="p-r-5 footer_icon">
                                                <PhoneEnabledOutlinedIcon />
                                            </span>
                                            <span className="dark_heading">
                                                {item.contactNumber}
                                            </span>
                                        </>
                                    ))

                                }
                            </div>
                            <div className="p-b-10 contact_us_info">
                                {
                                    stateResponse?.states?.map((item) => (
                                        item.whatsapp !== null && item.id == stateId &&
                                        <>
                                            <div className="p-r-5 footer_icon">
                                                <img src={whatsapp_icon} style={{ marginRight: 5 }} />
                                            </div>
                                            <div className="dark_heading">
                                                {item.whatsapp}
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                            <div className="p-b-10 contact_us_info">
                                <span className="p-r-5 footer_icon">
                                    <BusinessCenterOutlinedIcon />
                                </span>
                                <span className="dark_heading">
                                    Mon-Sun(10am-5PM)
                                </span>
                            </div>
                            <div className="contact_us_info">
                                {
                                    stateResponse?.states?.map((item) => (
                                        item.address !== null && item.id == stateId &&
                                        <>
                                            <div className="p-r-5 footer_icon" style={{ marginTop: '-1rem' }}>
                                                <LocationOnOutlinedIcon />
                                            </div>
                                            <div className="dark_heading">

                                                {item.address}

                                            </div>
                                        </>
                                    ))

                                }
                            </div>

                        </div>
                        <div>
                            <img src={contact_us_img} alt="contact us" height="250" className="contact_us_img" />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Footer
