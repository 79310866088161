import Actions from "../../values/Actions";

export const setAlert = (type, msg) => dispatch => {
    dispatch({
        type: Actions.SET_ALERT,
        payload: { type, msg }
    })

    setTimeout(()=>{
        dispatch({
            type : Actions.REMOVE_ALERT
        })
    },3000)
}