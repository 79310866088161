import { HTTPGetRequest, HTTPPutRequest, HTTPRequest } from "../../helper"
import Actions from "../../values/Actions"
import ServerUrls from "../../values/ServerUrls"
import { setAlert } from "./AlertActions"
import store from '../store';


export const resetComplaint = () => ({
    type: Actions.COMPLAINT_ADD_RESET
})

export const addComplaint = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.COMPLAINT_ADD_PROGRESS });
        HTTPRequest(ServerUrls.COMPLAINT_URL, {
            authToken: store.getState().authReducer.token
        }, {
            "orderId": data.id,
            "complaintText": data.complaint
        }).then(res => {
            if (res.status === 200) {
                dispatch({ type: Actions.COMPLAINT_ADD_SUCCESS, payload: res.response.message })
                dispatch(setAlert('success', res.response.message))
            } else {
                dispatch(setAlert('error', res.response.message))
                dispatch({ type: Actions.COMPLAINT_ADD_ERROR, payload: res.response.message })
            }
        }).catch((error) => {
            dispatch({ type: Actions.COMPLAINT_ADD_ERROR, payload: error.message })
            dispatch(setAlert('error', error.message))
        })
    }
}